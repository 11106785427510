import {RefObject, useEffect} from 'react';

export const useClickOutside = (ref: RefObject<Element>, callback: () => void) => {
	const onClickHandler = (event: MouseEvent) => {
		if (!ref.current || !event.target) {
			return;
		}

		if (!ref.current.contains(event.target as Node)) {
			return callback();
		}
	};

	useEffect(() => {
		window.addEventListener('click', onClickHandler);

		return () => {
			window.removeEventListener('click', onClickHandler);
		};
	});
};