import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	strokeColor?: string;
}

const IconBurgerMenu: FC<IconProps> = ({strokeColor, ...restProps}) => {
	return (
		<svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
			<path
				d="M1.02734 16.6501H21.9733M1.02734 1.3501H21.9733M1.02734 9.0001H21.9733"
				stroke={strokeColor}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	);
};

IconBurgerMenu.defaultProps = {
	strokeColor: '#ffffff',
};

export default IconBurgerMenu;
