import {createAsyncThunk} from '@reduxjs/toolkit';

import {SetPayment} from '../../../api/query-params';
import {SearchPayment} from '../../../api/search-payment';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const fetchSetPayment = createAsyncThunk(
	'paymentSlice/fetchSetPayment',
	async (data: SetPayment, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await SearchPayment.setPayment(data);
			dispatch(setShowLoader({isShowLoader: false}));
			if (response.status === 200) {
				window.open(response.data.checkout.redirect_url, '_blank');
				return {status: response.status, data: response.data.checkout};
			}
		} catch (error) {
			handleAxiosError({dispatch, rejectWithValue, error});
		}
	},
);
