import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	strokeColor?: string;
}

const IconDoubleArrowRight: FC<IconProps> = ({strokeColor, ...restProps}) => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
			<path
				d="M4.27398 4L3.33398 4.94L6.38732 8L3.33398 11.06L4.27398 12L8.27398 8L4.27398 4Z"
				fill={strokeColor}
			/>
			<path
				d="M8.66656 4L7.72656 4.94L10.7799 8L7.72656 11.06L8.66656 12L12.6666 8L8.66656 4Z"
				fill={strokeColor}
			/>
		</svg>
	);
};

IconDoubleArrowRight.defaultProps = {
	strokeColor: '#303030',
};

export default IconDoubleArrowRight;
