import {FC, memo} from 'react';
import {NavigateOptions, useNavigate} from 'react-router-dom';

import {ServiceListItem} from '../../../../../interfaces/service';
import {setCheckedServices} from '../../../../../redux/reducers/service-preview-reduser';
import {useAppDispatch} from '../../../../../redux/store';
import {SERVICE_PAGE, SERVICE_PREVIEW} from '../../../../../shared/constants/path';
import {kindLabelTypeView, kindLabelView, priceTitle} from '../../../../../shared/constants/service/kind';
import {converterPriceToString} from '../../../../../shared/utils/converter-price';
import {useGetDayInfo} from '../../../../../shared/utils/get-day-info';
import {handleScrollToTop} from '../../../../../shared/utils/scroll-to-top';
import {getServiceWorkingTime} from '../../../../../shared/utils/service/get-service-working-time';
import {useServicePrice} from '../../../../../shared/utils/service/use-service-price';
import IconCount from '../../../../svg-icon-components/icon-count';
import IconDollar from '../../../../svg-icon-components/icon-dollar';
import IconTime from '../../../../svg-icon-components/icon-time';
import {Address} from '../../components/address/address';
import {CardContainer} from '../../components/card-container/card-container';
import {Logo} from '../../components/logo/logo';
import {
	ServiceInfoIconTooltipWrapper
} from '../../components/service-info-icon-tooltip-wrapper/service-info-icon-tooltip-wrapper';
import {ServiceName} from '../../components/service-name/service-name';
import {TextWithIcon} from '../../components/text-with-icon/text-with-icon';
import * as SC from './other-services.style';

export interface ServiceCardProps {
	service: ServiceListItem;
	closeHover?: boolean;
	isAdminPage?: boolean
}

export const OtherServices: FC<ServiceCardProps> = memo(({service, isAdminPage, closeHover}) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const goToServiceCard = (options?: NavigateOptions) => {
		navigate(SERVICE_PAGE.replace(':page', service.id), options);
	};
	const goToServiceCardMap = () => {
		goToServiceCard({state: {map: true}});
	};
	const onClickLogoButton = () => {
		handleScrollToTop();
		if (isAdminPage) {
			dispatch(setCheckedServices(service));
			navigate(SERVICE_PREVIEW.replace(':page', service.id));
			return;
		}
		navigate(SERVICE_PAGE.replace(':page', service.id));
	};
	const priceInfo = useServicePrice(service.schedule, service.order_policy.periodic, service.price);

	return (
		<CardContainer>
			<Logo notIsHovered={closeHover} orgLogo={service.org_info.logo} serviceLogo={service.logo}
				  serviceName={service.service_type} orgName={service.org_info.org_name}
				  onClick={onClickLogoButton} isAdmin={isAdminPage}/>
			<SC.TextContentWrapper isAdminPage={isAdminPage} active={service.active}>
				<ServiceInfoIconTooltipWrapper service={service} onGoToMap={goToServiceCardMap}/>
				<ServiceName serviceName={service.service_type}/>
				<Address platformInfo={service.platform_info} goToServiceCardMap={goToServiceCardMap}/>
				<TextWithIcon icon={<IconDollar/>} description={'Стоимость за час'}
							  textRight={priceInfo ? `от ${converterPriceToString(priceInfo.minPricePerHour)}` : 'Не определена'}/>
				<TextWithIcon icon={<IconDollar/>} description={priceTitle(service.service_kind)}
							  textRight={priceInfo ? `от ${converterPriceToString(priceInfo.minPricePerOrder)}` : 'Не определена'}/>
				<TextWithIcon icon={<IconTime/>} textRight={'Сегодня'}
							  description={getServiceWorkingTime(useGetDayInfo(new Date(), service.schedule).dayInfo)}/>
				<TextWithIcon icon={<IconCount/>} description={kindLabelView(service.service_kind)}
							  textRight={`${service.max_participants} ${kindLabelTypeView(service.service_kind)}`}/>
				<SC.Description>{service.description}</SC.Description>
			</SC.TextContentWrapper>
		</CardContainer>
	);
});
