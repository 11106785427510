import {createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';

import {GetServicesByFilterParams} from '../../../api/query-params';
import {SearchPlatformAPI} from '../../../api/search-platform-api';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setServicesTypes} from '../../reducers/sport-reducer';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const getAllServicesOrigins = createAsyncThunk(
	'dependencySlice/getAllServicesOrigins',
	async (params: GetServicesByFilterParams, {dispatch, rejectWithValue}) => {
			dispatch(setShowLoader({isShowLoader: true}));
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await SearchPlatformAPI.getAllServicesOrigins(params);
			dispatch(setShowLoader({isShowLoader: false}));
			if (response.status === 200) {
				return {status: response.status, data: response.data.services};
			}
			if (response.status === 204) {
				dispatch(setServicesTypes({data: []}));
				return {status: response.status, data: []};
			}
		} catch (error) {
			if( error!== AxiosError){
				return;
			}
			handleAxiosError({dispatch,error,rejectWithValue});
		}
	},
);
