import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	strokeColor?: string;
}

const IconArrowDown: FC<IconProps> = ({strokeColor, ...restProps}) => {
	return (
		<svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
			<path
				d="M10.5 1L8.75269 2.76297C7.35925 4.1689 6.66252 4.87187 5.81333 4.98011C5.60527 5.00663 5.39473 5.00663 5.18667 4.98011C4.33747 4.87187 3.64075 4.1689 2.24731 2.76297L0.5 1"
				stroke={strokeColor}
				strokeLinecap="round"
			/>
		</svg>
	);
};

IconArrowDown.defaultProps = {
	strokeColor: '#4D4D4D',
};

export default IconArrowDown;
