import styled from '@emotion/styled';

export const Container = styled.section`
display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const ContainerService = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #1c8666;
  border-radius: 900px;
  width: 100%;
  padding: 8px 10px 8px 34px;
  min-height: 54px;
`;
export const Logo = styled.img`
  object-fit: cover;
  border-radius: 8px;
  width: 90px;
  height: 36px;
`;
export const ServiceTitle = styled.h2`
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: var(--input-color);
`;
export const ServiceLogoWrapper = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
`;
export const TypesServices = styled.div`
  display: flex;
  gap: 6px;
  color: var(--white-color);

  span:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    font: 500 16px / 1.3 ;
    color: #1c8666;
    border: 1px solid #1c8666;
    border-radius: 4px;
    padding: 2px 10px;
  }

  span:not(:first-child) {
    font: 400 16px / 1.3;
    border-radius: 4px;
    background: #352c8d;
    padding: 2px 10px;
  }
`;
