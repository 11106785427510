import {FC} from 'react';
import {Link} from 'react-scroll';

import noImagePlaceholder from '../../../../../assets/img/no_image.png';
import {ONE_ORG_NAME} from '../../../../../shared/constants/path';
import {handleScrollToTop} from '../../../../../shared/utils/scroll-to-top';
import * as SC from './logo.style';

interface LogoProps {
	checked?: boolean
	onClick?: () => void
	noShowLogoOrg?: boolean
	notIsHovered?: boolean
	isAdmin?: boolean
	orgName: string
	orgLogo: string | null
	serviceName: string
	serviceLogo: string | null
	platformPage?: boolean
}

export const Logo: FC<LogoProps> = ({
										orgName,
										serviceName,
										serviceLogo,
										orgLogo,
										isAdmin,
										checked,
										onClick,
										platformPage,
										noShowLogoOrg,
										notIsHovered
									}) => {
	return (
		<SC.LogoWrapper isHovered={!notIsHovered}>
			<SC.Logo className={'logo'} src={serviceLogo || noImagePlaceholder} alt={serviceName} title={serviceName}/>
			{!noShowLogoOrg && <SC.OrgLogoWrapper to={`/${ONE_ORG_NAME.replace(':name', orgName)}`}
												  onClick={() => handleScrollToTop()}>
				<SC.OrgLogo src={orgLogo || noImagePlaceholder} alt={orgName} title={orgName}/>
			</SC.OrgLogoWrapper>}
			{(!isAdmin && !platformPage) && <Link to={checked ? '' : 'checked_service_scroll'}>
				<SC.FastPassageService onClick={onClick}>
					{checked ? 'Отменить услугу' : 'Выбрать услугу'}
				</SC.FastPassageService>
			</Link>}
			{isAdmin && <Link to={checked ? '' : 'checked_service_scroll'}>
				<SC.FastPassageService onClick={onClick}>Предпросмотр</SC.FastPassageService>
			</Link>}
			{platformPage && <Link to={checked ? '' : 'checked_service_scroll'}>
				<SC.FastPassageService onClick={onClick}>Просмотреть площадку</SC.FastPassageService>
			</Link>}
		</SC.LogoWrapper>
	);
};
