import {FC} from 'react';

import {displayAddress} from '../../../../../interfaces/interfaces';
import {ServicePlatformInfo} from '../../../../../interfaces/service';
import {OverflowTooltip} from '../../../../overflow-tooltip/overflow-tooltip';
import IconLocation from '../../../../svg-icon-components/icon-location';
import * as SC from './address.style';

interface AddressProps {
	platformInfo: ServicePlatformInfo;
	goToServiceCardMap : ()=> void;
}

export const Address:FC<AddressProps> = ({platformInfo, goToServiceCardMap}) => {
	return (
		<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '42px'}}>
			<div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
				<span><IconLocation/></span>
				<OverflowTooltip
					content={displayAddress(platformInfo.city, platformInfo.location_info.address)}
				/>
			</div>
			<SC.OnMap onClick={goToServiceCardMap}>на карту</SC.OnMap>
		</div>
	);
};
