import styled from '@emotion/styled';

interface FormStyle{
	page: 'main' | 'quote'
}

export const Form = styled.form<FormStyle>`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 40px 36px;
  background-color: var(--white-color);
  border-radius: 8px;
  box-shadow: 0 8px 40px 0 rgba(30, 45, 68, 0.2);
  width: ${props =>props.page === 'main' ? '100%' : '94%'};
  @media(max-width: 390px){
    padding: 20px 16px;
}
`;
export const FormLabel = styled.h3`
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 0;
  text-align: center;
`;
export const InputWrapper = styled.form<FormStyle>`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(${props => props.page === 'main' ? 1 : 2}, minmax(300px, 1fr));
  gap: 20px;
  @media (max-width: 730px) {
    grid-template-columns: auto;
  }
`;
export const Free = styled.span`
	background: linear-gradient(188deg, #b02a80 0%, #491496 100%);
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
`;
export const InputCss = {
	minWidth: '300px',
	maxHeight: '46px',
	minHeight: '46px',
	width: '100%',
};
export const ButtonGreenCss = {
	minWidth: '300px',
	maxHeight: '46px',
	minHeight: '46px',
	width: '100%',
};
