import styled from '@emotion/styled';

export const CheckedBlock = styled.div`
	height: 74px;
	margin-top: -20px;
	padding: 3px;
	margin-left: 6px;
	bottom: -40px;
	background-color: var(--green);
	border-radius: 50px;
	z-index: 1;
	position: absolute;
	width: calc(100% - 12px);
`;

export const CheckedBlockContent = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	padding: 10px;
	background-color: var(--white-color);
	border-radius: 50px;
	gap: 43px;
`;
