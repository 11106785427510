import {FC, useCallback} from 'react';
import {NavigateOptions, useNavigate} from 'react-router-dom';

import {displayAddress} from '../../../../../interfaces/interfaces';
import {PlatformListItem} from '../../../../../interfaces/platform';
import {ONE_FIELDS} from '../../../../../shared/constants/path';
import {getPlatformWorkingTime} from '../../../../../shared/utils/get-platform-working-time';
import {handleScrollToTop} from '../../../../../shared/utils/scroll-to-top';
import {OverflowTooltip} from '../../../../overflow-tooltip/overflow-tooltip';
import IconCall from '../../../../svg-icon-components/icon-call';
import IconLocation from '../../../../svg-icon-components/icon-location';
import IconTime from '../../../../svg-icon-components/icon-time';
import {CardContainer} from '../../components/card-container/card-container';
import {Logo} from '../../components/logo/logo';
import {ServiceName} from '../../components/service-name/service-name';
import * as SC from './organization-platform-card.style';

interface OrganizationPlatformsCardProps {
	platform: PlatformListItem
}

export const OrganizationPlatformCard: FC<OrganizationPlatformsCardProps> = ({platform}) => {

	const navigate = useNavigate();
	const [workingTime, workingDescription] = getPlatformWorkingTime(platform.day_info);
	const goToPlatformCard = useCallback((options?: NavigateOptions) => {
		handleScrollToTop();
		navigate(ONE_FIELDS.replace(':id', platform.id), options);
	}, [platform.id]);

	const goToPlatformCardMap = useCallback(() => {
		goToPlatformCard({state: {map: true}});
	}, [goToPlatformCard]);

	return (
		<CardContainer>
			<Logo orgLogo={platform.org_logo} orgName={platform.org_name} serviceName={platform.org_name}
				  serviceLogo={platform.logo} platformPage onClick={goToPlatformCard}/>
			<SC.TextContentWrapper>
				<ServiceName serviceName={platform.org_name}/>
				<SC.Link onClick={goToPlatformCardMap}>
					на карту
				</SC.Link>
				<div >
					<SC.TextWithIcon>
						<IconLocation backgroundColor="#352C8D"/>
						<OverflowTooltip
							classNameContainer={SC.TooltipContainer}
							content={displayAddress(platform.city, platform.location_info.address)}
						/>
					</SC.TextWithIcon>
				</div>
				<SC.TextWithIcon>
					<IconTime backgroundColor="#352C8D"/>
					<SC.Label>{workingTime}</SC.Label>
					<SC.Label>{workingDescription}</SC.Label>
				</SC.TextWithIcon>
				<SC.TextWithIcon>
					<IconCall backgroundColor="#352C8D"/>
					<SC.Label>{platform.contacts.viber ?? '-'}</SC.Label>
				</SC.TextWithIcon>
				<SC.Description>{platform.description}</SC.Description>
			</SC.TextContentWrapper>
		</CardContainer>
	);
};
