import {OrderPolicy} from '../../../../interfaces/service';

export const initialReservationData:OrderPolicy = {
	allows: 'View',
	attachable: false,
	max_date_delay: null,
	max_user_hours: null,
	max_user_services: null,
	periodic: null,
	trial_order: false,
};

export const initialFormData = {
	platformInfo: {
		id: '',
		name: '',
		address: '',
		crm: false
	},
	serviceKind: '',
};
export const initialTimeSelect = {
	hours: '0',
	minutes: '15'
};
export const newPeriodic = {
	min_order_duration: 4,
	service_step: 1,
	order_step: 1
};
