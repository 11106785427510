import {MessageUser} from '../interfaces/interfaces';
import {setIsShowMessage, setMessageUser, setShowLoader} from '../redux/reducers/user-data-reducer';
import {messageErrDataRecover} from '../shared/constants/message-user';

export const onGetQueryStarted = (errMessage: MessageUser = messageErrDataRecover) => {
	return async (_: any, {dispatch, queryFulfilled}: { dispatch: any, queryFulfilled: any }) => {
		const timeout = setTimeout(() => {
			dispatch(setShowLoader({isShowLoader: true}));
		}, 1000);
		try {
			await queryFulfilled;
		} catch (err) {
			dispatch(setMessageUser({messageUser: errMessage}));
			dispatch(setIsShowMessage({isShowMessage: true}));
		} finally {
			clearTimeout(timeout);
			dispatch(setShowLoader({isShowLoader: false}));
		}
	};
};

export const onPutQueryStarted = (errMessage: MessageUser = messageErrDataRecover, sccssMessage?: MessageUser) => {
	return async (_: any, {dispatch, queryFulfilled}: { dispatch: any, queryFulfilled: any }) => {
		const timeout = setTimeout(() => {
			dispatch(setShowLoader({isShowLoader: true}));
		}, 1000);
		try {
			const response = await queryFulfilled;
			if (response.status === 200) {
				dispatch(setShowLoader({isShowLoader: false}));
				if (sccssMessage) {
					dispatch(setMessageUser({messageUser: sccssMessage}));
				}
				dispatch(setIsShowMessage({isShowMessage: true}));
			}
		} catch (err) {
			dispatch(setMessageUser({messageUser: errMessage}));
			dispatch(setIsShowMessage({isShowMessage: true}));
		} finally {
			clearTimeout(timeout);
			dispatch(setShowLoader({isShowLoader: false}));
		}
	};
};
