import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	strokeColor?: string;
}

const IconArrowLeftBig: FC<IconProps> = ({strokeColor}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="10" height="24" viewBox="0 0 10 24" fill="none">
			<path
				d="M9.16732 22.4167L5.49447 18.7765C2.56544 15.8735 1.10092 14.422 0.875422 12.6528C0.820172 12.2194 0.820172 11.7807 0.875422 11.3473C1.10092 9.57811 2.56544 8.12661 5.49446 5.2236L9.16732 1.58337"
				stroke={strokeColor}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>
		</svg>
	);
};

IconArrowLeftBig.defaultProps = {
	strokeColor: '#303030',
};

export default IconArrowLeftBig;
