import {FC} from 'react';

import {ListWithIcons} from '../../../../../../components/list-with-icons/list-with-icons';
import IconFigureShape from '../../../../../../components/svg-icon-components/icon-figure-shape';
import IconLocation from '../../../../../../components/svg-icon-components/icon-location';
import IconPencil from '../../../../../../components/svg-icon-components/icon-pencil';
import IconService from '../../../../../../components/svg-icon-components/icon-service';
import IconTime from '../../../../../../components/svg-icon-components/icon-time';
import {displayAddress} from '../../../../../../interfaces/interfaces';
import {IOrder} from '../../../../../../interfaces/order';
import {getTimeDisplayValue} from '../../../../../../shared/utils/time/get-time-display-value';
import * as SC from './order-info.style';

interface BgColor { disabled: boolean; busy: boolean; errored: boolean; ordered: boolean; };

interface OrderInfoProps {
	order: IOrder;
	bgColor: BgColor;
}

export const OrderInfo: FC<OrderInfoProps> = ({order, bgColor}) => {

	const getActiveStatus = (status: BgColor): string => {
		const activeStatus = Object.keys(status).find(key => status[key as keyof BgColor]);
		return activeStatus || '';
	};

	return (
		<SC.OrderInfo bgColor={getActiveStatus(bgColor)}>
			<SC.Title>Ваш заказ №{order.id}</SC.Title>
			<SC.Order>
				<ListWithIcons className={SC.ListWithIcons}>
					<ListWithIcons.Item
						icon={<IconLocation backgroundColor={'transparent'}/>}
						contentClassName={SC.ListItemContent}
					>
						<SC.Label>
							{displayAddress(order.host_info.city, order.host_info.location_info.address)}
						</SC.Label>
					</ListWithIcons.Item>
					<ListWithIcons.Item
						icon={<IconFigureShape  strokeColor={'var(--white-color)'} className={SC.Icon}/>}
						contentClassName={SC.ListItemContent}
					>
						<SC.Label>{order.host_info.organization}</SC.Label>
					</ListWithIcons.Item>
					<ListWithIcons.Item
						icon={<IconTime backgroundColor={'transparent'}/>}
						contentClassName={SC.ListItemContent}
					>
						<SC.Label>Время</SC.Label>
						<span>
              {getTimeDisplayValue(
				  order.order_details.from,
				  order.order_details.from + order.order_details.duration
			  ) || '-'}
            </span>
					</ListWithIcons.Item>
					<ListWithIcons.Item
						icon={<IconService backgroundColor={'transparent'}/>}
						contentClassName={SC.ListItemContent}
					>
						<SC.Label>Количество</SC.Label>
						<span>{`${order.order_details.amount.toString()} шт.`}</span>
					</ListWithIcons.Item>
					{order.order_details.description && (
						<ListWithIcons.Item
							icon={<IconPencil backgroundColor={'transparent'}/>}
							contentClassName={SC.ListItemContent}
						>
							<SC.Label>
								{order.order_details.description}
							</SC.Label>
						</ListWithIcons.Item>
					)}
				</ListWithIcons>
			</SC.Order>
		</SC.OrderInfo>
	);
};
