import {groupBy} from 'lodash';

import {HOURS_COEFFICIENT, MINUTES_COEFFICIENT} from '../../constants/time';
import {getConvertedFullTime} from './get-converted-full-time';

export const getHoursTimePoint = (timepoint: number) => {
	return Math.trunc(timepoint / HOURS_COEFFICIENT) * HOURS_COEFFICIENT;
}; // 38 => 36

export const groupPointsByHours = (points: number[]) => {
	return groupBy(points, getHoursTimePoint);
}; // [32, 34, 36, 38] => {32: [32, 34], 36: [36, 38]}

export const getTimePointHours = (timepoint: number) => {
	return Math.trunc(timepoint / HOURS_COEFFICIENT);
}; // 38 => 9

export const getTimePointMinutes = (timepoint: number) => {
	return (timepoint % HOURS_COEFFICIENT) * MINUTES_COEFFICIENT;
}; // 38 => 30

export const timePointToTime = (timepoint: number) => {
	return getConvertedFullTime(getTimePointHours(timepoint), getTimePointMinutes(timepoint));
};

export const getTimeDisplayValue = (from?: number, to?: number, isShort = false) => {
	if (!from || !to) {
		return '';
	}

	return isShort
		? `${timePointToTime(from)}-${timePointToTime(to)}`
		: `с ${timePointToTime(from)} до ${timePointToTime(to)}`;
};
