import classNames from 'classnames';
import {FC, PropsWithChildren} from 'react';

import {Tag} from './components/tag/tag';
import styles from './tag-list.module.css';

interface TagListProps extends PropsWithChildren {
	className?: string;
}

interface TagListComponents {
	Tag: typeof Tag;
}

export const TagList: FC<TagListProps> & TagListComponents = ({children, className}) => {
	return <div className={classNames(styles.tag_list, className)}>{children}</div>;
};

TagList.Tag = Tag;
