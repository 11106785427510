import classNames from 'classnames';
import {FC, JSX, PropsWithChildren} from 'react';

import styles from './list-with-icons-item.module.css';

export interface ListWithIconsItemProps extends PropsWithChildren {
	icon?: JSX.Element;
	className?: string;
	contentClassName?: string;
	onClick?: () => void;
}

export const ListWithIconsItem: FC<ListWithIconsItemProps> = ({
	icon,
	className,
	contentClassName,
	children,
	onClick,
}) => {
	return (
		<div
			className={classNames(styles.list_with_icons_item, className, {
				[styles.list_with_icons_item_interactive]: Boolean(onClick),
			})}
			onClick={onClick}
		>
			{icon ? <span className={styles.list_with_icons_item_icon}>{icon}</span> : null}
			<div className={classNames(styles.list_with_icons_item_content, contentClassName)}>{children}</div>
		</div>
	);
};
