import {createAsyncThunk} from '@reduxjs/toolkit';

import {historyPlatformsAPI} from '../../../api/history';
import {GetVisitedPlatformsParams} from '../../../api/query-params';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';

export const fetchGetTotalVisitedPlatforms = createAsyncThunk(
	'userDataSlice/fetchGetTotalVisitedPlatforms',
	async (data: {userId: string; params: GetVisitedPlatformsParams}, {dispatch, rejectWithValue}) => {
		try {
			const response = await historyPlatformsAPI.getTotalVisitedPlatforms(data.userId, data.params);
			if (response.status === 200) {
				return {status: response.status, data: response.data.total};
			}
			if (response.status === 204) {
				return {status: response.status, data: []};
			}
		} catch (error) {
				handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
