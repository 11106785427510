import {ServiceListFilterKey} from '../../../interfaces/service';

export const QUICK_ORDER_FILTERS = {
	[ServiceListFilterKey.sport_type]: ServiceListFilterKey.sport_type,
	[ServiceListFilterKey.service_type]: ServiceListFilterKey.service_type,
	[ServiceListFilterKey.date]: ServiceListFilterKey.date,
	[ServiceListFilterKey.from]: ServiceListFilterKey.from,
	[ServiceListFilterKey.to]: ServiceListFilterKey.to,
	[ServiceListFilterKey.duration]: ServiceListFilterKey.duration,
	[ServiceListFilterKey.amount]: ServiceListFilterKey.amount,
} as const;

export const DEFAULT_FILTERS_DATA = {
	[QUICK_ORDER_FILTERS.sport_type]: '',
	[QUICK_ORDER_FILTERS.service_type]: '',
	[QUICK_ORDER_FILTERS.date]: '',
	[QUICK_ORDER_FILTERS.from]: '',
	[QUICK_ORDER_FILTERS.to]: '',
	[QUICK_ORDER_FILTERS.hours]: '',
	[QUICK_ORDER_FILTERS.amount]: '',
};
