import classNames from 'classnames';
import React, {FC, PropsWithChildren} from 'react';

import styles from './section.module.css';

interface SectionProps extends PropsWithChildren {
	className?: string;
}

export const Section: FC<SectionProps> = ({children, className}) => (
	<section className={classNames(styles.section, className)}>{children}</section>
);
