import React, {useRef} from 'react';
import AliceCarousel from 'react-alice-carousel';
import {useDispatch} from 'react-redux';
import {createSearchParams, useNavigate} from 'react-router-dom';

import ArrowLeft from '../../assets/img/navigate-left.png';
import ArrowRight from '../../assets/img/navigate-right.png';
import {SportType} from '../../interfaces/interfaces';
import {setFilterSport} from '../../redux/reducers/sport-reducer';
import {PLAY_FIELDS} from '../../shared/constants/path';
import {useResizeWindow} from '../../shared/hooks/use-resize-window';
import {handleScrollToTop} from '../../shared/utils/scroll-to-top';
import st from './galery-start-page.module.css';
import {useGalleryPadding} from './hooks/use-galery-padding';

export const GalleryStartPage = (props: {srcImg: SportType[]}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const handleDragStart = (e: MouseEvent) => e.preventDefault();
	const checkPlatform = (sport: string) => {
		dispatch(setFilterSport({filter: sport}));
		handleScrollToTop();
		navigate({
			pathname: PLAY_FIELDS,
			search: createSearchParams({
				sport_type: sport,
			}).toString(),
		});
	};

	const width = useResizeWindow();

	const isMobileMod = width <= 1200;

	const items = props.srcImg.map((item, ind) => (
		<div className={st.slider_box} key={ind}>
			<img
				src={item.logo as string}
				alt={`Изображение - ${item.sport}`}
				title={`Изображение - ${item.sport}`}
				onDragStart={() => handleDragStart}
				role="presentation"
				className={st.slider_img}
				onClick={() => checkPlatform(item.sport)}
			/>
			<div className={st.sport_name_box} onClick={() => checkPlatform(item.sport)}>
				<h3 className={st.sport_name}>{item.sport}</h3>
			</div>
		</div>
	));

	const countImg = (isBig: boolean) => {
		if (isBig && items.length >= 3) {
			return 3;
		}
		if (!isBig && items.length >= 2) {
			return 2;
		}
		return items.length;
	};

	const contentRef = useRef<HTMLDivElement>(null);

	const padding = useGalleryPadding(contentRef);

	return (
		<div ref={contentRef} className={st.carousel_container}>
			<AliceCarousel
				// disableButtonsControls
				mouseTracking
				infinite={true}
				items={items}
				controlsStrategy="responsive"
				autoPlay={false}
				paddingRight={isMobileMod ? padding : undefined}
				// 			// autoPlayIntrval={5000}
				keyboardNavigation={true}
				disableDotsControls
				responsive={{
					0: {items: 1},
					1200: {items: countImg(true), itemsFit: 'contain'},
				}}
				renderPrevButton={() => {
					return (
							<img className={st.prev_btn_icon} src={ArrowLeft} alt="arrow_left" title="arrow_left" />
					);
				}}
				renderNextButton={() => {
					return (
							<img className={st.next_btn_icon} src={ArrowRight} alt="arrow_right" title="arrow_right" />
					);
				}}
			/>
		</div>

	);
};
