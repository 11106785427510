import {FC} from 'react';

import {Button} from '../../../../components';
import * as SC from './order-btn-block.style';

interface OrderBtnBlockProps {
	className?: string;
	onBtnClick?: () => void;
}

export const OrderBtnBlock: FC<OrderBtnBlockProps> = ({className, onBtnClick}) => {
	return (
		<SC.CheckedBlock className={className}>
			<SC.CheckedBlockContent>
				<Button label="К оформлению заказа" onClick={onBtnClick}/>
			</SC.CheckedBlockContent>
		</SC.CheckedBlock>
	);
};
