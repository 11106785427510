import {createSlice} from '@reduxjs/toolkit';

import {OrderType} from '../../interfaces/enums';
import {TOrderCreate} from '../../interfaces/order';
import {ServiceListItem,ServiceSingle} from '../../interfaces/service';
import {fetchGetListRecommendations} from '../thunks/get-list-recommendations';
import {fetchGetOrderAcceptServiceById} from '../thunks/playfields-order-accept-service-thunk';

interface InitialState {
	recommendations: ServiceListItem[];
	checkedService: ServiceSingle;
	getCheckedService: ServiceSingle,
	orderAttachDate: {
		allow: OrderType
		params: TOrderCreate
	};
}

const initialState: InitialState = {
	recommendations: [],
	checkedService: {} as ServiceSingle,
	getCheckedService: {} as ServiceSingle,
	orderAttachDate: {} as {
		allow: OrderType,
		params: TOrderCreate
	},
};

export const orderAttachSlice = createSlice({
	name: 'orderAttachSlice',
	initialState,
	reducers: {
		setOrderAttachDate: (state: InitialState, action) => {
			state.orderAttachDate = action.payload;
		},
		setCheckedServiceAttach: (state: InitialState, action) => {
			state.checkedService = action.payload;
		},
		setClearCheckedService: (state) => {
			state.getCheckedService = {} as ServiceSingle ;
			state.checkedService = {} as ServiceSingle ;
		}
	},
	extraReducers: builder => {
		builder.addCase(fetchGetListRecommendations.fulfilled, (state: InitialState, action) => {
			state.recommendations = action.payload?.data;
		});
		builder.addCase(fetchGetOrderAcceptServiceById.fulfilled, (state, action) => {
			state.getCheckedService = action.payload?.data;
		});
	},
});

export const {
	setClearCheckedService,
	setOrderAttachDate,
	setCheckedServiceAttach,
} = orderAttachSlice.actions;
export const orderAttachReducer = orderAttachSlice.reducer;
