import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	backgroundColor?: string;
	strokeColor?: string;
}

const IconWebSite: FC<IconProps> = ({backgroundColor, strokeColor, ...restProps}) => {
	return (
		<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
			<rect width="22" height="22" rx="11" fill={backgroundColor} />
			<path
				d="M16.5575 8.73436C16.8428 9.43342 17 10.1983 17 11C17 11.4257 16.9557 11.841 16.8714 12.2416M16.5575 8.73436L16.5337 8.74671C15.875 8.94713 14.6046 9.30931 13.2883 9.55223M16.5575 8.73436C15.815 6.91499 14.2051 5.54179 12.2416 5.12863M16.8714 12.2416C16.3838 14.5589 14.5589 16.3838 12.2416 16.8714M16.8714 12.2416C16.4157 12.387 14.8173 12.8792 13.1732 13.1732M12.2416 16.8714C11.841 16.9557 11.4257 17 11 17C10.5743 17 10.159 16.9557 9.75836 16.8714M12.2416 16.8714C12.387 16.4157 12.8792 14.8173 13.1732 13.1732M9.75836 16.8714C7.44106 16.3838 5.61624 14.5589 5.12863 12.2416M9.75836 16.8714C9.61297 16.4157 9.12081 14.8173 8.82684 13.1732M5.44317 8.73272C5.15746 9.43222 5 10.1977 5 11C5 11.4257 5.04433 11.841 5.12863 12.2416M5.44317 8.73272L5.46581 8.74656C6.12444 8.94696 7.3951 9.30925 8.71166 9.55224M5.44317 8.73272C6.18593 6.91416 7.79547 5.54166 9.75836 5.12863M5.12863 12.2416C5.5843 12.387 7.18271 12.8792 8.82684 13.1732M9.75836 5.12863C10.159 5.04433 10.5743 5 11 5C11.4257 5 11.841 5.04433 12.2416 5.12863M9.75836 5.12863C9.59157 5.65136 8.96843 7.67796 8.71166 9.55224M12.2416 5.12863C12.4084 5.65136 13.0316 7.67796 13.2883 9.55223M13.2883 9.55223C13.3574 10.0566 13.4 10.55 13.4 11C13.4 11.6646 13.3072 12.4236 13.1732 13.1732M13.2883 9.55223C12.5017 9.69742 11.6987 9.8 11 9.8C10.3013 9.8 9.49831 9.69742 8.71166 9.55224M13.1732 13.1732C12.4236 13.3072 11.6646 13.4 11 13.4C10.3354 13.4 9.57637 13.3072 8.82684 13.1732M8.82684 13.1732C8.69282 12.4236 8.6 11.6646 8.6 11C8.6 10.55 8.64257 10.0566 8.71166 9.55224"
				stroke={strokeColor}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

IconWebSite.defaultProps = {
	backgroundColor: '#1c8666',
	strokeColor: '#ffffff',
};

export default IconWebSite;
