import styled from '@emotion/styled';
import {Link} from 'react-router-dom';

export const PlatformCard = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	height: fit-content;
	margin-bottom: 20px;
	background-color: var(--grey);
	border-radius: 8px;
	@media screen and (max-width: 1300px) {
		display: flex;
		height: 100%;
		flex-direction: column;
		margin-bottom: 0;
	}
`;

export const PlatformCardLogoWrapper = styled.div`
	flex-shrink: 0;
	width: 683px;
	margin: -12px 0;
	overflow: hidden;
	border-radius: 8px;
	position: relative;
	border: 1px solid var(--gray-dark);
	transition: 0.7s;

	&:hover {
		img {
			transition: 0.7s ease-in-out;
			filter: brightness(0.6);
		}

		button {
			transition: 0.7s;
			display: block;
		}
	}

	@media screen and (max-width: 1300px) {
		width: 100%;
		margin: 0;
	}
`;
export const PlatformCardLogo = styled.img`
	width: 100%;
	height: 100%;
	border-radius: 8px;
	max-height: 412px;
	object-fit: cover;
	object-position: center top;
	cursor: pointer;
	transition: 0.7s ease-in-out;

	@media screen and (max-width: 1300px) {
		border-radius: 8px;
		aspect-ratio: 16/9;
		object-fit: cover;
		cursor: pointer;
	}
`;
export const PlatformCardTitle = styled.h3`
	font-weight: 700;
	font-size: 20px;
	line-height: 1.385em;
`;
export const PlatformCardMainInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
	min-width: 245px;
	margin-left: 28px;
	padding-top: 32px;
	gap: 15px;
	@media screen and (max-width: 1300px) {
		display: flex;
		flex-direction: column;
		align-items: start;
		min-width: 245px;
		margin-left: 16px;
		padding-top: 16px;

		gap: 15px;
	}
`;
export const PlatformCardOrganizationLogo = styled.img`
	width: 80px;
	height: 80px;
	border-radius: 50%;

	object-fit: cover;
	cursor: pointer;
`;
export const PlatformCardAdditionalInfo = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	padding: 30px 10px 30px 0;
	position: relative;
	justify-content: space-between;
	@media screen and (max-width: 1300px) {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		overflow: hidden;
		margin-top: 5px;
		padding: 8px 20px;
		gap: 10px;
	}
`;
export const ToMapLinkWrapper = styled(Link)`
	display: flex;
	flex-shrink: 0;
	align-items: center;
	align-self: flex-end;
	cursor: pointer;
	gap: 6px;
`;
export const ToMapLink = styled.span`
	text-wrap: nowrap;
	font-size: 16px;
	line-height: 1.3em;
	color: var(--green);
	text-decoration: underline;
`;
export const PlatformCardInfoBlockDescription = styled.p`
	overflow-y: auto;
	font-size: 14px;
	line-height: 1.385em;
	height: 200px;
	white-space: pre-wrap;
	padding: 10px;

	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #9f9f9f;
		border-radius: 20px;
	}
	&::-webkit-scrollbar-track {
		background-color: #eaeaea;
		border-radius: 20px;
	}

	@media screen and (max-width: 1300px) {
		overflow-y: unset;
	}
`;
export const ButtonGroupSetting = {
	padding: '48px 14px',
};
