import {range} from 'lodash';

import {TimePointMeta} from '../../../../../../../interfaces/order';
import {OrderStepPolicy} from '../../../../../../../interfaces/service';
import {MINUTES_COEFFICIENT} from '../../../../../../../shared/constants/time';

export const getError = (from: number, to: number, policy: OrderStepPolicy): string | null => {
	const duration = to - from;

	const isTimeError = from > to;
	const isMinTimeError = duration < policy.min_order_duration;
	const isOrderStepError = (duration - policy.min_order_duration) % policy.order_step !== 0;

	if (isTimeError) {
		return 'Некорректное время, начало не может быть позже конца';
	}

	if (isMinTimeError) {
		return `Минимум ${policy.min_order_duration * MINUTES_COEFFICIENT} минут`;
	}

	if(isOrderStepError) {
		return `Если ваш заказ больше минимальной длительности (${policy.min_order_duration * MINUTES_COEFFICIENT} минут), то после этого разрещено бронировать промежутками по ${policy.order_step * MINUTES_COEFFICIENT} минут`;
	}

	return null;
};

export const validateRange = ({
	start,
	end,
	step,
	pointsMetaMap,
}: {
	start: number;
	end: number;
	step: number;
	pointsMetaMap: Record<number, TimePointMeta>;
}) => {
	const points = range(start, end, step);
	const isValid = !points.find((point) => {
		return pointsMetaMap[point].order !== undefined;
	});
	return {range: points, isValid};
};
