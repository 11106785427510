import styled from '@emotion/styled';

export const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.45);
	color: var(--link-color);
	z-index: 999;
`;
export const Modal = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: var(--white-color);
	padding: 30px;
	border-radius: 8px;
	display: flex;
	width: min(100%, 420px);
	flex-direction: column;
	gap: 16px;
	z-index: 9999;
	button{
		width: 100%;
	}
`;
export const Description = styled.p`
	font-size: 16px;
	color: var(--link-color);
`;
export const Title = styled.h3`
	font-size: 28px;
	color: var(--link-color);
`;
export const CloseBtn  = styled.button`
	position: absolute;
	width: 30px !important;
	height: 30px !important;
	padding: 4px;
	font-weight: 600;
	right: 10px;
	top: 10px;
	font-size: 1.4rem;
	color: var(--grey-color);
	border: 1px solid var(--grey-color);
	border-radius: 50%;
	cursor: pointer;
	transition: 0.7s ease-in-out;
	&:hover {
		color: var(--blue-color);
		border: 1px solid var(--blue-color);
	}
`;
