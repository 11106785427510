import {createAsyncThunk} from '@reduxjs/toolkit';

import {searchUsersAPI} from '../../../api/search-users-a-p-i';
import {messageErr404} from '../../../shared/constants/message-user';
import {requestCodes} from '../../../shared/constants/request-codes';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setIsShowMessage, setMessageUser, setShowLoader} from '../../reducers/user-data-reducer';

export const fetchGetUserById = createAsyncThunk(
	'userDataSlice/fetchGetUserById',
	async (data: string, {dispatch, rejectWithValue}) => {
		try {
			const response = await searchUsersAPI.getUserById(data);

			if (response.status === requestCodes.success) {
				return {status: response.status, data: response.data};
			}

			if (response.status === requestCodes.emptyData) {
				dispatch(setShowLoader({isShowLoader: false}));
				dispatch(setMessageUser({messageUser: messageErr404}));
				dispatch(setIsShowMessage({isShowMessage: true}));
			}
		} catch (error) {
				handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
