import {ImageInfo} from '../components/images/index.type';
import {ModeratorRoleMetaField, OwnerRoleMetaField} from '../shared/constants/roles';
import {ServiceTypeKind} from './enums';
import {PlatformListItem} from './platform';
import {TUserRole} from './role';
import {ServiceListItem} from './service';

export interface LocationInfo {
	address: string;
	location_x: number;
	location_y: number;
}

export interface OrganizationUpdate {
	org_name: string;
	description: string;
	images: ImageInfo[];
	unp: string;
	contacts: {
		instagram: null | string;
		telegram: null | string;
		viber: null | string;
		web_site: null | string;
	};
}

// -------------------USER-----------------------//
export interface InputTypesRegistration {
	avatar?: string | null;
	name: string;
	password: string;
	phone: string;
	email: string;
	code: string;
	news_subscription: boolean;
}

export interface UserServerData {
	id: string;
	nickname: string;
	phone: string;
	email: string;
	news_subscription?: boolean;
}

export type UserMeServerInfo = UserRegistered | UserAnonym & { totalComments?: number };

export interface IComments {
	comment: string;
	id: string;
	register_at: string;
	requester: UserRegistered;
	updated_at: string | null;
}

export interface IPlatformInfo {
	city: string | null;
	location: { address: string; location_x: string; location_y: string };
	org_name: string;
	platform_id: string;
}

export type UserWithPlatformInfo = {
	comments: number;
	platform_info: IPlatformInfo;
	user_info: UserRegistered;
};


export interface UserRegistered {
	Registered: {
		id: string;
		name: string;
		email: string;
		phone: string;
		news_subscription?: boolean;
	};
}

export interface UserAnonym {
	Anonym: {
		id: string;
		phone: string;
	};
}

export interface SystemID {
	id: string;
}

export interface OrganizationName {
	org_name: string;
}

export interface UpdateUser {
	id: string;
	name?: string;
	phone?: string;
	news_subscription?: boolean;
	avatar?: string;
}

export interface UserRequestAuth {
	login: string;
	password: string;
}

export interface MessageUser {
	titleMessage: string;
	textMessage: string;
	action: string;
	btnTitle: string;
	isErrorMessage: boolean;
	descriptionMessage?: string
}

export interface InfoCalendarRulesType {
	day?: number;
	numWeek?: number;
	week?: number;
	month?: number;
	onceDay?: string;
	weekend?: boolean;
}

export interface JwtData {
	exp: number;
	res: object;
	roles: TUserRole[];
	sub: string;
}

export interface AddRoleUserFormField {
	role: string;
	[OwnerRoleMetaField]: string;
	[ModeratorRoleMetaField]: string;
}

export type ServiceKind = `${ServiceTypeKind}`;

export interface OrgFormType {
	org_name: string;
	description: string;
	unp: string;
	contacts: Contacts;
}

export interface ServicesData {
	id?: string;
	logo?: string | null;
	platform_id: string;
	service_type: string;
	description: string;
	max_participants: number;
	price: number;
}

export type FuncString = (str: string) => void;

export interface WorkloadStatusServices {
	status: string;
	time_point: number;
}

export interface WorkloadService {
	time_point: number;
	weight: number;
	max_weight: number;
}

export interface InfoOrdersPlatform {
	id?: string;
	info: InfoOrder;
	status: string;
}

export interface InfoOrder {
	owned_by: string;
	host: string;
	date: string;
	description: string;
	from: number;
	duration: number;
	amount: number;
	service_id: string;
}

export interface OrderConfirm {
	owned_by: string;
	host: string;
	date: string;
	from: number;
}

export interface SetOrderActivate {
	date: string;
	duration: number;
	from: number;
	order_id: string;
	platform_id: string;
	requester: string;
	service: string;
}

export interface CloseOrderData {
	order_id: string;
	owned_by: string;
	platform_id: string;
	reason: string;
	requester: string;
}

export interface OrderAttach {
	info: OrderAttachService,
	order_id: string,
	owner: string,
	platform_id: string,
	requester: string
}
export interface OrderAttachConfirmOrder {
	info: {
		service_id: string;
		amount: number;
	},
	order_id: string,
	owner: string,
	platform_id: string,
	requester: string
}
export interface OrderAttachService {
	'amount': number,
	'service_info': {
		'service_id': string,
		'service_name': string,
		'service_type': string
	}
}

export interface ScheduleFrequencyWeekly {
	Weekly: {
		day: number;
	};
}

export interface ScheduleFrequencyMonthly {
	Monthly: {
		week: number | null;
		day: number;
	};
}

export interface ScheduleFrequencyYearly {
	Yearly: {
		month: number;
		week: number | null;
		day: number;
	};
}

export interface ScheduleFrequencyOnceDate {
	OnceDate: {
		date: string; // '2023-05-23'
	};
}

export type ScheduleFrequency =
	| 'Daily'
	| ScheduleFrequencyWeekly
	| ScheduleFrequencyMonthly
	| ScheduleFrequencyYearly
	| ScheduleFrequencyOnceDate;

export function isScheduleFrequencyWeekly(frequency: ScheduleFrequency): frequency is ScheduleFrequencyWeekly {
	return typeof frequency === 'object' && 'Weekly' in frequency;
}

export function isScheduleFrequencyMonthly(frequency: ScheduleFrequency): frequency is ScheduleFrequencyMonthly {
	return typeof frequency === 'object' && 'Monthly' in frequency;
}

export function isScheduleFrequencyYearly(frequency: ScheduleFrequency): frequency is ScheduleFrequencyYearly {
	return typeof frequency === 'object' && 'Yearly' in frequency;
}

export function isScheduleFrequencyOnceDate(frequency: ScheduleFrequency): frequency is ScheduleFrequencyOnceDate {
	return typeof frequency === 'object' && 'OnceDate' in frequency;
}

export function isScheduleFrequencyDaily(frequency: ScheduleFrequency): frequency is 'Daily' {
	return frequency === 'Daily';
}

export type ScheduleItem = {
	start_working: number;
	end_working: number;
	property: { price_policy: { price: number } | null } | null;
};

export type DaySchedule = {
	Working: {
		schedule: ScheduleItem[];
	};
};
export type DayInfo = DaySchedule | 'Weekend';

export interface ScheduleInfo {
	frequency: ScheduleFrequency;
	schedule: DayInfo;
}

export interface UpdateSchedule {
	issuer: string;
	issuer_id: string;
	schedule: ScheduleInfo[];
}

export interface WorkingData {
	working: boolean;
	workString: string;
	workStart: number;
	workEnd: number;
}

export interface BlackListUser {
	login: string;
	platform_id: string;
	comment: string;
	requester: string;
}

export type BlackListUserRemove = Omit<BlackListUser, 'comment' | 'requester'>;

export interface AddImage {
	issuer: 'organization' | 'platform' | 'service' | 'sport';
	issuer_id: string;
	image_link: string;
}

export interface RemoveImage {
	issuer: 'organization' | 'platform' | 'service' | 'sport';
	issuer_id: string;
	image_id: string;
}

export interface SportType {
	sport: string;
	logo: null | string;
}

export interface ServiceType {
	service_kind: ServiceKind;
	service_type: string;
}

export interface IServiceType {
	info: ServiceType;
	allows: string[];
}

export interface IUpdateServiceType extends IServiceType {
	old_service_type: string;
}

export interface ServiceTypeAllows {
	service_type: string;
	sport_type: string;
}

export interface RegisterOrgRequest {
	org_name: string;
	user_email: string;
	user_phone: string;
	unp: string;
}

export interface Img {
	id: string;
	info: ImageInfo;
}

export interface RecoverPasswordCompleteData {
	login: string;
	code: string;
	new_password: string;
}

export enum AuthPages {
	'registration',
	'login',
}

export type PaymentMethod = 'CASH' | 'ONLINE' | 'BOTH';

export interface Contacts {
	instagram: string | null;
	telegram: string | null;
	viber: string | null;
	web_site: string | null;
}

export interface ContactsPlatform {
	instagram: string | null;
	telegram: string | null;
	viber: string | null;
	web_site: string | null;
	email_for_notifications: string | null;
	telegram_for_notifications: string | null;
}

export interface Notification {
	email: string | null;
	telegram: string | null;
}

export interface IMapItem extends PlatformListItem, ServiceListItem {
}

export interface IPlacemark extends IMapItem {
	coords: number[];
}


export const displayAddress = (city: string | null, address: string): string => {
	const cityInfo = city ? `${city}, ` : '';
	return `${cityInfo}${address}`;
};
