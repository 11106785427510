import {FC, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import noImagePlaceholder from '../../../assets/img/no_image.png';
import {PlatformListItem} from '../../../interfaces/platform';
import {setCheckedPlatformUpdate} from '../../../redux/reducers/platforms-reducer';
import {useAppDispatch} from '../../../redux/store';
import {ONE_FIELDS, UPDATE_PLATFORM} from '../../../shared/constants/path';
import {useResizeWindow} from '../../../shared/hooks/use-resize-window';
import {handleScrollToTop} from '../../../shared/utils/scroll-to-top';
import {ButtonGroupSetting} from '../../buttons';
import {QuickJumpButton} from '../../buttons/quick-jump-button/quick-jump-button';
import {Spoiler} from '../../Spoiler/Spoiler';
import {
	PlatformCardContactsList,
} from '../platform-card/components/platform-card-contacts-list/platform-card-contacts-list';
import {Props} from '../platform-card/components/platform-list-card/platform-list-card';
import * as SC from './platform-list-card-map.style';

export const PlatformListCardMap: FC<Props> = ({
												   onClickToOrg,
												   platform,
												   onClickToPlatform,
												   isAdmin = false,
												   handleActions = () => {
												   },
												   allModerators = [],
											   }) => {

	const width = useResizeWindow();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const isMobileMod = width <= 1200;
	const [type, setType] = useState<string>('');
	const handleIsType = (val: string) => setType(val);

	const update = (platform: PlatformListItem) => {
		dispatch(setCheckedPlatformUpdate(platform));
		navigate(`/admin/platforms/${UPDATE_PLATFORM}`);
	};
	const onClickToPlatformHandler = () => {
		onClickToPlatform?.(platform?.id);
		handleScrollToTop();
	};

	return (
		<SC.PlatformCard>
			<SC.PlatformCardLogoWrapper>
				<SC.PlatformCardLogo className='logo'
									 src={platform.logo || noImagePlaceholder}
									 alt={`Изображение - ${platform?.org_name}`}
									 title={`Изображение - ${platform?.org_name}`}
				/>
				<QuickJumpButton onClick={onClickToPlatformHandler} text={'Быстрый просмотр'} type={'link'}
								 to={ONE_FIELDS.replace(':id', platform.id)}/>
			</SC.PlatformCardLogoWrapper>
			<SC.PlatformCardMainInfo>
				<SC.PlatformCardTitle>{platform?.org_name}</SC.PlatformCardTitle>
				<SC.LogoMobile onClick={() => onClickToOrg?.(platform?.org_name)}>
					<SC.PlatformCardOrganizationLogo
						src={platform?.org_logo || noImagePlaceholder}
						alt={`Логотип - ${platform.org_name}`}
						title={`Логотип - ${platform.org_name}`}
					/>
				</SC.LogoMobile>
				<PlatformCardContactsList platform={platform}/>
			</SC.PlatformCardMainInfo>
			<SC.PlatformCardAdditionalInfo>
				<SC.LogoDesktop onClick={() => onClickToOrg?.(platform?.org_name)}>
					<SC.PlatformCardOrganizationLogo
						src={platform?.org_logo || noImagePlaceholder}
						alt={`Логотип - ${platform.org_name}`}
						title={`Логотип - ${platform.org_name}`}
					/>
				</SC.LogoDesktop>
				{!isMobileMod &&
					<SC.PlatformCardInfoBlockDescription>{platform?.description}</SC.PlatformCardInfoBlockDescription>}
				{isMobileMod && <Spoiler description={platform?.description}/>}
				<ButtonGroupSetting platform={platform} type={type} handleIsType={handleIsType}
									update={() => update(platform)}
									handleActions={handleActions} allModerators={allModerators} isAdmin={isAdmin}/>
			</SC.PlatformCardAdditionalInfo>
		</SC.PlatformCard>
	);
};
