import {Clock} from '../../../components/forms/form-add-service/constants/interfaces';

export const calculateTimeInMinutes = (time: Clock) => {
	return +time.hours * 60 + +time.minutes;
};

export const isMultiple = (a: number, b: number) => {
	return a % b === 0;
};
export const converterTime = (countTime: number): string => {
	let hours = Math.trunc(countTime / 4).toString();

	if (hours.length === 1) {
		hours = 0 + hours;
	}
	const minutesCount = countTime % 4;
	let minutes = '00';
	if (minutesCount === 1) {
		minutes = '15';
	}
	if (minutesCount === 2) {
		minutes = '30';
	}
	if (minutesCount === 3) {
		minutes = '45';
	}
	return `${hours}.${minutes}`;
};
// 45 => 09.00

export const coderTimeIn15 = (time: { hours: string, minutes: string }): number => {
	// hour * 4 + minutes % 15
	const hour15: number = +time.hours * 4;
	const min = +time.minutes / 15;
	return hour15 + min;
};// 9.00 => 36
export const decodeTimeFrom15 = (value: number): Clock => {
	const hours = Math.floor(value / 4).toString();
	const minutes = ((value % 4) * 15).toString().padStart(2, '0');
	return {hours, minutes};
};

export const converterTimeForInputTime = (countTime: number): string => {
	const time: string = converterTime(countTime);
	return time.replace('.', ':');
}; // 36 => 9:00

export const converterTimeFullTime = (countTime: number): string => {
	return Math.trunc(countTime / 4).toString();
}; // 36 => 9

export const converterTimeLocalString = (date: Date): string => {
	return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, 10);
};
// => '2023-06-01'

export const converterTimeString = (date: string): string => {
	if(!date){
		return '';
	}
	return `${date.slice(8, 10)}.${date.slice(5, 7)}.${date.slice(0, 4)}`;
}; // 2023-05-21 => 21.05.2023
export const converterTimeToDayMouth = (date: string): string => {
	return `${date.slice(8, 10)}.${date.slice(5, 7)}.`;
};// 2023-05-21 => 21.05.
export const converterDatetimeString = (date: string): string => {
	return `${date.slice(8, 10)}.${date.slice(5, 7)}.${date.slice(0, 4)}, ${date.slice(11, 13)}:${date.slice(14, 16)}`;
}; // 2023-12-28T18:28 => 18:28 28.12.2023
