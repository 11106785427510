import {arrStartPageCard} from '../../../shared/constants/item-start-page';
import {Section} from '../../section/section';
import img1 from './../../../assets/img/section1_icon1.png';
import img2 from './../../../assets/img/section1_icon2.png';
import img3 from './../../../assets/img/section1_icon3.png';
import styles from './section-title.module.css';

export const SectionTitle = () => {
	const arrImg = [img1, img2, img3];
	return (
		<Section className={styles.section_title}>
			<h2 className={styles.title}>
				Сделай спорт <br />
				частью своей жизни. <br />
				С нами - это очень просто
			</h2>
			<div className={styles.ag_format_container}>
				<div className={styles.ag_courses_box}>
					{arrStartPageCard.map((item, idx) => (
						<div className={styles.ag_courses_item} key={idx}>
							<div className={styles.ag_courses_item_link}>
								<div className={styles.ag_courses_item_bg}></div>
								<img className={styles.img_card} src={arrImg[idx]} alt="icon" title="icon" />
								<h3 className={styles.ag_courses_item_title}>{item}</h3>
							</div>
						</div>
					))}
				</div>
			</div>
		</Section>
	);
};
