import classNames from 'classnames';
import {CSSProperties, FC, useEffect, useState} from 'react';

import IconExclamationMarkShape
	from '../../../../../../../../../../components/svg-icon-components/icon-exclamation-mark-shape';
import {Tooltip} from '../../../../../../../../../../components/tooltip/tooltip';
import {TimePointMeta} from '../../../../../../../../../../interfaces/order';
import {setOrder} from '../../../../../../../../../../redux/reducers/user-data-reducer';
import {useAppDispatch} from '../../../../../../../../../../redux/store';
import {OrderInfo} from '../../../../../order-info/order-info';
import styles from './time-schedule-slot-cell.module.css';

interface TimeScheduleSlotCellProps {
	value?: string;
	onClick?: () => void;
	active: boolean;
	point: number;
	pointsMeta: TimePointMeta;
	className?: string;
	style?: CSSProperties | undefined;
	attach?: boolean;
	orderId?: string | undefined;
}

export const TimeScheduleSlotCell: FC<TimeScheduleSlotCellProps> = ({
																		orderId,
																		attach,
																		value,
																		onClick,
																		active,
																		point,
																		pointsMeta,
																		className,
																		style,
																	}) => {
	const {freeCount, order: ordered} = pointsMeta;
	const dispatch = useAppDispatch();
	const [warningMessage, setWarningMessage] = useState('');

	useEffect(() => {
		if (ordered) {
			dispatch(setOrder(ordered));
		}
	}, [ordered]);

	const handleOnCellClick = () => {
		const message = ordered && !attach
			? 'Это время забронировано вами'
			: (freeCount === 0
				? 'Это время занято, выберите другое'
				: '');
		setWarningMessage(message);
		if (onClick) {
			onClick();
		}
	};

	const bgColorClasses = {
		[styles.disabled]: freeCount === 0,
		[styles.busy]: !!ordered,
		[styles.errored]: !!ordered && !attach && active,
		[styles.ordered]: !!ordered && pointsMeta.order?.id === orderId,
	};
	return (
		<div
			style={style}
			className={classNames(styles.time_schedule_slot_cell, className, {
				...bgColorClasses,
				[styles.active]: active,
			})}
			data-tooltip-id={`click_point_${point}`}
			onClick={handleOnCellClick}
		>
			<div className={styles.tooltip_wrapper_box}>
				{value && <span className={classNames(styles.time_schedule_slot_cell_time)}>{value}</span>}
				<span className={styles.time_schedule_slot_cell_count}>{`свободно: ${freeCount}шт`}</span>
				{(ordered || freeCount === 0) && (
					<IconExclamationMarkShape
						data-tooltip-id={`hover_point_${point}`}
						className={styles.service_info_icon}
						width="12"
						height="12"
						strokeColor="white"
					/>
				)}
			</div>
			<Tooltip
				place={'top'}
				className={classNames(styles.cell_tooltip, bgColorClasses)}
				id={`click_point_${point}`}
				content={warningMessage}
				openOnClick={true}
			/>

			{ordered && (
				<Tooltip className={styles.base_tooltip} id={`hover_point_${point}`} place="top-start" delayHide={300}>
					<OrderInfo bgColor={{
						disabled: freeCount === 0,
						busy: !!ordered,
						errored: !!ordered && !attach && active,
						ordered: !!ordered && pointsMeta.order?.id === orderId,
					}} order={ordered}/>
				</Tooltip>
			)}
		</div>
	);
};
