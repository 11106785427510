import {DayInfo, ScheduleFrequency, ScheduleFrequencyMonthly, ScheduleFrequencyOnceDate, ScheduleFrequencyWeekly, ScheduleFrequencyYearly, ScheduleInfo} from '../../../../../../../../interfaces/interfaces';

export enum ScheduleFrequencyType {
    UNKNOWN = 'Unknown',
    DAILY = 'Daily',
    WEEKLY = 'Weekly',
    MONTHLY = 'Monthly',
    YEARLY = 'Yearly',
    ONCE_DATE = 'OnceDate'
}

export enum ScheduleCalendarType {
    DAILY = 'Daily',
    WEEKLY = 'Weekly',
    MONTHLY_FOR_DAY = 'MonthlyForDay',
    MONTHLY_FOR_WEEK = 'MonthlyForWeek',
    YEARLY_FOR_DAY = 'YearlyForDay',
    YEARLY_FOR_WEEK = 'YearlyForWeek',
    ONCE_DATE = 'OnceDate'
}

export type ScheduleItemConfig = {
    title: string,
    color: string,
    options: string[],
};

export type ScheduleViewItems = {
    items: {[type: string]: ScheduleItemConfig};
};

export interface ListRuleType {
    startAt: number,
    endAt: number,
    weekend: boolean,
    price?: number,
    frequency: ListFrequencyType[]
}

export interface ListFrequencyType {
    optionId: string,
    type: ScheduleFrequencyType,
    value: RuleType,
}

export interface RuleType {
    day: number | null,
    numWeek: number | null,
    week: number | null,
    month: number | null,
    anyDay: string | null,
}

export interface RulesType {
    value: RuleType | null,
    type: string
}

const extractDailyRule = (rule: ListFrequencyType): ScheduleFrequency | null => {
    if (rule.type !== ScheduleFrequencyType.DAILY) {
        return null;
    }

    return 'Daily';
};

const extractWeeklyRule = (rule: ListFrequencyType): ScheduleFrequencyWeekly | null => {
    if (rule.type !== ScheduleFrequencyType.WEEKLY || !rule.value.numWeek) {
        return null;
    }

    return {
        Weekly: {
            day: rule.value.numWeek
        }
    };
};

const extractDayForWeek = (rule: ListFrequencyType): number | null => {
    if (rule.value.week === null) {
        if (!rule.value.day){
            return null;
        }
        return rule.value.day;
    }

    if (!rule.value.numWeek){
        return null;
    }
    return rule.value.numWeek;
};

const extractMonthlyRule = (rule: ListFrequencyType): ScheduleFrequencyMonthly | null => {
    if (rule.type !== ScheduleFrequencyType.MONTHLY) {
        return null;
    }

    const day = extractDayForWeek(rule);
    if(!day) {
        return null;
    }

    return {
        Monthly: {
            day,
            week: rule.value.week
        }
    };
};

const extractYearlyRule = (rule: ListFrequencyType): ScheduleFrequencyYearly | null => {
    if (rule.type !== ScheduleFrequencyType.YEARLY || !rule.value.month) {
        return null;
    }

    const day = extractDayForWeek(rule);
    if(!day) {
        return null;
    }
    return {
        Yearly: {
            day,
            week: rule.value.week,
            month: rule.value.month,
        }
    };
};

const extractOnceDateRule = (rule: ListFrequencyType): ScheduleFrequencyOnceDate | null => {
    if (rule.type !== ScheduleFrequencyType.ONCE_DATE || !rule.value.anyDay) {
        return null;
    }

    return {
        OnceDate: {
            date: rule.value.anyDay.split('-').reverse().join('-')
        }
    };
};

export const extractRule = (rule: ListRuleType): ScheduleInfo[] | null => {
    const infos = rule.frequency.map((listFrequency) => {
        const frequency =
            extractDailyRule(listFrequency) ||
            extractWeeklyRule(listFrequency) ||
            extractMonthlyRule(listFrequency) ||
            extractYearlyRule(listFrequency) ||
            extractOnceDateRule(listFrequency);
        if (!frequency) {
            return null;
        }
        const schedule: DayInfo = rule.weekend ? 'Weekend' : {
            Working: {
                schedule: [
                    {
                        start_working: rule.startAt,
                        end_working: rule.endAt,
                        property: rule.price ? {price_policy: {price: rule.price}} : null
                    }
                ]
            }
        };

        return {
            frequency,
            schedule
        };
    });

    if (infos.find((info) => info === null) !== undefined) {
        return null;
    }

    return infos.filter((info) => info !== null) as ScheduleInfo[];
};
