import classNames from 'classnames';
import {formatDuration} from 'date-fns';
import {ru} from 'date-fns/locale';
import {FC} from 'react';

import {displayAddress} from '../../../interfaces/interfaces';
import {ServiceListItem} from '../../../interfaces/service';
import {UNLIMITED} from '../../../pages/service-list-page/constants';
import {priceTitle} from '../../../shared/constants/service/kind';
import {ORDER_TYPE_LABEL_MAP} from '../../../shared/constants/service/order-policy';
import {converterPriceToString} from '../../../shared/utils/converter-price';
import {useServicePrice} from '../../../shared/utils/service/use-service-price';
import {ListWithIcons} from '../../list-with-icons/list-with-icons';
import IconCount from '../../svg-icon-components/icon-count';
import IconDollar from '../../svg-icon-components/icon-dollar';
import IconExclamationMark from '../../svg-icon-components/icon-exclamation-mark';
import IconExclamationMarkShape from '../../svg-icon-components/icon-exclamation-mark-shape';
import IconLocation from '../../svg-icon-components/icon-location';
import IconTime from '../../svg-icon-components/icon-time';
import {Tooltip} from '../../tooltip/tooltip';
import styles from './service-info-icon-tooltip.module.css';

interface ServiceInfoIconTooltipProps {
	service: ServiceListItem;
	onGoToMap?: () => void;
	iconColor?: string;
}

export const ServiceInfoIconTooltip: FC<ServiceInfoIconTooltipProps> = ({
	service,
	onGoToMap,
	iconColor = 'green',
}) => {
	const priceInfo = useServicePrice(service.schedule, service.order_policy.periodic, service.price);
	return (
		<>
			<IconExclamationMarkShape
				data-tooltip-id={`service-info-tooltip-${service.id}`}
				className={styles.service_info_icon}
				strokeColor={iconColor}
			/>
			<Tooltip
				id={`service-info-tooltip-${service.id}`}
				clickable={true}
				place="top"
				className={classNames(styles.service_info_icon_base_tooltip, styles.service_info_icon_tooltip)}
				opacity={1}
			>
				<ListWithIcons>
					<ListWithIcons.Item icon={<IconLocation />} contentClassName={styles.list_item_content}>
						<span className={styles.label}>
							{displayAddress(service.platform_info.city, service.platform_info.location_info.address)}
						</span>
						<div onClick={onGoToMap} className={styles.tooltip_link}>
							на карту
						</div>
					</ListWithIcons.Item>
					<ListWithIcons.Item icon={<IconDollar/>} contentClassName={styles.list_item_content}>
						<p className={styles.label}>Стоимость за час</p>
						<p>{priceInfo? `от ${converterPriceToString(priceInfo.minPricePerHour)}` : 'Не определена'}</p>
					</ListWithIcons.Item>
					<ListWithIcons.Item icon={<IconDollar />} contentClassName={styles.list_item_content}>
						<p className={styles.label}>{priceTitle(service.service_kind)}</p>
						<p> {priceInfo ? `от ${converterPriceToString(priceInfo.minPricePerOrder)}` : 'Не определена'}</p>
					</ListWithIcons.Item>
					<ListWithIcons.Item icon={<IconTime />} contentClassName={styles.list_item_content}>
						<p className={styles.label}>Макс. длительность</p>
						<p>
							{service.order_policy.max_user_hours
								? `${service.order_policy.max_user_hours / 4} ч.`
								: UNLIMITED}
						</p>
					</ListWithIcons.Item>
					<ListWithIcons.Item icon={<IconCount />} contentClassName={styles.list_item_content}>
						<p className={styles.label}>Макс. количество</p>
						<p>
							{service.order_policy.max_user_services
								? `${service.order_policy.max_user_services} шт.`
								: UNLIMITED}
						</p>
					</ListWithIcons.Item>
					<ListWithIcons.Item icon={<IconCount />} contentClassName={styles.list_item_content}>
						<p className={styles.label}>Тип заказа</p>
						<p>{ORDER_TYPE_LABEL_MAP[service.order_policy.allows]}</p>
					</ListWithIcons.Item>
					<ListWithIcons.Item icon={<IconExclamationMark />} contentClassName={styles.list_item_content}>
						<p className={styles.label}>Ограничение по брони</p>
						<p>
							{typeof service.order_policy.max_date_delay === 'number'
								? formatDuration(
										{days: service.order_policy.max_date_delay},
										{
											locale: ru,
										}
								  )
								: UNLIMITED}
						</p>
					</ListWithIcons.Item>
				</ListWithIcons>
			</Tooltip>
		</>
	);
};
