import './index.css';

import {YMaps} from '@pbe/react-yandex-maps';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {HelmetProvider} from 'react-helmet-async';
import {Provider} from 'react-redux';

import {AppRouter} from './app-router';
import {store} from './redux/store';
import reportWebVitals from './reportWebVitals';
import {API_KEY_YMAPS, TYPE_PACKAGE} from './shared/constants/ymaps';
const helmetContext = {};

const root = ReactDOM.createRoot(document.querySelector('#root') as HTMLElement);
root.render(
	<HelmetProvider context={helmetContext}>
		<Provider store={store}>
			<YMaps
				preload
				query={{
					apikey: API_KEY_YMAPS,
					load: TYPE_PACKAGE,
					lang: 'ru_RU',
				}}
			>
				<AppRouter />
			</YMaps>
		</Provider>
	</HelmetProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
