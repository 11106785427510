import {UserRole} from '../../../interfaces/enums';
import {ModeratorFullRole, ModeratorRole} from '../../../interfaces/role';
import {ModeratorRoleFullMetaField, ModeratorRoleMetaField} from '../../constants/roles';
import {isObject} from '../is-object';

export const isModeratorFullRole = (role: unknown): role is ModeratorFullRole => {
	if (!isObject(role)) {
		return false;
	}

	if (!(UserRole.moderator in role)) {
		return false;
	}

	const roleMeta = role[UserRole.moderator];

	if (!isObject(roleMeta)) {
		return false;
	}

	if (!(ModeratorRoleFullMetaField in roleMeta)) {
		return false;
	}

	const platformShortInfo = roleMeta[ModeratorRoleFullMetaField];

	if (!isObject(platformShortInfo)) {
		return false;
	}

	return 'org_name' in platformShortInfo && 'platform_id' in platformShortInfo && 'city' in platformShortInfo && 'location' in platformShortInfo;
};

export const isModeratorRole = (role: unknown): role is ModeratorRole => {
	if (!isObject(role)) {
		return false;
	}

	if (!(UserRole.moderator in role)) {
		return false;
	}

	const roleMeta = role[UserRole.moderator];

	if (!isObject(roleMeta)) {
		return false;
	}

	return ModeratorRoleMetaField in roleMeta;
};
