import {FC} from 'react';

import {ServiceListItem} from '../../../../../interfaces/service';
import {ServiceInfoIconTooltip} from '../../../../service/service-info-icon-tooltip/service-info-icon-tooltip';
import {TagsSlider} from '../tags-slider/tags-slider';
import * as SC from './service-info-icon-tooltip-wrapper.style';

interface ServiceInfoIconTooltipWrapperProps{
	service: ServiceListItem
	onGoToMap: ()=> void
	checked?:boolean
	createDepsCard?:boolean
}

export const ServiceInfoIconTooltipWrapper:FC<ServiceInfoIconTooltipWrapperProps> = ({service,checked,onGoToMap, createDepsCard}) => {
	return (
		<SC.ServiceInfoIconTooltipWrapper>
			<TagsSlider service={service} checked={checked} createDepsCard={createDepsCard}/>
			<ServiceInfoIconTooltip
				service={service}
				onGoToMap={onGoToMap}
				iconColor={checked ? 'white' : 'green'}/>
		</SC.ServiceInfoIconTooltipWrapper>
	);
};
