import {ru} from 'date-fns/locale';
import {memo, useMemo, useRef} from 'react';
import {DayPicker} from 'react-day-picker';

import {useAppSelector} from '../../redux/selectors/hook';
import {infoRules} from '../../redux/selectors/orders-state-selectors';
import {useModalWindow} from '../../shared/hooks/useModalWindow';
import {
	useCalendarDate
} from '../../shared/utils/schedule-editor/components/schedule-editor-modal/components/schedule-editor-modal-calendary/useCalendarDate';
import styles from './index.module.css';

export interface CalendarModalProps {
	openFromMonth?: number;
	hideNavigation?: boolean;
	isCalendarOpened: boolean;
	closeCalendar: () => void;
	handleSelectDay?: (data: Date) => void;
	selectedDay?: Date;
	isColor?: boolean;
}

export const CalendarModal = memo(({openFromMonth,isCalendarOpened = false,closeCalendar,selectedDay,handleSelectDay = () => {}, isColor = false}: CalendarModalProps) => {
	const selectedRules = useAppSelector(infoRules);
	const calendarRef = useRef<HTMLDivElement | null>(null);
	useModalWindow(calendarRef, closeCalendar);
	const {modifiers} = useCalendarDate(selectedRules);
	const startMonth = useMemo(() => {
		return openFromMonth ? new Date(new Date().getFullYear(), openFromMonth - 1) : undefined;
	}, [openFromMonth]);

	if (!isCalendarOpened) {
		return null;
	}
	const handleIsDay = (date: Date) => {
		handleSelectDay(date);
		closeCalendar();
	};
	return (
		<div ref={calendarRef} onClick={closeCalendar} className={styles.wrapper}>
			<div className={styles.calendar}>
				<DayPicker
					mode="single"
					fromYear={2015}
					toYear={2035}
					selected={selectedDay}
					onDayClick={(e) => handleIsDay(e)}
					captionLayout="dropdown"
					styles={{
						day: {
							borderRadius: '100%',
							border: '2px solid black',
							color: 'black',
							width: '28px',
							height: '28px',
						},
						months: {
							padding: '20px',
							maxWidth: 'inherit',

						},
						month: {
							position: 'relative'
						},
						caption: {
							textTransform: 'uppercase',
							display: 'flex'
						},
						caption_label: {
							fontSize: '20px',
							fontFamily: 'Jost, sans-serif',
							fontWeight: '400',
							color: '#303030'
						},
						cell: {
							height: '12px',
							cursor: 'default'
						}

					}}
					locale={ru}
					modifiers={!isColor ? modifiers : {}}
					defaultMonth={startMonth}
					modifiersStyles={{
						Daily: {
							border: 'none',
							backgroundColor: '#CB8B2A',
							color: 'white',
						},
						Weekly: {
							border: 'none',
							backgroundColor: '#1C8666',
							color: 'white',
						},
						MonthlyForWeek: {
							border: 'none',
							backgroundColor: '#352C8D',
							color: 'white',
						},
						MonthlyForDay: {
							border: 'none',
							backgroundColor: '#352C8D',
							color: 'white',
						},
						YearlyForWeek: {
							border: 'none',
							backgroundColor: '#2C708D',
							color: 'white',
						},
						YearlyForDay: {
							border: 'none',
							backgroundColor: '#2C708D',
							color: 'white',
						},
						OnceDate: {
							border: 'none',
							backgroundColor: '#8D2C83',
							color: 'white',
						},
						WeekendDay: {
							border: 'none',
							backgroundColor: '#e2e2e2',
							color: '#cccccc',
						},
						WeekendWeek: {
							border: 'none',
							backgroundColor: '#e2e2e2',
							color: '#cccccc',
						},
						WeekendMonthlyForDay: {
							border: 'none',
							backgroundColor: '#e2e2e2',
							color: '#cccccc',
						},
						WeekendMonthlyForWeek: {
							border: 'none',
							backgroundColor: '#e2e2e2',
							color: '#cccccc',
						},
						WeekendYearlyForDay: {
							border: 'none',
							backgroundColor: '#e2e2e2',
							color: '#cccccc',
						},
						WeekendYearlyForWeek: {
							border: 'none',
							backgroundColor: '#e2e2e2',
							color: '#cccccc',
						},
						WeekendOnceDate: {
							border: 'none',
							backgroundColor: '#e2e2e2',
							color: '#cccccc',
						}
					}}
					showOutsideDays
				/>
			</div>
		</div>
	);
});
