import styled from '@emotion/styled';

export const Header = styled.header`
	position: sticky;
	top: 0;
	left: 0;
	z-index: 4;
	width: 100%;
	padding: 35px 10px 30px;
	color: var(--text-blue);
	background: var(--header-color);
	@media screen and (max-width: 1200px) {
		padding: 15px;
	}
	@media screen and (max-width: 480px) {
		padding: 20px;
	}
`;
export const FaqWrapper = styled.div`
	@media (max-width: 800px) {
		display: none;
	}
`;
export const HeaderContent = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1410px;
	margin: 0 auto;
`;
export const HeaderControls= styled.div`
	max-width: 290px;
	display: flex;
	align-items: center;
	gap: 15px;
`;
export const HeaderLogoNav = styled.div`
	width: 65%;
	display: flex;
	justify-content: space-between;
	gap: 10px;
`;
