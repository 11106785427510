import {createAsyncThunk} from '@reduxjs/toolkit';

import {registerAPI} from '../../api/registration-api';
import {InputTypesRegistration} from '../../interfaces/interfaces';
import {handleAxiosError} from '../../shared/utils/handle-axios-error';

export const fetchRegistrationUser = createAsyncThunk(
	'userDataSlice/fetchRegistrationUser',
	async (dataUser: InputTypesRegistration, {dispatch, rejectWithValue}) => {
		try {
			const response = await registerAPI.registerUser(dataUser);
			if (response.status === 200) {
				return {status: response.status, dataUser: dataUser.name};
			}
			if (response.status === 204) {
				return {status: response.status, data: response.data};
			}
		} catch (error) {
				handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
