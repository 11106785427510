import {getEmptyArray} from '../../utils/get-empty-array';
import {SERVICE_KIND_LABEL_MAP} from './kind';

// Amount filter

const AMOUNT_FILTER_OPTION_COUNT = 15;

export const AMOUNT_FILTER_OPTIONS = getEmptyArray(AMOUNT_FILTER_OPTION_COUNT).map((index) => {
	const order = index + 1;

	return {
		value: String(order),
		label: String(order),
	};
});

// Service kind filter
export const SERVICE_KIND_OPTIONS = Object.entries(SERVICE_KIND_LABEL_MAP).map(([value, label]) => ({
	value,
	label,
}));

export const ANY_ACTIVITY_OPTION = {
	value: 'Любая активность',
	label: 'Любая активность',
};

export const ONLY_ACTIVE_OPTION = {
	value: 'Только активные',
	label: 'Только активные',
};

// Activity filter
export const ACTIVITY_OPTIONS = [
	ANY_ACTIVITY_OPTION,
	ONLY_ACTIVE_OPTION
];
export const UNLIMITED_OPTION = {
	value: 'Безлимитный',
	label: 'Безлимитный',
};

export const LIMIT_OPTION = {
	value: 'Лимитированный',
	label: 'Лимитированный',
};

export const ABONEMENT_OPTIONS = [
	UNLIMITED_OPTION,
	LIMIT_OPTION
];
