import {LinksAdminRoom} from '../../../interfaces/enums';
import {TFullUserRole} from '../../../interfaces/role';
import {
	ADMIN,
	ADMIN_SETTING_GLOBAL,
	ADMIN_SETTING_ORDERS,
	ADMIN_SETTING_ORGS,
	ADMIN_SETTING_PLATFORMS,
	ADMIN_SETTING_SERVICES,
	ADMIN_SETTING_USERS,
	CREATE_SERVICE,
	SETTING_CITIES,
	SHOW_ALL_ORGS,
	SHOW_ALL_PLATFORMS,
	SHOW_ALL_USERS,
} from '../../constants/path';
import {isAdminRole, isModeratorFullRole, isOwnerRole} from '../roles';

export const buildAdminMenu = (role: TFullUserRole) => {
	const items = [];
	if(isAdminRole(role)){
		items.push({
			to: `${ADMIN}${ADMIN_SETTING_GLOBAL}${SETTING_CITIES}`,
			text: LinksAdminRoom.global,
			subpath: ADMIN_SETTING_GLOBAL,
		});
	}
	if(isAdminRole(role) || isOwnerRole(role)){
		items.push({
			to: `${ADMIN}${ADMIN_SETTING_ORGS}${SHOW_ALL_ORGS}`,
			text: LinksAdminRoom.organizations,
			subpath: ADMIN_SETTING_ORGS,
		});
	}
	if(isAdminRole(role) || isOwnerRole(role) || isModeratorFullRole(role)){
		items.push(...[{
			to: `${ADMIN}${ADMIN_SETTING_PLATFORMS}${SHOW_ALL_PLATFORMS}`,
			text: LinksAdminRoom.platforms,
			subpath: ADMIN_SETTING_PLATFORMS,
		},{
			to: `${ADMIN}${ADMIN_SETTING_SERVICES}${CREATE_SERVICE}`,
			text: LinksAdminRoom.services,
			subpath: ADMIN_SETTING_SERVICES,
		},{
			to: `${ADMIN}${ADMIN_SETTING_USERS}${SHOW_ALL_USERS}`,
			text: LinksAdminRoom.users,
			subpath: ADMIN_SETTING_USERS,
		},{
			to: `${ADMIN}${ADMIN_SETTING_ORDERS}`,
			text: LinksAdminRoom.orders,
			subpath: ADMIN_SETTING_ORDERS,
		}]);
	}
	return items;
};
