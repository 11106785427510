import {Dispatch, FC, memo, SetStateAction} from 'react';

import img from '../../../assets/img/no_image.png';
import {ServiceListItem} from '../../../interfaces/service';
import {SERVICE_KIND_LABEL_MAP} from '../../../shared/constants/service/kind';
import {converterPriceToString} from '../../../shared/utils/converter-price';
import {useServicePrice} from '../../../shared/utils/service/use-service-price';
import {
	ServiceInfoIconTooltip,
} from '../../service/service-info-icon-tooltip/service-info-icon-tooltip';
import IconCount from '../../svg-icon-components/icon-count';
import IconDollar from '../../svg-icon-components/icon-dollar';
import * as SC from './card-service-abonement.style';

interface ServiceCardInterface {
	service: ServiceListItem;
	setCheckedServices?: Dispatch<SetStateAction<ServiceListItem[]>>;
	checked: boolean;
	deleteSelectService?: (id: string) => void;
	showButtonLogo: boolean;
	unLimited?: boolean;
}

export const CardServiceAbonement: FC<ServiceCardInterface> = memo(({
																		showButtonLogo,
																		service,
																		checked,
																		deleteSelectService,
																		setCheckedServices,
																		unLimited,
																	}) => {

	const clickCheckedServices = () => {
		if (setCheckedServices) {
			setCheckedServices(prevState => [...prevState, service]);
			return;
		}
		if (deleteSelectService) {
			deleteSelectService(service.id);
		}
	};
	const priceInfo = useServicePrice(service.schedule, service.order_policy.periodic, service.price);
	return (
		<SC.ServiceCardContainer>
			<SC.ImgWrapper
				ImgWrapper={(showButtonLogo && checked && !!deleteSelectService) || (showButtonLogo && !checked && !deleteSelectService)}>
				<SC.ServiceLogo src={service.logo || img} alt={service.service_type} title={service.service_type} />
				<SC.OrgInfoLogo src={service.org_info.logo || img} alt={service.org_info.org_name}
								title={service.org_info.org_name} />
				{(showButtonLogo && checked && deleteSelectService) &&
					<SC.Button onClick={clickCheckedServices}>Удалить услугу</SC.Button>}
				{(showButtonLogo && !checked && !deleteSelectService) &&
					<SC.Button onClick={clickCheckedServices}>Выбрать услугу</SC.Button>}
			</SC.ImgWrapper>
			<SC.ServiceTextContent checked={checked}>
				<SC.SportsKindTypesWrapper>
					<SC.SportType>{SERVICE_KIND_LABEL_MAP[service.service_kind as keyof typeof SERVICE_KIND_LABEL_MAP]}</SC.SportType>
					{/* <SC.SportKind checked={checked}>{service.service_type}</SC.SportKind>*/}
					{service.sports.map((item, index) => {
						return <SC.Sport key={index}>{item}</SC.Sport>;
					})}
				</SC.SportsKindTypesWrapper>
				<SC.TitleService checked={checked}>{service.service_type}</SC.TitleService>
				<SC.ItemsWrapper checked={checked}>
					<SC.ItemContainer>
						<SC.ItemWithIcon>
							<IconDollar backgroundColor={!unLimited ? 'var(--yellow' : undefined} />
							<span>Стоимость за час</span>
						</SC.ItemWithIcon>
						<span>{priceInfo ? `от ${converterPriceToString(priceInfo.minPricePerHour)}` : 'Не указана'}</span>
					</SC.ItemContainer>
					<SC.ItemContainer>
						<SC.ItemWithIcon>
							<IconCount backgroundColor={!unLimited ? 'var(--yellow' : undefined} />
							<span>Всего штук</span>
						</SC.ItemWithIcon>
						3 шт.
					</SC.ItemContainer>
				</SC.ItemsWrapper>
				<SC.ServiceInfoIcon>
					<ServiceInfoIconTooltip
						service={service}
						iconColor={unLimited ? (checked ? 'var(--white-color)' : 'var(--green)') : (checked ? 'var(--white-color)' : 'var(--yellow)')}
					/></SC.ServiceInfoIcon>
			</SC.ServiceTextContent>
		</SC.ServiceCardContainer>
	);
});

