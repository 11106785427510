import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	backgroundColor?: string;
	strokeColor?: string;
}

const IconCall: FC<IconProps> = ({backgroundColor, strokeColor, ...restProps}) => {
	return (
		<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
			<rect width="22" height="22" rx="11" fill={backgroundColor} />
			<path
				d="M6.17527 5.44176C6.22242 5.39461 6.246 5.37103 6.26677 5.35195C6.77746 4.88268 7.56245 4.88268 8.07315 5.35195C8.09392 5.37103 8.11749 5.39461 8.16465 5.44176L9.13791 6.41502C9.67323 6.95035 9.82634 7.75878 9.52382 8.45277C9.22131 9.14677 9.37442 9.9552 9.90974 10.4905L11.5095 12.0903C12.0448 12.6256 12.8532 12.7787 13.5472 12.4762C14.2412 12.1737 15.0497 12.3268 15.585 12.8621L16.5582 13.8354C16.6054 13.8825 16.629 13.9061 16.6481 13.9269C17.1173 14.4375 17.1173 15.2225 16.6481 15.7332C16.629 15.754 16.6054 15.7776 16.5582 15.8247L15.9667 16.4163C15.4849 16.8981 14.7904 17.1003 14.1253 16.9525C9.59366 15.9455 6.05453 12.4063 5.04749 7.87468C4.89969 7.20959 5.10193 6.5151 5.58369 6.03334L6.17527 5.44176Z"
				stroke={strokeColor}
			/>
		</svg>
	);
};

IconCall.defaultProps = {
	backgroundColor: '#1c8666',
	strokeColor: '#ffffff',
};

export default IconCall;
