import {DayInfo, ScheduleInfo} from '../../../interfaces/interfaces';
import {OrderStepPolicy} from '../../../interfaces/service';
import {
	SCHEDULE_PRIORITY,
	useCalendarDate
} from '../schedule-editor/components/schedule-editor-modal/components/schedule-editor-modal-calendary/useCalendarDate';
import {
	ScheduleCalendarType
} from '../schedule-editor/components/schedule-editor-modal/components/schedule-editor-modal-settings/components/index.type';

export type ServicePriceInfo = {
	minPricePerHour: number;
	minPricePerOrder: number;
};

const getPriceFor = (dayInfo: DayInfo, policy: OrderStepPolicy | null): ServicePriceInfo | undefined => {
	if (typeof dayInfo === 'string') {
		return;
	}
	const schedule = dayInfo.Working.schedule;
	const pricesWithDurations = schedule.reduce((acc: {duration: number, price: number}[], {start_working, end_working, property}) => {
		if (property && property.price_policy && typeof property.price_policy.price === 'number') {
			acc.push({duration: end_working - start_working, price: property.price_policy.price});
		}

		return acc;
	}, []);

	if (pricesWithDurations.length === 0) {
		return;
	}
	const minPricePerOrder = Math.min(...pricesWithDurations.map(({price}) => price));
	if (policy === null) {
		const minPricePerHour = Math.min(...pricesWithDurations.map(({price, duration}) => price * 4 / duration));
		return {minPricePerHour: Math.trunc(minPricePerHour), minPricePerOrder: Math.trunc(minPricePerOrder)};
	}

	const minPricePerHour = minPricePerOrder * 4 / policy.min_order_duration;
	return {minPricePerHour: Math.trunc(minPricePerHour), minPricePerOrder: Math.trunc(minPricePerOrder)};
};

const calculatePriceService = (priceService: number, policy: OrderStepPolicy | null) => {
	if (policy) {
		return {
			minPricePerHour: Math.min(priceService / 4 * policy.min_order_duration),
			minPricePerOrder: Math.min(priceService),
		};
	} else if (policy === null) {
		return {
			minPricePerHour: Math.min(priceService),
			minPricePerOrder: Math.min(priceService),
		};
	}
	return null;
};

export const useServicePrice = (schedule: ScheduleInfo[], policy: OrderStepPolicy | null, priceService: number): ServicePriceInfo | undefined => {
	const {calendar} = useCalendarDate(schedule);
	const closestFrequencyType = SCHEDULE_PRIORITY.find((kind: ScheduleCalendarType) => {
		return calendar[kind].length !== 0;
	});

	if (!closestFrequencyType) {
		const minPrices = calculatePriceService(priceService, policy);
		if (minPrices) {
			return minPrices;
		}
		return;
	}
	const frequencyWorkingInfo = calendar[closestFrequencyType];
	const priceInfos: ServicePriceInfo[] = frequencyWorkingInfo
											.map((dayInfo) => getPriceFor(dayInfo.info, policy))
											.filter((priceInfo) => priceInfo !== undefined) as ServicePriceInfo[];
	if (priceInfos.length === 0) {
		const minPrices = calculatePriceService(priceService, policy);
		if (minPrices) {
			return minPrices;
		}
		return;
	}
	return priceInfos.reduce((acc, priceInfo) => {
		return {
			minPricePerHour: Math.min(acc.minPricePerHour, priceInfo.minPricePerHour),
			minPricePerOrder: Math.min(acc.minPricePerOrder, priceInfo.minPricePerOrder),
		};
	}, {minPricePerHour: Infinity, minPricePerOrder: Infinity});

};
