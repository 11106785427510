import {FC, memo} from 'react';
import {NavigateOptions, useNavigate} from 'react-router-dom';

import {ServiceListItem} from '../../../../../interfaces/service';
import {CounterBlock} from '../../../../../pages/order-page/components/counter-block/counter-block';
import {OrderBtnBlock} from '../../../../../pages/order-page/components/order-btn-block/order-btn-block';
import {SERVICE_PAGE} from '../../../../../shared/constants/path';
import {kindLabelTypeView, kindLabelView, priceTitle} from '../../../../../shared/constants/service/kind';
import {converterPriceToString} from '../../../../../shared/utils/converter-price';
import {useGetDayInfo} from '../../../../../shared/utils/get-day-info';
import {getServiceWorkingTime} from '../../../../../shared/utils/service/get-service-working-time';
import {useServicePrice} from '../../../../../shared/utils/service/use-service-price';
import IconCount from '../../../../svg-icon-components/icon-count';
import IconDollar from '../../../../svg-icon-components/icon-dollar';
import IconTime from '../../../../svg-icon-components/icon-time';
import {Address} from '../../components/address/address';
import {CardContainer} from '../../components/card-container/card-container';
import {Logo} from '../../components/logo/logo';
import {
	ServiceInfoIconTooltipWrapper
} from '../../components/service-info-icon-tooltip-wrapper/service-info-icon-tooltip-wrapper';
import {ServiceName} from '../../components/service-name/service-name';
import {TextWithIcon} from '../../components/text-with-icon/text-with-icon';
import * as SC from './new-service-card-order.style';

export interface ServiceCardProps {
	service: ServiceListItem;
	onClick?: () => void;
	checked?: boolean;
	amount?: number | null;
	onChangeAmount?: (amount: number) => void;
	isOrderPage?: boolean
	onSelect?: () => void
	isOrderMap?: boolean
}

export const NewServiceCardOrder: FC<ServiceCardProps> = memo(({
																   onSelect,
																   service,
																   onClick,
																   checked,
																   amount,
																   onChangeAmount,
																   isOrderPage,
																   isOrderMap
															   }) => {
	const navigate = useNavigate();
	const goToServiceCard = (options?: NavigateOptions) => {
		navigate(SERVICE_PAGE.replace(':page', service.id), options);
	};
	const goToServiceCardMap = () => {
		goToServiceCard({state: {map: true}});
	};
	const onClickHandler = () => {
		if (onClick) {
			onClick();
		}
	};
	const priceInfo = useServicePrice(service.schedule, service.order_policy.periodic, service.price);
	return (
		<CardContainer>
			<Logo orgLogo={service.org_info.logo} serviceLogo={service.logo} serviceName={service.service_type}
				  orgName={service.org_info.org_name} checked={checked} onClick={onClickHandler}/>
			<SC.TextContentWrapper checked={checked} page={!!isOrderPage || !!isOrderMap}>
				<ServiceInfoIconTooltipWrapper service={service} checked={checked} onGoToMap={goToServiceCardMap}/>
				<ServiceName serviceName={service.service_type} checked={checked}/>
				<Address platformInfo={service.platform_info} goToServiceCardMap={goToServiceCardMap}/>
				<TextWithIcon icon={<IconDollar/>} description={'Стоимость за час'}
							  textRight={priceInfo ? `от ${converterPriceToString(priceInfo.minPricePerHour)}` : 'Не определена'}/>
				<TextWithIcon icon={<IconDollar/>} description={priceTitle(service.service_kind)}
							  textRight={priceInfo ? `от ${converterPriceToString(priceInfo.minPricePerOrder)}` : 'Не определена'}/>
				<TextWithIcon icon={<IconTime/>} textRight={'Сегодня'}
							  description={getServiceWorkingTime(useGetDayInfo(new Date(), service.schedule).dayInfo)}/>
				<TextWithIcon icon={<IconCount/>} description={kindLabelView(service.service_kind)}
							  textRight={`${service.max_participants} ${kindLabelTypeView(service.service_kind)}`}/>
			</SC.TextContentWrapper>
			{(checked && isOrderPage) && <CounterBlock
				count={amount || 0}
				maxCount={service.max_participants}
				onCountChange={onChangeAmount}
			/>}
			{(checked && isOrderMap && <OrderBtnBlock onBtnClick={onSelect}/>)}
		</CardContainer>
	);
});
