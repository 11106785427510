import {FC, memo, useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import icon from '../../../assets/icon/abonement-platform.svg';
import {AbonementType} from '../../../interfaces/abonement';
import {ServiceListItem} from '../../../interfaces/service';
import {useAppDispatch} from '../../../redux/store';
import {fetchGetListServicesForAbonement} from '../../../redux/thunks/abonements/get-list-services';
import {fetchSetPayment} from '../../../redux/thunks/payment/set-payment';
import {HOST_PAYMENT, urlNotifyPayment} from '../../../shared/constants/url';
import {getIdUser, isRightTokenTime} from '../../../shared/utils/decoder-jwt';
import {isAuth, jwtUser} from '../../../shared/utils/save-local-storage';
import {CardServiceAbonement} from '../../cards/card-service-abonement/card-service-abonement';
import {Button, ShowListButton} from '../../index';
import {ModalPhoneOrder} from '../../modal-phone-order/modal-phone-order';
import IconCount from '../../svg-icon-components/icon-count';
import IconHome from '../../svg-icon-components/icon-home';
import IconTime from '../../svg-icon-components/icon-time';
import * as SC from './abonement.style';

interface AbonementInterface {
	bgColor?: string;
	borderBgPriceColor?: string;
	unLimited?: boolean;
	abonement: AbonementType;
}

export const Abonement: FC<AbonementInterface> = memo(({bgColor, unLimited, abonement, borderBgPriceColor}) => {
	const BGCOLORICON = 'var(--yellow)';
	const dispatch = useAppDispatch();
	const [services, useServices] = useState<ServiceListItem[]>([]);
	const [showAllServices, setShowAllServices] = useState(false);
	const idUser = getIdUser();
	const [phone, setPhone] = useState('');
	const [openPhoneWindow, setOpenPhoneWindow] = useState(false);
	const [openPhoneTokenTimeWindow, setOpenPhoneTokenTimeWindow] = useState(false);
	const location = useLocation();

	useEffect(() => {
		dispatch(fetchGetListServicesForAbonement({
			allow_view_only: true,
			allow_disabled: false,
			abonement_id: abonement.id,
		})).unwrap().then(res => {
			if (res) {
				useServices(res.data);
			}
		});
	}, [abonement.id]);

	const onClickShowServices = useCallback(() => setShowAllServices(prevState => !prevState), [showAllServices]);

	const onClickBuy = useCallback(() => {
		if (!idUser && !isAuth() && !jwtUser()) {
			setOpenPhoneWindow(true);
			return;
		}
		if (!isRightTokenTime()) {
			setOpenPhoneTokenTimeWindow(true);
			return;
		}
		dispatch(fetchSetPayment({
			notification_url: `${HOST_PAYMENT}${urlNotifyPayment}`,
			issuer: {Abonement: {id: abonement.id}},
			requester: {Registered: {id: idUser}},
			success_url: `${HOST_PAYMENT}${location.pathname}`,
			fail_url: `${HOST_PAYMENT}${location.pathname}`,
		}));
	}, [abonement.id, jwtUser(), isAuth(), idUser]);

	const onClickBye = () => {
		const phoneNumber = phone.replace(/[\s()\-]/g, '');
		dispatch(fetchSetPayment({
			notification_url: `${HOST_PAYMENT}${urlNotifyPayment}`,
			issuer: {Abonement: {id: abonement.id}},
			requester: {Anonym: {phone: phoneNumber}},
			success_url: `${HOST_PAYMENT}${location.pathname}`,
			fail_url: `${HOST_PAYMENT}${location.pathname}`,
		}));
		setOpenPhoneWindow(false);
		setOpenPhoneTokenTimeWindow(false);
	};
	const changePhone = useCallback((e: string) => {
		setPhone(e);
	}, [phone]);
	const closeWindowPhone = useCallback(() => {
		setOpenPhoneWindow(false);
		setOpenPhoneTokenTimeWindow(false);
	}, [openPhoneTokenTimeWindow, openPhoneWindow]);
	return (
		<SC.Container background={bgColor} borderColor={borderBgPriceColor}>
			<SC.AbonementHeader>
				<SC.TitleWrapper>
					<img src={icon} alt="Абонементы"/>
					<SC.Title>{abonement.info.name}</SC.Title>
				</SC.TitleWrapper>
				<SC.Price bgPrice={borderBgPriceColor}>
					<span>{(abonement.info.price / 100).toFixed(2)}</span>
					<SC.Valuta>BYN.</SC.Valuta>
				</SC.Price>
			</SC.AbonementHeader>
			<SC.TextContentWrapper>
				<SC.NamePlatformWrapper>
					<SC.IconWrapper>
						<IconHome backgroundColor={borderBgPriceColor || BGCOLORICON}/>
					</SC.IconWrapper>
					<SC.NamePlatform>{abonement.org_info.org_name}</SC.NamePlatform>
				</SC.NamePlatformWrapper>
				<SC.Details>
					<SC.DetailWrapper>
						<SC.DetailLabel>Вид абонемента</SC.DetailLabel>
						<SC.TextWithIconWrapper>
							<IconCount backgroundColor={borderBgPriceColor || BGCOLORICON} width={24} height={24}/>
							<SC.Count>{unLimited ? 'Безлимитный' : 'Лимитированный'}</SC.Count>
						</SC.TextWithIconWrapper>
					</SC.DetailWrapper>
					<SC.DetailWrapper>
						<SC.DetailLabel>Количество занятий</SC.DetailLabel>
						<SC.TextWithIconWrapper>
							<IconCount backgroundColor={borderBgPriceColor || BGCOLORICON} width={24} height={24}/>
							<SC.Count>{abonement.info.limit}</SC.Count>
						</SC.TextWithIconWrapper>
					</SC.DetailWrapper>
					<SC.DetailWrapper>
						<SC.DetailLabel>Длительность абонемента</SC.DetailLabel>
						<SC.TextWithIconWrapper>
							<IconTime backgroundColor={borderBgPriceColor || BGCOLORICON} width={24} height={24}/>
							<SC.Count>{abonement.info.duration.Month}</SC.Count>
						</SC.TextWithIconWrapper>
					</SC.DetailWrapper>
				</SC.Details>
			</SC.TextContentWrapper>
			<SC.ButtonWrapper>
				<ShowListButton bgColor={unLimited ? 'green' : 'yellow'} prevText={'Подробнее'}
								nextText={'Скрыть подробности'} showAll={showAllServices}
								onClickShowList={onClickShowServices}/>
				{abonement.payment_method !== 'CASH' &&
					<Button label={'Купить'} bgColor={unLimited ? 'green' : 'yellow'} onClick={onClickBuy}/>}
			</SC.ButtonWrapper>
			{showAllServices && <SC.DetailsContainer>
				{abonement.info.description && <SC.TextWithIconWrapper>
					<SC.IconWrapper>
						<IconCount backgroundColor={borderBgPriceColor || BGCOLORICON}/>
					</SC.IconWrapper>
					<SC.Description>{abonement.info.description}</SC.Description>
				</SC.TextWithIconWrapper>}
				<SC.ServiceCardsLabel>В абонемент входят:</SC.ServiceCardsLabel>
				<SC.ServiceCardsContainer countServices={services.length > 1}>
					{services.map(i => {
						return <CardServiceAbonement unLimited={unLimited} showButtonLogo={false} key={i.id} service={i}
													 checked={false}/>;
					})}
				</SC.ServiceCardsContainer>
			</SC.DetailsContainer>}
			{openPhoneWindow &&
				<ModalPhoneOrder onChangePhone={changePhone} orderClick={onClickBye} closeWindow={closeWindowPhone}
								 titleButton={'Купить'}/>}

			{openPhoneTokenTimeWindow &&
				<ModalPhoneOrder onChangePhone={changePhone} orderClick={onClickBye} closeWindow={closeWindowPhone}
								 description={'Время сеанса истекло. Авторизуйтесь снова или введите номер телефона'}
								 titleButton={'Купить'}/>}
		</SC.Container>
	);
});
