import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	strokeColor?: string;
}

const IconSettingsShape: FC<IconProps> = ({strokeColor, ...restProps}) => {
	return (
		<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
			<path
				d="M12.6055 17.5C12.8816 17.5 13.1055 17.2761 13.1055 17C13.1055 16.7239 12.8816 16.5 12.6055 16.5V17.5ZM4.60547 16.5C4.32933 16.5 4.10547 16.7239 4.10547 17C4.10547 17.2761 4.32933 17.5 4.60547 17.5L4.60547 16.5ZM12.6055 6.5C12.3293 6.5 12.1055 6.72386 12.1055 7C12.1055 7.27614 12.3293 7.5 12.6055 7.5V6.5ZM20.6055 7.5C20.8816 7.5 21.1055 7.27614 21.1055 7C21.1055 6.72386 20.8816 6.5 20.6055 6.5V7.5ZM12.6055 16.5L4.60547 16.5L4.60547 17.5L12.6055 17.5V16.5ZM12.6055 7.5L20.6055 7.5V6.5L12.6055 6.5V7.5ZM4.10547 7C4.10547 8.38071 5.22476 9.5 6.60547 9.5V8.5C5.77704 8.5 5.10547 7.82843 5.10547 7H4.10547ZM6.60547 9.5C7.98618 9.5 9.10547 8.38071 9.10547 7H8.10547C8.10547 7.82843 7.4339 8.5 6.60547 8.5V9.5ZM9.10547 7C9.10547 5.61929 7.98618 4.5 6.60547 4.5V5.5C7.4339 5.5 8.10547 6.17157 8.10547 7H9.10547ZM6.60547 4.5C5.22476 4.5 4.10547 5.61929 4.10547 7H5.10547C5.10547 6.17157 5.77704 5.5 6.60547 5.5V4.5ZM21.1055 17C21.1055 15.6193 19.9862 14.5 18.6055 14.5V15.5C19.4339 15.5 20.1055 16.1716 20.1055 17H21.1055ZM18.6055 14.5C17.2248 14.5 16.1055 15.6193 16.1055 17H17.1055C17.1055 16.1716 17.777 15.5 18.6055 15.5V14.5ZM16.1055 17C16.1055 18.3807 17.2248 19.5 18.6055 19.5V18.5C17.777 18.5 17.1055 17.8284 17.1055 17H16.1055ZM18.6055 19.5C19.9862 19.5 21.1055 18.3807 21.1055 17H20.1055C20.1055 17.8284 19.4339 18.5 18.6055 18.5V19.5Z"
				fill={strokeColor}
			/>
		</svg>
	);
};

IconSettingsShape.defaultProps = {
	strokeColor: '#b3b3B3',
};

export default IconSettingsShape;
