import {createAsyncThunk} from '@reduxjs/toolkit';

import {registerAPI} from '../../../api/registration-api';
import {messageSuccessUpdatePlatform} from '../../../shared/constants/message-user';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setIsShowMessage, setMessageUser, setShowLoader} from '../../reducers/user-data-reducer';

export const fetchAddAllowsServiceType = createAsyncThunk(
	'sportSlice/fetchAddAllowsServiceType',
	async (data: { typeSport: string; typeService: { service_type: string } }, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await registerAPI.addAllowsServicesTypes(data.typeSport, data.typeService);
			dispatch(setShowLoader({isShowLoader: false}));
			if (response.status === 200) {
				dispatch(setMessageUser({messageUser: messageSuccessUpdatePlatform}));
				dispatch(setIsShowMessage({isShowMessage: true}));
				return {status: response.status, data: response.data};
			}
		} catch (error) {
			handleAxiosError({dispatch, rejectWithValue, error});
		}
	},
);
