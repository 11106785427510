import {createAsyncThunk} from '@reduxjs/toolkit';

import {registerAPI} from '../../api/registration-api';
import {UserRequestAuth} from '../../interfaces/interfaces';
import {messageAuthError} from '../../shared/constants/message-user';
import {jwtDecode, jwtParserRole} from '../../shared/utils/decoder-jwt';
import {handleAxiosError} from '../../shared/utils/handle-axios-error';
import {resetInfoOrdersUser} from '../reducers/orders-reducer';
import {resetDataUser, setIsShowMessage, setMessageUser, setShowLoader,} from '../reducers/user-data-reducer';

export const fetchAuthorizeUser = createAsyncThunk(
	'userDataSlice/fetchAuthorizeUser',
	async (dataUser: UserRequestAuth, {dispatch, rejectWithValue}) => {
		try {
			const response = await registerAPI.authUser(dataUser);

			if (response.status === 200) {
				return {
					status: response.status,
					jwt: response.data.token,
					login: dataUser.login,
					jwtData: jwtParserRole(jwtDecode(response.data.token)),
					dataUser,
				};
			}
			if (response.status === 204) {
				localStorage.clear();
				dispatch(resetDataUser());
				dispatch(resetInfoOrdersUser());
				dispatch(setShowLoader({isShowLoader: false}));
				dispatch(setMessageUser({messageUser: messageAuthError}));
				dispatch(setIsShowMessage({isShowMessage: true}));
				return {
					status: response.status,
				};
			}
		} catch (error) {
				handleAxiosError({dispatch,error,rejectWithValue});
		}
	},
);
