import classNames from 'classnames';
import {FC} from 'react';
import {ITooltip, Tooltip as BaseTooltip} from 'react-tooltip';

import styles from './tooltip.module.css';

interface TooltipProps extends ITooltip {
	id?: string;
}

export const Tooltip: FC<TooltipProps> = ({children, className, id, ...restProps}) => {
	return (
		<BaseTooltip id={id} noArrow={true} {...restProps} className={classNames(styles.base_tooltip, className)}>
			{children}
		</BaseTooltip>
	);
};

Tooltip.defaultProps = {
	place: 'bottom',
};
