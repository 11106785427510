import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	strokeColor?: string;
}

const IconCloseThin: FC<IconProps> = ({strokeColor, ...restProps}) => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill={strokeColor} xmlns="http://www.w3.org/2000/svg" {...restProps}>
			<path d="M1 1L15 15M1.00003 15L8.00003 8L15 1" stroke="#202020" strokeWidth="1.5" strokeLinecap="round"/>
		</svg>
	);
};

IconCloseThin.defaultProps = {
	strokeColor: '#912436',
};

export default IconCloseThin;
