import cnBind from 'classnames/bind';
import {FC, memo} from 'react';

import {displayAddress} from '../../../../../interfaces/interfaces';
import {PlatformSingle} from '../../../../../interfaces/platform';
import {ListWithIcons} from '../../../../list-with-icons/list-with-icons';
import IconCall from '../../../../svg-icon-components/icon-call';
import IconInstagram from '../../../../svg-icon-components/icon-instagram';
import IconLocation from '../../../../svg-icon-components/icon-location';
import IconTelegram from '../../../../svg-icon-components/icon-telegram';
import IconTime from '../../../../svg-icon-components/icon-time';
import IconWebSite from '../../../../svg-icon-components/icon-web-site';
import {WorkSchedule} from '../../../../WorkSchedule/WorkSchedule';
import styles from './index.module.css';

const cx = cnBind.bind(styles);

interface PlatformCardContactsPageType {
	platformSingle: PlatformSingle
	classNames?: string;
}

export const PlatformCardContactsPage:FC<PlatformCardContactsPageType> = memo(({platformSingle, classNames}) => {
	const contacts = platformSingle.contacts;
	const getUserNikFromInstUrl = (arr: string[]) => {
		if (arr.at(-1) === '') {
			return arr.at(-2);
		}
		return arr.at(-1);

	};

	return (
		<ListWithIcons
			className={cx('contacts_list', classNames)}>
			<ListWithIcons.Item icon={<IconLocation/>} contentClassName={cx('list_item_content')}>
				<p className={cx('contacts_list_item_label')}>
					{displayAddress(platformSingle.city, platformSingle.location_info.address)}
				</p>
			</ListWithIcons.Item>
			<ListWithIcons.Item icon={<IconTime/>} contentClassName={cx('list_item_content')}>
				<WorkSchedule schedule={platformSingle.schedule}/>
			</ListWithIcons.Item>
			{contacts.viber &&
				<ListWithIcons.Item icon={<IconCall/>} contentClassName={cx('contacts_list_item_content')}>
					<p className={cx('contacts_list_item_label')}>{contacts.viber}</p>
				</ListWithIcons.Item>
			}
			{contacts.instagram &&
				<ListWithIcons.Item icon={<IconInstagram/>} contentClassName={cx('contacts_list_item_content')}>
					<a href={contacts.instagram} className={cx('contacts_list_item_label', 'link')}
					   target="_blank">
						@{getUserNikFromInstUrl(contacts.instagram.split('/'))}
					</a>
				</ListWithIcons.Item>
			}
			{contacts.telegram &&
				<ListWithIcons.Item icon={<IconTelegram/>} contentClassName={cx('contacts_list_item_content')}>
					<a href={contacts.telegram} className={cx('contacts_list_item_label', 'link')}
					   target="_blank">
						@{contacts.telegram.split('/').pop()}
					</a>
				</ListWithIcons.Item>
			}
			{contacts.web_site &&
				<ListWithIcons.Item icon={<IconWebSite/>} contentClassName={cx('contacts_list_item_content')}>
					<a href={contacts.web_site} className={cx('contacts_list_item_label', 'link')}
					   target="_blank">
						{contacts.web_site.replace('https://', '')}
					</a>
				</ListWithIcons.Item>
			}
		</ListWithIcons>
	);
});
