import {InputMaskChangeEvent} from 'primereact/inputmask';
import {FC, memo, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';

import {setOpenForUrl} from '../../redux/reducers/user-data-reducer';
import {useAppDispatch} from '../../redux/store';
import {AUTH} from '../../shared/constants/path';
import {isValidPhoneNumber} from '../../shared/utils/is-valid-input- phone-email';
import {handleScrollToTop} from '../../shared/utils/scroll-to-top';
import {Button} from '../buttons';
import PhoneInput from '../controls/phone-input/phone-input';
import {RegistrationConditions} from '../registration-conditions/registration-conditions';
import IconCallShape from '../svg-icon-components/icon-call-shape';
import * as SC from './modal-phone-order.style';

interface ModalPhoneOrderProps {
	onChangePhone: (e: string) => void;
	orderClick: () => void;
	closeWindow: () => void;
	description?: string;
	titleButton?: string;
}

export const ModalPhoneOrder: FC<ModalPhoneOrderProps> = memo(({
																   onChangePhone,
																   closeWindow,
																   orderClick,
																   titleButton,
																   description
															   }) => {
	const [valid, setValid] = useState(false);
	const dispatch = useAppDispatch();
	const location = useLocation();

	const onChangePhoneHandler = (e: InputMaskChangeEvent) => {
		const value = e.target.value;
		if (value) {
			onChangePhone(value);
			setValid(isValidPhoneNumber(value));
		}
	};

	const clickLogin = () => {
		dispatch(setOpenForUrl(location.pathname + location.search));
		handleScrollToTop();
	};
	return (
		<SC.Container>
			<SC.Modal>
				{description && <SC.Description>{description}</SC.Description>}
				{!description && <SC.Title>Введите номер телефона</SC.Title>}
				<PhoneInput
					data-tooltip-id="phone"
					onChange={onChangePhoneHandler}
					type="tel"
					icon={<IconCallShape/>}
					placeholder="Телефон"
					required={true}
				/>
				<Button label={titleButton || 'Забронировать'} onClick={orderClick} disabled={!valid} disabledGgColorMode={'dark'}/>
				<Link to={AUTH}><Button label={'Войти'} bgColor={'green'} onClick={clickLogin}/></Link>
				<RegistrationConditions/>
				<SC.CloseBtn onClick={closeWindow}>X</SC.CloseBtn>
			</SC.Modal>
		</SC.Container>
	);
});
