import {useEffect,useState} from 'react';

export const useResizeWindow = (): number => {
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = (event: Event) => {
			if (event.target instanceof Window) {
				setWidth(event.target.innerWidth);
			}
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return width;
};
