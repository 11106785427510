import styled from '@emotion/styled';
import {Link} from 'react-router-dom';

export const RegistrationConditions = styled.div`
	color: var(--black-color);
	font-size: 16px;
	font-weight: 400;
	line-height: 128.8%;
	text-align: left;
	margin-top: 1rem;
`;
export const RegistrationConditionsLink = styled(Link)`
	color: var(--red-color);
`;
