import axios from 'axios';

import {HOST_PAYMENT, urlPayment} from '../shared/constants/url';
import {SetPayment} from './query-params';

const instance = axios.create({
	baseURL: HOST_PAYMENT,
});

export const SearchPayment = {
	setPayment(params: SetPayment) {
		return instance.post(urlPayment, params);
	},
};
