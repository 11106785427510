export const extractUniqueTimePairs = (arr: string[]): string[] => {
	const timeArray: string[] = arr.map(timeString => timeString.split('-')).flat();
	const countMap: Record<string, number> = {};
	const duplicates: string[] = [];

	timeArray.forEach(value => {
		countMap[value] = (countMap[value] || 0) + 1;
		if (countMap[value] === 2) {
			duplicates.push(value);
		}
	});

	const uniqueArray: string[] = timeArray.filter(value => !duplicates.includes(value));

	const pairedArray =uniqueArray.reduce((pairs: [string, string][], value, index, array) => {
		if (index % 2 === 0) {
			pairs.push([value, array[index + 1]]);
		}
		return pairs;
	}, []);

	return pairedArray.map(time => time.join(' - '));
};
