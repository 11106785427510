import classNames from 'classnames';
import type {Nullable} from 'primereact/ts-helpers';
import {FC, MouseEvent, useEffect, useRef, useState} from 'react';

import styles from './Spoiler.module.css';

type Props = {
	description: Nullable<string>
};

export const Spoiler: FC<Props> = ({description}) => {
	const contentRef = useRef<HTMLDivElement>(null);
	const [heightElement, setHeightElement] = useState(0);
	const [showInfo, setShowInfo] = useState(false);

	const sowMode = showInfo ? styles.show_block_description : styles.unShow_block_description;

	const showSpoiler = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		setShowInfo(!showInfo);
	};

	useEffect(() => {
		if (contentRef.current) {
			setHeightElement(contentRef.current.clientHeight);
		}
	}, [contentRef?.current?.clientHeight]);

	return (
		<div className={styles.description_text_wrapper}>
			<p ref={contentRef}
			   className={`${styles.description_text} ${heightElement > 30 ? sowMode : ''}`}>{description}</p>

			{heightElement > 25 && (
				<div className={styles.show_button}>
					<button type='button'
							className={classNames('btn_yellow', 'btn_yellow_highlight', styles.button)}
							onClick={showSpoiler}>Подробнее
					</button>
				</div>
			)}
		</div>
	);
};
