import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	strokeColor?: string;
}

const IconExclamationMarkShape: FC<IconProps> = ({strokeColor, ...restProps}) => {
	return (
		<svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
			<g clipPath="url(#clip0_1557_2563)">
				<mask id="path-1-inside-1_1557_2563" fill="white">
					<path d="M36.5 18.5C36.5 28.165 28.665 36 19 36C9.33502 36 1.5 28.165 1.5 18.5C1.5 8.83502 9.33502 1 19 1C28.665 1 36.5 8.83502 36.5 18.5Z" />
				</mask>
				<path
					d="M20.5 7.73077C20.5 6.90234 19.8284 6.23077 19 6.23077C18.1716 6.23077 17.5 6.90234 17.5 7.73077H20.5ZM17.5 22.5385C17.5 23.3669 18.1716 24.0385 19 24.0385C19.8284 24.0385 20.5 23.3669 20.5 22.5385H17.5ZM20.5 27.9231C20.5 27.0947 19.8284 26.4231 19 26.4231C18.1716 26.4231 17.5 27.0947 17.5 27.9231H20.5ZM17.5 29.2692C17.5 30.0977 18.1716 30.7692 19 30.7692C19.8284 30.7692 20.5 30.0977 20.5 29.2692H17.5ZM17.5 7.73077V22.5385H20.5V7.73077H17.5ZM17.5 27.9231V29.2692H20.5V27.9231H17.5ZM33.5 18.5C33.5 26.5081 27.0081 33 19 33V39C30.3218 39 39.5 29.8218 39.5 18.5H33.5ZM19 33C10.9919 33 4.5 26.5081 4.5 18.5H-1.5C-1.5 29.8218 7.67816 39 19 39V33ZM4.5 18.5C4.5 10.4919 10.9919 4 19 4V-2C7.67816 -2 -1.5 7.17816 -1.5 18.5H4.5ZM19 4C27.0081 4 33.5 10.4919 33.5 18.5H39.5C39.5 7.17816 30.3218 -2 19 -2V4Z"
					fill={strokeColor}
					mask="url(#path-1-inside-1_1557_2563)"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1557_2563">
					<rect width="36" height="36" fill="white" transform="translate(0.5)" />
				</clipPath>
			</defs>
		</svg>
	);
};

IconExclamationMarkShape.defaultProps = {
	strokeColor: '#1c8666',
};

export default IconExclamationMarkShape;
