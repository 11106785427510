import {FC, ReactNode} from 'react';

import * as SC from './text-with-icon.style';

interface TextWithIconProps{
	icon: ReactNode
	description: string | string[]
	textRight: string
}
export const TextWithIcon:FC<TextWithIconProps> = ({icon, description, textRight}) => {
	return (
		<SC.Wrapper>
			<SC.IconWrapper>
				{icon}
				<SC.Description>{description}</SC.Description>
			</SC.IconWrapper>
			<SC.TextRight>{textRight}</SC.TextRight>
		</SC.Wrapper>
	);
};
