import {FC, useEffect, useRef, useState} from 'react';
import Slider from 'react-slick';

import {IService} from '../../../../../interfaces/service';
import {SERVICE_KIND_LABEL_MAP,} from '../../../../../shared/constants/service/kind';
import IconArrowLeftBig from '../../../../svg-icon-components/icon-arrow-left-big';
import IconArrowRightBig from '../../../../svg-icon-components/icon-arrow-right-big';
import {TagList} from '../../../../tag-list/tag-list';
import styles from './tags-slider.module.css';

interface TagsSliderProps {
	service: IService;
	checked?: boolean;
	createDepsCard?: boolean;
}

export const TagsSlider: FC<TagsSliderProps> = ({service, createDepsCard, checked}) => {
	const sliderRef = useRef<Slider>(null);
	const [slideCount, setSlideCount] = useState<number>(0);
	const [currentSlide, setCurrentSlide] = useState<number>(0);
	const beforeChange = (_: any, next: any) => {
		setCurrentSlide(next);
	};

	useEffect(() => {
		setSlideCount((sliderRef.current?.innerSlider as any)?.state.slideCount);
	}, []);

	const settings = {
		dots: false,
		arrows: false,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		rows: 3,
		beforeChange,
	};

	const tags = [
		<TagList.Tag className={createDepsCard ? styles.checked_green_tag : styles.orange_tag}>
			{SERVICE_KIND_LABEL_MAP[service.service_kind as keyof typeof SERVICE_KIND_LABEL_MAP]}
		</TagList.Tag>,
		<span className={styles.tads_wrapper}>
			{[...service.sports.map((sport, index) => (
				<TagList.Tag className={styles.sport_tag} key={index}>
					{sport}
				</TagList.Tag>
			))]}
		</span>,
		// <TagList.Tag
		// 	className={service.service_kind === 'Workout'
		// 		? (checked ? styles.checked_green_tag : styles.green_tag_workout)
		// 		: (checked ? styles.checked_green_tag : styles.green_tag)}>{service.service_type}</TagList.Tag>,
	];

	return (<div className={styles.tags_slider}>
		{currentSlide !== 0 && <div className={styles.arrow_left} onClick={() => {
			sliderRef.current?.slickPrev();
		}}><IconArrowLeftBig strokeColor={checked || createDepsCard ? 'white' : 'green'}/></div>}
		<Slider {...settings} className="multirows_slider" ref={sliderRef}>
			{tags.map((item, index) => (<div className={'multirows_slider_tags'} key={index}>{item}</div>))}
		</Slider>
		{currentSlide !== slideCount - 1 && <div className={styles.arrow_right} onClick={() => {
			sliderRef.current?.slickNext();
		}}><IconArrowRightBig strokeColor={checked || createDepsCard ? 'white' : 'green'}/></div>}
	</div>);

};
