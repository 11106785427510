import styled from '@emotion/styled';

interface ContainerStyle {
	background?: string;
	borderColor?: string;
}

interface PriceStyle {
	bgPrice?: string;
}

interface IconWrapperInterface {
	countServices: boolean;
}

export const Container = styled.div<ContainerStyle>`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	height: fit-content;
	gap: 20px;
	border: 1px solid ${props => props.borderColor ? props.borderColor : 'var(--yellow)'};
	border-radius: 8px;
	padding: 20px;
	background: ${props => props.background ? props.background : 'rgba(203, 139, 42, 0.1)'};
	color: var(--link-color);

	button {
		height: 36px;
		font-weight: 400;
		font-size: 20px;
		width: fit-content;
		div {
			text-wrap: wrap;
			gap: 4px
		}
	}
	@media screen and (max-width: 600px){
		button {
			height: fit-content;
			padding: 4px 25px;
		}
	}
`;
export const AbonementHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	@media screen and (max-width: 605px) {
		justify-content: flex-start;
		align-items: flex-start;
		flex-direction: column;
		gap: 10px;
		margin-bottom: 20px;
	}
`;
export const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	width: 90%;
`;
export const Title = styled.h2`
	font-weight: 700;
	font-size: 24px;
	color: var(--black-brown);
`;
export const Price = styled.span<PriceStyle>`
	display: flex;
	gap: 10px;
	height: 50px;
	border-radius: 8px;
	align-items: baseline;
	padding: 6px 26px;
	background-color: ${props => props.bgPrice ? props.bgPrice : 'var(--yellow)'};
	font-weight: 700;
	font-size: 28px;
	color: var(--white-color);
`;
export const Valuta = styled.span`
	font-size: 18px;
`;
export const TextContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	justify-content: flex-start;
`;
export const Description = styled.p`
	font-weight: 400;
	font-size: 18px;
`;
export const DetailsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;
export const TextWithIconWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 10px
`;
export const NamePlatformWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 10px;
`;
export const NamePlatform = styled.p`
	font-weight: 600;
	font-size: 20px;
`;
export const Details = styled.div`
	display: flex;
	justify-content: space-between;
	width: 95%;
	gap: 12px;
	@media screen and (max-width: 1170px) {
		flex-wrap: wrap;
	}
	@media screen and (max-width: 605px) {
		justify-content: flex-start;
		flex-direction: column;
	}
`;
export const Count = styled.h3`
	font-weight: 600;
	font-size: 18px;
`;
export const DetailWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 6px;
	justify-content: flex-start;
`;
export const DetailLabel = styled.span`
	font-weight: 300;
	font-size: 14px;
	line-height: 18px;
`;
export const IconWrapper = styled.span`
	width: 24px;
	height: 24px;
`;
export const ServiceCardsLabel = styled.h3`
	font-weight: 600;
	font-size: 18px;
`;
export const ServiceCardsContainer = styled.div<IconWrapperInterface>`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, ${props => props.countServices ? '1fr' : '390px'}));
	width: 100%;
	gap: 20px;
	justify-content: start;
	align-items: baseline;

	@media screen and (max-width: 750px) {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}
`;
export const ButtonWrapper= styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
	gap: 16px;
`;
