import {createAsyncThunk} from '@reduxjs/toolkit';

import {searchUsersAPI} from '../../../api/search-users-a-p-i';
import {requestCodes} from '../../../shared/constants/request-codes';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';

export const fetchGetUserRoles = createAsyncThunk(
	'userDataSlice/fetchGetUserRoles',
	async (data: string, {rejectWithValue, dispatch}) => {
		try {
			const response = await searchUsersAPI.getUserRoles(data);

			if (response.status === requestCodes.success) {
				return {status: response.status, data: response.data};
			}
		} catch (error) {
				handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
