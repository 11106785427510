import classNames from 'classnames';
import {FC} from 'react';

import st from './loader.module.css';

type Props = {
	className?: string;
};
export const Loader: FC<Props> = ({className}) => {
	return (
		<div className={classNames(st.container__loader, className)}>
			<span className={st.loader}> </span>
		</div>
	);
};
