import styled from '@emotion/styled';

interface ItemsStyleProps{
	active: boolean
}

export const GroupBtns = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
`;
export const Items = styled.div<ItemsStyleProps>`
	display: flex;
	align-items: center;
	gap: 10px;
	opacity: ${(props)=> props.active ? 0 : 1};
`;
