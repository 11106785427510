import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	strokeColor?: string;
}

const IconCallShape: FC<IconProps> = ({strokeColor, ...restProps}) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
			<path
				d="M4.76085 3.66187C4.8315 3.59123 4.86682 3.5559 4.89794 3.52731C5.6631 2.82423 6.83921 2.82423 7.60436 3.52731C7.63548 3.5559 7.67081 3.59123 7.74145 3.66187L9.19965 5.12007C10.0017 5.92212 10.2311 7.13336 9.77785 8.17314C9.32461 9.21293 9.554 10.4242 10.356 11.2262L12.7529 13.623C13.5549 14.4251 14.7661 14.6545 15.8059 14.2012C16.8457 13.748 18.057 13.9774 18.859 14.7794L20.3172 16.2376C20.3878 16.3083 20.4232 16.3436 20.4518 16.3747C21.1548 17.1399 21.1548 18.316 20.4518 19.0811C20.4232 19.1123 20.3878 19.1476 20.3172 19.2182L19.4309 20.1046C18.7091 20.8264 17.6685 21.1294 16.6721 20.9079C9.88248 19.3991 4.57995 14.0966 3.07115 7.307C2.84971 6.31054 3.15272 5.27001 3.87451 4.54821L4.76085 3.66187Z"
				stroke={strokeColor}
			/>
		</svg>
	);
};

IconCallShape.defaultProps = {
	strokeColor: '#b3b3b3',
};

export default IconCallShape;
