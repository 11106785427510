import {createAsyncThunk} from '@reduxjs/toolkit';

import {registerAPI} from '../../../api/registration-api';
import {messageErr404} from '../../../shared/constants/message-user';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setInfoOrdersUser} from '../../reducers/orders-reducer';
import {setIsShowMessage, setMessageUser, setShowLoader} from '../../reducers/user-data-reducer';

export const fetchGetOrgCreations = createAsyncThunk(
	'fieldSlice/fetchGetOrgCreations',
	async (_, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await registerAPI.getOrgCreations();
			dispatch(setShowLoader({isShowLoader: false}));

			if (response.status === 200) {
				return {status: response.status, data: response.data.records};
			}
			if (response.status === 204) {
				dispatch(setInfoOrdersUser({data: []}));
				dispatch(setShowLoader({isShowLoader: false}));
				dispatch(setMessageUser({messageUser: messageErr404}));
				dispatch(setIsShowMessage({isShowMessage: true}));
				return {status: response.status};
			}
		} catch (error) {
			handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
