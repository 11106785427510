import {FC} from 'react';

import * as SC from './button-card.style';

interface ButtonCardProps {
	title: string;
	background: string;
	buttonClick: () => void;
}

export const ButtonCard: FC<ButtonCardProps> = ({background, title, buttonClick}) => {
	return (
		<SC.Button background={background}
			onClick={buttonClick}
			type="button"
		>
			{title}
		</SC.Button>
	);
};
