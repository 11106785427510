import {createAsyncThunk} from '@reduxjs/toolkit';

import {SearchPlatformAPI} from '../../api/search-platform-api';
import {handleAxiosError} from '../../shared/utils/handle-axios-error';
import {setAllCity, setAllSportType} from '../reducers/sport-reducer';
import {setShowLoader} from '../reducers/user-data-reducer';

export const fetchGetAllCity = createAsyncThunk(
	'sportSlice/fetchGetAllCity',
	async (_, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await SearchPlatformAPI.getCities();
			dispatch(setShowLoader({isShowLoader: false}));

			if (response.status === 200) {
				dispatch(setAllCity({data: response.data.cities}));
				return {status: response.status, data: response.data.cities};
			}
			if (response.status === 204) {
				dispatch(setAllSportType({data: []}));
				return {status: response.status, data: []};
			}
		} catch (error) {
				handleAxiosError({dispatch,error,rejectWithValue});
		}
	},
);
