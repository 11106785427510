import {RefObject, useEffect, useState} from 'react';

import {useResizeWindow} from '../../../shared/hooks/use-resize-window';

export const useGalleryPadding = (ref: RefObject<HTMLDivElement>): number => {
	const [padding, setPadding] = useState(0);

	const width = useResizeWindow();

	let percent = 0.1;

	if (width >= 960) {
		percent = 0.6;
	} else if (width >= 730) {
		percent = 0.5;
	} else if (width >= 590) {
		percent = 0.4;
	} else if (width >= 430) {
		percent = 0.2;
	} else {
		percent = 0.1;
	}

	useEffect(() => {
		const handleResize = () => {
			if (ref.current) {
				setPadding(ref.current.offsetWidth * percent);
			}
		};

		window.addEventListener('resize', handleResize);

		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return padding;
};
