import {createAsyncThunk} from '@reduxjs/toolkit';

import {registerAPI} from '../../api/registration-api';
import {handleAxiosError} from '../../shared/utils/handle-axios-error';

export const fetchGetAvatars = createAsyncThunk('userDataSlice/fetchGetAvatars', async (_, {rejectWithValue, dispatch}) => {
	try {
		const response = await registerAPI.getAvatars();
		if (response.status === 200) {
			return {avatars: response.data.avatars};
		}
		if (response.status === 204) {
			return {status: response.status};
		}
	} catch (error) {
			handleAxiosError({dispatch,error,rejectWithValue});
	}
});
