import {FC, useCallback, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {ServiceListItem} from '../../../interfaces/service';
import {useAppDispatch} from '../../../redux/store';
import {fetchRecoverService} from '../../../redux/thunks/recover-service';
import {fetchGetServiceById} from '../../../redux/thunks/services/get-service-by-id';
import {fetchRemoveService} from '../../../redux/thunks/services/remove-service';
import {UPDATE_SERVICE} from '../../../shared/constants/path';
import {useModalWindow} from '../../../shared/hooks/useModalWindow';
import {ButtonGroupSettingService} from '../../buttons';
import {OtherServices} from '../card-service-new/cards/other-services/other-services';
import * as SC from './card-service.style';

interface CardServiceProps {
	service: ServiceListItem;
	onUpdate: () => void;
}

export const CardService: FC<CardServiceProps> = ({service, onUpdate}) => {
	const [isOpenDeleteWindowType, setIsOpenDeleteWindowType] = useState('');
	const navigate = useNavigate();
	const elementRef = useRef<HTMLDivElement>(null);
	useModalWindow(elementRef, () => setIsOpenDeleteWindowType(''));
	const dispatch = useAppDispatch();
	const deleteItem = useCallback(async () => {
		dispatch(fetchRemoveService({id: service.id})).then(() => onUpdate());
	}, [onUpdate, service, dispatch]);
	const recoverItem = useCallback(() => {
		dispatch(fetchRecoverService({id: service.id})).then(() => onUpdate());
	}, [onUpdate, service, dispatch]);
	const updateCard = useCallback(async () => {
		await dispatch(fetchGetServiceById({id: service.id}));
		navigate(`/admin/services/${UPDATE_SERVICE}`);
	}, [service]);

	// TODO: remove OrganizationCardModal from here
	return (
		<SC.ServiceWrapper ref={elementRef}>
			<OtherServices key={service.id} service={service} isAdminPage/>
			<ButtonGroupSettingService service={service} isOpenDeleteWindowType={isOpenDeleteWindowType}
									   deleteItem={deleteItem} recoverItem={recoverItem}
									   setIsOpenDeleteWindowType={setIsOpenDeleteWindowType} updateCard={updateCard}/>
		</SC.ServiceWrapper>
	);
};

