import {ServiceTypeKind} from '../../../interfaces/enums';
interface SERVICE_KIND_LABEL_MAP_TYPE{
	[key: string]: string
}

export const SERVICE_KIND_LABEL_MAP: SERVICE_KIND_LABEL_MAP_TYPE = {
	[ServiceTypeKind.rent]: 'Аренда зала',
	[ServiceTypeKind.inventory]: 'Аренда инвентаря',
	[ServiceTypeKind.event]: 'Мероприятие',
	[ServiceTypeKind.tournament]: 'Турнир',
	[ServiceTypeKind.workout]: 'Тренировка',
};
export const priceTitle = (eventType: string) => {
	switch (eventType) {
		case 'Workout':
			return `Стоимость
за занятие`;
		case 'Tournament':
		case 'Event':
			return `Стоимость
участия`;
		case 'Inventory':
		case 'Rent':
			return `Стоимость
аренды`;
		default:
			return `Неверный тип
мероприятия`;
	}
};
export const kindLabelView = (eventType: string) => {
	if (eventType === ServiceTypeKind.workout) {
		return 'Размер группы';
	} else if (eventType === ServiceTypeKind.tournament) {
		return 'Количество участников';
	} else if (eventType === ServiceTypeKind.event) {
		return 'Количество участников';
	} else if (eventType === ServiceTypeKind.inventory) {
		return 'Количество';
	} else if (eventType === ServiceTypeKind.rent) {
		return 'Вместительность';
	}
	return 'Неверный тип мероприятия';

};
export const kindLabelTypeView = (eventType: string) => {
	if (eventType === ServiceTypeKind.inventory) {
		return 'шт';
	}
	return 'чел';
};
