import {createSlice} from '@reduxjs/toolkit';

import {LocationInfo} from '../../interfaces/interfaces';
import {OrderPolicy} from '../../interfaces/service';
import {fetchGetServiceById} from '../thunks/services/get-service-by-id';


export interface InitialStateService{
	active: boolean;
	description: string;
	id: string;
	images: any[];
	max_participants: number;
	order_policy: OrderPolicy;
	org_info: OrgInfoState;
	platform_info: PlatformInfoState;
	schedule: any[];
	service_kind: string;
	service_name: string;
	service_type: string;
	sports: string[];
	price: number;
}

export interface OrgInfoState {
  logo?: any;
  org_name: string;
}

export interface PlatformInfoState {
  city: string;
  location_info: LocationInfo;
  platform_id: string;
}

const initialState: InitialStateService = <InitialStateService>{};

export const serviceSlice = createSlice({
	name: 'serviceSlice',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchGetServiceById.fulfilled, (_, action) => {
			return  action.payload.data;
		});
	}
});
