import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	strokeColor?: string;
}

const IconLineUp: FC<IconProps> = ({strokeColor, ...restProps}) => {
	return (
		<svg width="2" height="22" viewBox="0 0 2 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
			<line x1="1.10547" y1="2.18557e-08" x2="1.10547" y2="22" stroke={strokeColor} />
		</svg>
	);
};

IconLineUp.defaultProps = {
	strokeColor: '#b7b7b7',
};

export default IconLineUp;
