import {createSlice} from '@reduxjs/toolkit';

import {OrderType} from '../../interfaces/enums';
import {
	Notification,
	ScheduleInfo,
	ServicesData,
	WorkloadService,
	WorkloadStatusServices
} from '../../interfaces/interfaces';
import {PlatformListItem, PlatformSingle} from '../../interfaces/platform';
import {ServiceListItem} from '../../interfaces/service';
import {fetchGetTotalServicesOrigins} from '../thunks/get-total-services-origins';
import {fetchGetNotificationsPlatform} from '../thunks/platform/get-notifications-platform';
import {fetchGetServicesPlatformFilter} from '../thunks/services/get-services-platform-filter';

export interface PlayFieldState {
	idCheckField: number;
	checkedPlatform: PlatformSingle | null;
	checkedPlatformUpdate: PlatformSingle | null;
	allPlatform: PlatformListItem[];
	allServices: ServicesData[];
	servicesPlatformChecked: ServiceListItem[];
	servicesPlatform: ServiceListItem[];
	orderCheckedService: ServiceListItem | null,
	checkedServiceForUpdate: ServiceListItem | null;
	workloadStatusService: WorkloadStatusServices[];
	workloadService: WorkloadService[];
	scheduleCheckedPlatform: ScheduleInfo[];
	servicesTotal: number;
	totalServicesOrigins: number;
	totalServicesDepsAllowed: number;
	servicesTotalDeps: number
	platformsTotal: number;
	notification: Notification | null;
	recommendations: ServiceListItem[];
	isCheckServiceId: { id: string, allow: OrderType } | null
}

const initialState: PlayFieldState = {
	scheduleCheckedPlatform: [],
	checkedPlatformUpdate: null,
	idCheckField: 0,
	allPlatform: [],
	checkedPlatform: null,
	notification: null,
	allServices: [],
	servicesPlatformChecked: [],
	servicesPlatform:[],
	orderCheckedService: null,
	checkedServiceForUpdate: null,
	workloadStatusService: [],
	workloadService: [],
	servicesTotal: 0,
	totalServicesOrigins: 0,
	totalServicesDepsAllowed: 0,
	servicesTotalDeps: 0,
	platformsTotal: 0,
	recommendations: [],
	isCheckServiceId: null,
};

export const fieldSlice = createSlice({
	name: 'fieldSlice',
	initialState,
	reducers: {
		setScheduleCheckedPlatform: (state: PlayFieldState, action) => {
			state.scheduleCheckedPlatform = [...action.payload.data];
		},
		setAllServices: (state: PlayFieldState, action) => {
			state.allServices = [...action.payload.data];
		},
		setRecommendations: (state: PlayFieldState, action) => {
			state.recommendations = [...action.payload.data];
		},
		setCheckedServiceForUpdate: (state: PlayFieldState, action) => {
			state.checkedServiceForUpdate = {...action.payload.data};
		},
		setServicesPlatformChecked: (state: PlayFieldState, action) => {
			state.servicesPlatformChecked = [...action.payload.data];
		},
		resetServicesPlatformChecked: (state: PlayFieldState) => {
			state.servicesPlatformChecked = [];
		},
		setOrderCheckedService: (state: PlayFieldState, action) => {
			state.orderCheckedService = action.payload;
		},
		resetFilters: (state: PlayFieldState) => {
			state.checkedPlatform = null;
		},
		setCheckedPlatform: (state: PlayFieldState, action) => {
			state.checkedPlatform = action.payload.data;
		},

		setIdCheckField: (state: PlayFieldState, action) => {
			state.idCheckField = action.payload.id;
		},
		setAllPlatforms: (state: PlayFieldState, action) => {
			state.allPlatform = action.payload.data;
		},
		setWorkload: (state: PlayFieldState, action) => {
			state.workloadService = [...action.payload.data];
		},
		setWorkloadStatus: (state: PlayFieldState, action) => {
			state.workloadStatusService = [...action.payload.data];
		},

		setServicesTotal: (state: PlayFieldState, action) => {
			state.servicesTotal = action.payload.data;
		},
		setServicesTotalDeps: (state: PlayFieldState, action) => {
			state.servicesTotalDeps = action.payload.data;
		},
		setPlatformsTotal: (state: PlayFieldState, action) => {
			state.platformsTotal = action.payload.data;
		},
		setCheckedPlatformUpdate: (state: PlayFieldState, action) => {
			state.checkedPlatformUpdate = action.payload;
		},
		setTotalServicesDepsAllowed: (state: PlayFieldState, action) => {
			state.totalServicesDepsAllowed = action.payload;
		},
		setIsCheckServiceId: (state: PlayFieldState, action) => {
			state.isCheckServiceId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchGetTotalServicesOrigins.fulfilled, (state, action) => {
			if (action.payload?.total !== undefined) {
				state.totalServicesOrigins = action.payload.total;
			}
		});
		builder.addCase(fetchGetServicesPlatformFilter.fulfilled, (state, action) => {
			state.servicesPlatform = action.payload?.data;
		});
		builder.addCase(fetchGetNotificationsPlatform.fulfilled, (state, action) => {
			state.notification = action.payload?.data;
		});
	},
});

export const {
	setIsCheckServiceId,
	setScheduleCheckedPlatform,
	resetFilters,
	setAllServices,
	setOrderCheckedService,
	setCheckedServiceForUpdate,
	resetServicesPlatformChecked,
	setWorkload,
	setWorkloadStatus,
	setServicesPlatformChecked,
	setCheckedPlatform,
	setAllPlatforms,
	setServicesTotalDeps,
	setTotalServicesDepsAllowed,
	setIdCheckField,
	setServicesTotal,
	setPlatformsTotal,
	setRecommendations,
	setCheckedPlatformUpdate,
} = fieldSlice.actions;
