import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	strokeColor?: string;
}

const IconEmailShape: FC<IconProps> = ({strokeColor, ...restProps}) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
			<path
				d="M20.0784 6.79014C20.4686 7.66325 20.6339 8.8534 20.9181 10.8995C21.3542 14.0399 21.5723 15.6101 21.0928 16.8297C20.6718 17.9003 19.8945 18.793 18.892 19.3573C17.75 20 16.1647 20 12.9941 20H11.3699C8.19935 20 6.61405 20 5.47208 19.3573C4.46954 18.793 3.69225 17.9003 3.27127 16.8297C2.79175 15.6101 3.00983 14.0399 3.44601 10.8995C3.71208 8.98369 3.87394 7.81829 4.21376 6.96093M20.0784 6.79014C20.0022 6.61965 19.9175 6.46125 19.8216 6.31092C19.3043 5.50008 18.5642 4.85563 17.6899 4.45483C16.6977 4 15.4632 4 12.9941 4H11.3699C8.90089 4 7.66636 4 6.6742 4.45483C5.79991 4.85563 5.05976 5.50008 4.5425 6.31092C4.41533 6.51027 4.30774 6.72382 4.21376 6.96093M20.0784 6.79014L19.9899 6.87868L19.1318 7.7368C15.7984 11.0701 14.1318 12.7368 12.0607 12.7368C9.98963 12.7368 8.32297 11.0701 4.98963 7.7368L4.21376 6.96093"
				stroke={strokeColor}
			/>
		</svg>
	);
};

IconEmailShape.defaultProps = {
	strokeColor: '#b3b3b3',
};

export default IconEmailShape;
