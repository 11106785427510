import {ServiceTypeAllows} from '../../interfaces/interfaces';

export const createObjAllows = (data: ServiceTypeAllows[]) => {
	if (data.length) {
		const obj: any = {};
		data.forEach((service: ServiceTypeAllows) => {
			const dataSport = service.sport_type as string;
			const dataService = service.service_type as string;
			if (obj.hasOwnProperty(dataSport)) {
				const newData = obj[dataSport];
				newData.push(dataService);
				obj[dataSport] = newData;
			} else {
				obj[dataSport] = [dataService];
			}
		});
		return obj;
	}
	return null;
};
