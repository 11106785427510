import styled from '@emotion/styled';

export const Container = styled.section`
	display: grid;
	grid-template-columns: repeat(2, minmax(560px, 1fr));
	gap: 20px;
	width: 100%;
	@media screen and (max-width: 1200px){
		grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	}
	@media screen and (max-width: 605px){
		grid-template-columns: auto;
	}
`;
