import {MessageUser} from '../../interfaces/interfaces';

const failedLoginText = 'Вход не выполнен';
const noDataText = 'Данных нет';
export const dataSavedText = 'Данные сохранены';
const dataNotSavedText = 'Данные не сохранены';
export const invalidRequestFormat400 = 'Неверный формат запроса';
export const unAuthorized401 = 'Вы не авторизованны';
export const invalidUrlAddress404 = 'Неверный URL-адрес';
export const dataSendingError409 = 'Ошибка отправки данных';
export const errorServer500 = 'Ошибка сервера';

export const emptyMessage: MessageUser = {
	titleMessage: '',
	textMessage: '',
	action: '',
	btnTitle: '',
	isErrorMessage: false,
};

export const messageBack: MessageUser = {
	titleMessage: dataNotSavedText,
	textMessage:
		'Такой логин (телефон, пароль или e-mail) уже записан в системе. Попробуйте зарегистрироваться с другими данными',
	action: 'back',
	btnTitle: 'Назад к регистрации',
	isErrorMessage: true,
};

export const messageErrorServer: MessageUser = {
	titleMessage: failedLoginText,
	textMessage: 'Что-то пошло не так. Попробуйте ещё раз',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: true,
};
export const messageErrorUpdateUser: MessageUser = {
	titleMessage: 'Обновление не выполнено',
	textMessage:
		'Что-то пошло не так. Возможно, пользователь с такими данными уже есть. Попробуйте ввести другие данные',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: true,
};

export const messageAuthError: MessageUser = {
	titleMessage: failedLoginText,
	textMessage: 'Пользователь не найден. Попробуйте войти с другими данными',
	action: 'auth',
	btnTitle: 'Войти заново',
	isErrorMessage: true,
};

export const messageErrorNetwork: MessageUser = {
	titleMessage: 'Ошибка связи',
	textMessage:
		'Если Вы получили это сообщение, значит, у Вас отсутствует интернет-соединение. Пожалуйста, проверьте доступ к сети Интернет',
	action: 'close',
	btnTitle: 'Оk',
	isErrorMessage: true,
};

export const messageErrorData: MessageUser = {
	titleMessage: 'Данные не получены',
	textMessage: 'Что-то пошло не так. Попробуйте позже',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: true,
};

export const messageRepeat: MessageUser = {
	titleMessage: dataNotSavedText,
	textMessage: 'Что-то пошло не так, и ваша регистрация не завершилась. Попробуйте ещё раз',
	action: 'repeat',
	btnTitle: 'Повторить',
	isErrorMessage: true,
};

export const messageErrSportType: MessageUser = {
	titleMessage: noDataText,
	textMessage: 'Нет данных о видах спорта. Возможно, администратор их ещё не создал :)',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: true,
};

export const messageErrServiceType: MessageUser = {
	titleMessage: noDataText,
	textMessage: 'Нет данных об услугах. Возможно, администратор их ещё не создал :)',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: true,
};

export const messageErrDataUserByLogin: MessageUser = {
	titleMessage: noDataText,
	textMessage: 'Что-то пошло не так, данные о вашем старом email отсутствуют. Попробуйте позже',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: true,
};
export const messageSuccessRemoveImg: MessageUser = {
	titleMessage: 'Фото удалено',
	textMessage: 'Фотография успешно удалена',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: false,
};
export const messageErrDataRecover: MessageUser = {
	titleMessage: noDataText,
	textMessage: 'Что-то пошло не так. Попробуйте позже',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: true,
};
export const messageErrRecoverCode: MessageUser = {
	titleMessage: noDataText,
	textMessage: 'Код не верный или истек срок его использования. Пожалуйста, проверьте Ваши данные',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: true,
};
export const messageErrNotDataRecover: MessageUser = {
	titleMessage: noDataText,
	textMessage: 'Пользователь не найден. Попробуйте ввести другие данные',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: true,
};

export const messageFinishToken: MessageUser = {
	titleMessage: 'Время сессии истекло',
	textMessage: 'Время сессии истекло. Пожалуйста, зайдите на сайт, используя свои логин и пароль',
	action: 'navigateAuth',
	btnTitle: 'Вход',
	isErrorMessage: true,
};

export const messageSuccessStep1: MessageUser = {
	titleMessage: 'Проверьте почту',
	textMessage: 'На Ваш email выслан код для регистрации. Скопируйте его и вставьте в форму',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: false,
};
export const messageErrStep1: MessageUser = {
	titleMessage: 'Регистрация не выполнена',
	textMessage: 'Код не верный или истёк срок его действия',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: true,
};
export const messageSuccessAddPlatform: MessageUser = {
	titleMessage: dataSavedText,
	textMessage: 'Новая площадка успешно сохранена',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: false,
};
export const message204AddPlatform: MessageUser = {
	titleMessage: dataNotSavedText,
	textMessage:
		'Новая площадка не добавлена. Возможно, такой вид спорта не зарегистрирован на платформе. Пожалуйста, обратитесь к администратору',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: true,
};
export const messageSuccessUpdatePlatform: MessageUser = {
	titleMessage: dataSavedText,
	textMessage: 'Новые данные успешно сохранены',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: false,
};
export const messageSuccessRemoveTypeServ: MessageUser = {
	titleMessage: dataSavedText,
	textMessage: 'Выбранная Вами услуга успешно удалён',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: false,
};
export const messageSuccessRemoveCity: MessageUser = {
	titleMessage: dataSavedText,
	textMessage: 'Выбранный Вами город успешно удалён',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: false,
};
export const messageSuccessAddBlackList: MessageUser = {
	titleMessage: dataSavedText,
	textMessage: 'Пользователь успешно добавлен в нежелательный список',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: false,
};
export const successRemoveUserBlackList: MessageUser = {
	titleMessage: dataSavedText,
	textMessage: 'Пользователь успешно удалён из нежелательного списка',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: false,
};
export const messageSuccessRemove: MessageUser = {
	titleMessage: 'Данные удалены',
	textMessage: 'Выбраный Вами вид спорта успешно удалён',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: false,
};
export const messageSuccessUpdate: MessageUser = {
	titleMessage: 'Данные обновлены',
	textMessage: 'Выбраный Вами вид спорта успешно обновлён',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: false,
};
export const messageErrRemoveSport: MessageUser = {
	titleMessage: 'Данные не удалены',
	textMessage: 'В удалении отказано. Возможно, Вы неверно ввели название вида спорта',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: true,
};
export const messageErrUpdateSport: MessageUser = {
	titleMessage: 'Данные не обновлены',
	textMessage: 'В обновлении отказано. Повторите попытку позже',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: true,
};

export const messageErrAddBlackList: MessageUser = {
	titleMessage: dataNotSavedText,
	textMessage: 'Новые данные не сохранены. Может, неверно введены данные?',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: true,
};

export const messageErr404: MessageUser = {
	titleMessage: noDataText,
	textMessage: 'Извините, по Вашему запросу данных нет',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: true,
};

export const messageErrServer: MessageUser = {
	titleMessage: dataNotSavedText,
	textMessage: 'Что-то пошло не так и ваши данные не сохранены. Попробуйте ещё раз',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: true,
};

export const messageErrCloseOrder: MessageUser = {
	titleMessage: dataNotSavedText,
	textMessage: 'Извините, но нельзя повторно отменить заказ, так как бронь уже снята до этого',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: true,
};

export const messageErrConfirmOrder: MessageUser = {
	titleMessage: dataNotSavedText,
	textMessage: 'Извините, но нельзя подтвердить эту бронь. Может, заказ сделан на время, которое ещё не наступило?',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: true,
};

export const messageIsNotAuth: MessageUser = {
	titleMessage: 'Оформить заказ могут только зарегистрированные пользователи',
	textMessage: 'Пожалуйста, войдите на сайт, используя свой логин и пароль или зарегистрируйтесь на сайте',
	action: 'auth',
	btnTitle: 'Вход / Регистрация',
	isErrorMessage: true,
};

export const messageErrAddRole: MessageUser = {
	titleMessage: dataNotSavedText,
	textMessage: 'Новая роль не добавлена, вероятно, Вы неверно ввели данные пользователя',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: true,
};

export const messageSucсessUpdate: MessageUser = {
	titleMessage: 'Данные успешно обновлены',
	textMessage: 'Изменения в расписании зафиксированы',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: false,
};

export const messageErrorRemoveComment: MessageUser = {
	titleMessage: 'Данные не удалены',
	textMessage: 'Комментарий не удален повторите попытку',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: true,
};

export const messageSuccessUpdateImage: MessageUser = {
	titleMessage: dataSavedText,
	textMessage: 'Выбранное Вами изображение успешно загружено',
	action: 'close',
	btnTitle: 'Ок',
	isErrorMessage: false,
};
