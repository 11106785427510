import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

import {IPlacemark} from '../../interfaces/interfaces';
export interface IMapState {
	isOpen: boolean;
	isOpenLeftSideBar: boolean;
	allPlacemarks: IPlacemark[] | null;
	filteredPlacemarks: IPlacemark[];
	prioritizedPlacemarks: IPlacemark[];
	isClearForm: boolean
}

const initialState: IMapState = {
	isOpen: false,
	isOpenLeftSideBar: false,
	allPlacemarks: null,
	filteredPlacemarks: [],
	prioritizedPlacemarks: [],
	isClearForm: false,
};

export const mapSlice = createSlice({
	name: 'mapSlice',
	initialState,
	reducers: {
		toggleMap: (state, {payload}: PayloadAction<boolean>) => {
			state.isOpen = payload;
		},
		toggleLeftSideBar: (state, {payload}: PayloadAction<boolean>) => {
			state.isOpenLeftSideBar = payload;
		},
		addAllPlacemarks: (state, {payload}: PayloadAction<IPlacemark[]>) => {
			state.allPlacemarks = payload;
			state.filteredPlacemarks = [];
			state.prioritizedPlacemarks = [];
		},
		addPrioritizePlacemarks: (state, {payload}: PayloadAction<string | string[]>) => {
			if (state.allPlacemarks) {
				let prioritizedPlacemarks: IPlacemark[] = [];

				if (typeof payload === 'object') {
					prioritizedPlacemarks = cloneDeep(state.allPlacemarks).filter((el) => {
						return payload.includes(el.id);
					});
				} else {
					const placemark = state.allPlacemarks.find((el) => el.id === payload);

					if (placemark) {
						prioritizedPlacemarks = [cloneDeep(placemark)];
					}
				}

				if (prioritizedPlacemarks.length) {
					state.prioritizedPlacemarks = prioritizedPlacemarks;
					state.isOpenLeftSideBar = true;
				}
			}
		},
		addFilteredPlacemarks: (state, {payload}: PayloadAction<string[]>) => {
			if (state.allPlacemarks) {
				const filteredPlacemarks = cloneDeep(state.allPlacemarks).filter((el) => {
					return payload.includes(el.id);
				});

				state.filteredPlacemarks = filteredPlacemarks.length ? filteredPlacemarks : [];
				state.prioritizedPlacemarks = [];
			}
		},
		clearPrioritizePlacemarks: (state) => {
			state.prioritizedPlacemarks = [];
		},
		clearMapSlice: () => initialState,
		setIsClearModal: (state, action:PayloadAction<boolean>) => {
			state.isClearForm = action.payload;
		},
	},
});

export const {
	toggleMap,
	toggleLeftSideBar,
	clearMapSlice,
	addAllPlacemarks,
	addPrioritizePlacemarks,
	addFilteredPlacemarks,
	clearPrioritizePlacemarks,
	setIsClearModal,
} = mapSlice.actions;
