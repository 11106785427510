import {createAsyncThunk} from '@reduxjs/toolkit';

import {registerAPI} from '../../api/registration-api';
import {RecoverPasswordCompleteData} from '../../interfaces/interfaces';
import {handleAxiosError} from '../../shared/utils/handle-axios-error';

export const fetchRecoverPasswordComplete = createAsyncThunk(
	'userDataSlice/fetchRecoverPasswordComplete',
	async (data: RecoverPasswordCompleteData, {rejectWithValue, dispatch}) => {
		try {
			const response = await registerAPI.recoverPasswordComplete(data);

			if (response.status === 200) {
				return {status: response.status, data: response.data.token};
			}
			if (response.status === 204) {
				return {status: response.status};
			}
		} catch (error) {
				handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
