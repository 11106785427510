import {CSSProperties, useEffect, useRef} from 'react';

import {UserRegistered} from '../../interfaces/interfaces';
import {OrganizationListItem} from '../../interfaces/organization';
import {PlatformListItem} from '../../interfaces/platform';
import {
	OrganizationCardModal,
} from '../../pages/organization-list-page/components/organization-list/components/organization-card/components/organization-card-modal/organization-card-modal';
import {useModalWindow} from '../../shared/hooks/useModalWindow';
import {ButtonCard} from '../buttons';
import * as SC from './GroupButtonsCard.style';

interface IGroupButtonsCardType {
	handleActions: (val: string, type: string) => void;
	data: any;
	allOwners: UserRegistered[];
	type: string;
	handleIsType: (val: string) => void;
	formType: boolean;
	platform?: OrganizationListItem | PlatformListItem;
	update: (value: unknown) => void;
	styleItems?: CSSProperties;
}

export const GroupButtonsCard = ({
									 handleActions,
									 data,
									 type,
									 allOwners,
									 update,
									 platform,
									 handleIsType,
									 formType,
									 styleItems,
								 }: IGroupButtonsCardType) => {

	const elementRef = useRef<HTMLDivElement>(null);
	useModalWindow(elementRef, () => handleIsType(''));

	useEffect(() => {
		if (type === 'owners') {
			handleActions(formType ? data.org_name : data.id, 'owners');
		}
	}, [type, data.org_name, data.id]);

	return (
		<SC.GroupBtns ref={elementRef}>
			<SC.Items active={!data.active} style={styleItems}>
				<ButtonCard title={formType ? 'Все владельцы' : 'Все модераторы'} background={'#CB8B2A;'}
							buttonClick={() => handleIsType(type === '' ? 'owners' : '')} />
				<ButtonCard title={'Изменить'} background={'#1C8666'} buttonClick={() => update(platform)} />
				<ButtonCard buttonClick={() => handleIsType('remove')} title={'Деактивировать'}
							background={'#352C8D'} />
			</SC.Items>
			<OrganizationCardModal
				orgName={formType ? data.org_name : data.id}
				handleActions={handleActions}
				type={type}
				allOwners={allOwners}
				handleIsType={handleIsType}
				formType={formType}
			/>
		</SC.GroupBtns>
	);
};
