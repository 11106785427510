import {createAsyncThunk} from '@reduxjs/toolkit';

import {crmPlatformAPI} from '../../api/crm-platform';
import {handleAxiosError} from '../../shared/utils/handle-axios-error';

export const fetchGetOwnersOrg = createAsyncThunk(
    'organizationSlice/fetchGetOwnersOrg',
    async (orgName: string, {rejectWithValue,dispatch}) => {
        try {
            const response = await crmPlatformAPI.getOwnersOrg(orgName);
            return response.data.users;
        } catch (error) {
				handleAxiosError({dispatch,rejectWithValue,error});
        }
    });
