import {FC} from 'react';

import {ConfirmDeletion} from '../../../../../../../../components/confirm-deletion/confirm-deletion';
import * as SC from './organization-card-modal.style';
import {OrganizationCardModalType} from './organization-card-modal.type';


export const OrganizationCardModal:FC<OrganizationCardModalType> = (
    {
        allOwners,
        type,
        handleActions,
        orgName,
        handleIsType,
        formType
    }) => {

    const handleActionOrg = () => {
        handleActions(orgName, type);
        handleIsType('');
    };

    if (!type) {
        return null;
    }

    return (
        <SC.Modal type={type === 'owners'}>
            <SC.Container type={type === 'owners'} owners={true}>
                <SC.OwnerTitle>{formType ? 'Все владельцы оргинизации' : 'Все модераторы площадки'}</SC.OwnerTitle>
                {allOwners.map((elem, i) =>
                    <SC.OwnerItem key={i}>
						<span>{elem.Registered.email}</span>
                    </SC.OwnerItem>)}
            </SC.Container>
			<ConfirmDeletion type={type} remove={true} deleteFalse={()=>handleIsType('')} deleteTrue={handleActionOrg}/>
        </SC.Modal>
    );
};
