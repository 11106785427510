import {UserRole} from '../../interfaces/enums';

export const OwnerRoleMetaField = 'org_name';

export const ModeratorRoleMetaField = 'platform_id';
export const ModeratorRoleFullMetaField = 'info';

export const humanReadableRoleMap = {
	[UserRole.viewer]: 'Пользователь',
	[UserRole.coach]: 'Тренер',
	[UserRole.moderator]: 'Модератор',
	[UserRole.adminSite]: 'Владелец',
	[UserRole.admin]: 'Администратор',
	[UserRole.unknown]: 'Не определена роль'
};
