import {css} from '@emotion/css';
import styled from '@emotion/styled';

export const Images = styled.img`
	border: 1px solid var(--gray-middle);
	width: 200px;
	height: 150px;
	object-fit: cover;
	user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	cursor: grab;
`;
export const Slider =css`
	height: 150px;

	.flickity-enabled {
		position: relative;
	}

	.flickity-enabled:focus {
		outline: none;
	}

	.flickity-viewport {
		overflow: hidden;
		position: relative;
		height: 100%;
		outline: none;
	}
	.flickity-viewport:focus-visible{
		outline: none;
	}

	.flickity-slider {
		position: absolute;
		width: 100%;
		height: 100%;
		border: none;
		div {
			height: 100%;
		}
	}

	/* draggable */

	.flickity-enabled.is-draggable {
		-webkit-tap-highlight-color: transparent;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	/* ---- flickity-button ---- */

	.flickity-button {
		position: absolute;
		background: hsla(0, 0%, 100%, 0.75);
		border: none;
		color: #333;
	}

	.flickity-button:hover {
		background: white;
		cursor: pointer;
	}

	.flickity-button:focus {
		outline: none;
		box-shadow: 0 0 0 5px #19F;
	}

	.flickity-button:active {
		opacity: 0.6;
	}

	.flickity-slider > * {
		margin-right: 20px;
	}

	.flickity-button:disabled {
		opacity: 0.3;
		cursor: auto;
		/* prevent disabled button from capturing pointer up event. #716 */
		pointer-events: none;
	}

	.flickity-button-icon {
		fill: currentColor;
	}

	/* ---- previous/next buttons ---- */

	.flickity-prev-next-button {
		top: 84%;
		width: 44px;
		height: 44px;
		border-radius: 50%;
		/* vertically center */
		transform: translateY(-50%);
	}

	.flickity-prev-next-button.previous {
		left: 10px;
	}

	.flickity-prev-next-button.next {
		right: 10px;
	}

	/* right to left */

	.flickity-rtl .flickity-prev-next-button.previous {
		left: auto;
		right: 10px;
	}

	.flickity-rtl .flickity-prev-next-button.next {
		right: auto;
		left: 10px;
	}

	.flickity-prev-next-button .flickity-button-icon {
		position: absolute;
		left: 20%;
		top: 20%;
		width: 60%;
		height: 60%;
	}
`;
