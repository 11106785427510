import React from 'react';
import {Link} from 'react-router-dom';

import desktopLogo from '../../../../assets/img/sporder_dark.png';
import {MAIN} from '../../../../shared/constants/path';
import styles from './logo.module.css';

export const Logo = () => {
	return (
		<Link to={MAIN} id={'logo-header'} className={styles.logo}>
			<img className={styles.logo_image} src={desktopLogo} alt="Логотип Sporder" title="Логотип Sporder" />
		</Link>
	);
};
