import classNames from 'classnames';
import {FC, PropsWithChildren} from 'react';

import {ListWithIconsItem} from './components/list-with-icons-item/list-with-icons-item';
import styles from './list-with-icons.module.css';

interface ListWithIconsProps extends PropsWithChildren {
	className?: string;
}

interface ListWithIconsComponents {
	Item: typeof ListWithIconsItem;
}

export const ListWithIcons: FC<ListWithIconsProps> & ListWithIconsComponents = ({children, className}) => {
	return <div className={classNames(styles.list_with_icons, className)}>{children}</div>;
};

ListWithIcons.Item = ListWithIconsItem;
