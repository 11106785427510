import {keyframes} from '@emotion/react';
import styled from '@emotion/styled';

import {OrganizationCardModalStyle} from './organization-card-modal.type';



export const Modal = styled.div<OrganizationCardModalStyle>`
	position: absolute;
	width: 100%;
	display: flex;
	align-items: flex-end;
	z-index: 2;
	justify-content: ${(props) => props.type ? 'flex-start' : 'flex-end'};
	@media (max-width: 1200px) {
		height: 42%;
	}
`;
const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const Container = styled.div<OrganizationCardModalStyle>`
	position: absolute;
	flex-direction: column;
	background: #FFFFFF;
	border-radius: 8px;
	padding: 13px 14px;
	opacity: 0;
	animation: ${fadeInAnimation} 1s forwards;
	font-family: var(--font-family), serif;
	font-size: 14px;
	width: 250px;
	box-shadow: 0 0 5px 0 #c9c4c4;
	display: none;
	${props => props.owners && `
		left: -25%;
	    height: 20rem;
	`};
	${(props) => props.remove && `
		right: -20%;
	height: 8rem;
	width: 250px;
	align-items: center;
	justify-content: center;
	gap: 6px;
	`};
	${(props) => props.type && `
		display: flex;
	    transition: 1s;
	`};
	@media (max-width: 1200px) {
		top: inherit;
		left: inherit;
	}
`;
export const OwnerTitle = styled.span`
	margin-bottom: 16px;
	font-weight: 600;
	display: block;
`;
export const OwnerItem = styled.div`
	display: flex;
	flex-direction: column;
`;
