import {OrderType} from '../../../interfaces/enums';

export interface OrderTypeLabelMap {
	[key: string]: string;
}
export const ORDER_TYPE_LABEL_MAP: OrderTypeLabelMap = {
	[OrderType.book]: 'Мгновенная бронь',
	[OrderType.request]: 'Через запись',
	[OrderType.view]: 'Не для заказа',
};
export const Hours = Array.from({length: 24}, (_, i) => String(i).padStart(2));
export const Minutes = ['00', '15', '30', '45'];

export const SERVICE_STEP_MIN_ORDER_DURATION = 'service_step <= min_order_duration';
export const SERVICE_STEP_ORDER_STEP= 'service_step <= order_step';
export const ORDER_STEP_MIN_ORDER_DURATION= 'order_step <= min_order_duration';
export const ORDER_STEP_MULTIPLE_SERVICE_STEP = 'order_step multiple service_step';
export const MIN_ORDER_DURATION_MULTIPLE_ORDER_STEP = 'min_order_duration multiple order_step';
export const MIN_ORDER_DURATION_MULTIPLE_SERVICE_STEP = 'min_order_duration multiple service_step';

export const Errors: OrderTypeLabelMap = {
	[SERVICE_STEP_MIN_ORDER_DURATION]: 'Минимальный промежуток бронирования <= Минимальная длительность заказа',
	[SERVICE_STEP_ORDER_STEP]: 'Минимальный промежуток бронирования  <= Промежуток продления',
	[ORDER_STEP_MIN_ORDER_DURATION]: 'Промежуток продления  <= Минимальная длительность заказа',
	[ORDER_STEP_MULTIPLE_SERVICE_STEP]: ' Промежуток продления должен быть кратен минимальному промежутку бронирования',
	[MIN_ORDER_DURATION_MULTIPLE_ORDER_STEP]: 'Минимальная длительность должна быть кратна промежутку продления',
	[MIN_ORDER_DURATION_MULTIPLE_SERVICE_STEP]: 'Минимальная длительность должна быть кратна минимальному промежутку бронирования'
};
