import {FC, memo} from 'react';

import {AbonementType} from '../../interfaces/abonement';
import {Abonement} from './abonement/abonement';
import * as SC from './abonements.style';

interface AbonementsInterface {
	abonements: AbonementType[]
}

export const Abonements:FC<AbonementsInterface> = memo(({abonements}) => {

	return (
		<SC.Container>
			{abonements.map(i => {
				if (i.info.limit !== null) {
					return <Abonement key={i.id} abonement={i} />;
				}
				return <Abonement abonement={i} unLimited bgColor={'rgba(28, 134, 102, 0.1)'}
								  borderBgPriceColor={'var(--green)'} />;
			})}
		</SC.Container>
	);
});
