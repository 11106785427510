import {FC} from 'react';

import {ServiceListItem} from '../../../interfaces/service';
import {
	OrganizationCardModal,
} from '../../../pages/organization-list-page/components/organization-list/components/organization-card/components/organization-card-modal/organization-card-modal';
import {ButtonCard} from '../button-card/button-card';
import * as SC from './button-group-setting-service.style';

interface ButtonGroupSettingServiceProps {
	deleteItem: () => void;
	isOpenDeleteWindowType: string;
	setIsOpenDeleteWindowType: (val: string) => void;
	updateCard: () => void;
	service: ServiceListItem;
	recoverItem: () => void;
}

export const ButtonGroupSettingService: FC<ButtonGroupSettingServiceProps> = ({
																	deleteItem,
																	isOpenDeleteWindowType,
																	recoverItem,
																	service,
																	setIsOpenDeleteWindowType,
																	updateCard,
																}) => {
	return (
		<SC.ButtonGroup>
			<OrganizationCardModal
				orgName={''}
				handleActions={deleteItem}
				type={isOpenDeleteWindowType}
				allOwners={[]}
				handleIsType={setIsOpenDeleteWindowType}
				formType={false}
			/>
			<ButtonCard title={'Изменить'} background={'#1C8666'} buttonClick={updateCard} />
			{service.active &&
				<ButtonCard buttonClick={() => setIsOpenDeleteWindowType('remove')} title={'Деактивировать'}
							background={'#352C8D'} />}
			{!service.active &&
				<ButtonCard buttonClick={recoverItem} title={'Активировать'}
							background={'rgb(28, 134, 102)'} />}
		</SC.ButtonGroup>
	);
};
