import {FC, memo} from 'react';

import IconOkShape from '../../../../components/svg-icon-components/icon-ok-shape';
import {Counter} from '../counter/counter';
import * as SC from './counter-block.style';

interface CounterBlockProps {
	count: number;
	maxCount?: number;
	className?: string;
	onCountChange?: (count: number) => void;
}

export const CounterBlock: FC<CounterBlockProps> = memo(({count, maxCount = 1, className, onCountChange = ()=>{}}) => {
	return (
		<SC.CheckedBlock className={className}>
			<SC.CheckedBlockContent >
				<IconOkShape strokeColor="#1c8666" />
				<Counter count={count} maxCount={maxCount} iconColor="#1c8666" onCountChange={onCountChange} />
			</SC.CheckedBlockContent>
		</SC.CheckedBlock>
	);
});
