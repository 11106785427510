import {css} from '@emotion/css';
import styled from '@emotion/styled';

interface OrderInfoStyle{
	bgColor: string
}

export const OrderInfo = styled.div<OrderInfoStyle>`
	border-radius: 6px;
	background: #1C8666;
	${props => props.bgColor === 'ordered' && `
	background-color: var(--green);
	`}
	${props => props.bgColor === 'busy' && `
	background-color: var(--yellow);
	`}
	${props => props.bgColor === 'errored' && `
	background-color: var(--red);
	`}
	display: inline-flex;
	padding: 20px;
	border: 1px solid var(--black-color);
	flex-direction: column;
	gap: 20px;
	z-index: 1;
	min-width: 300px;
`;
export const ListWithIcons = css`
	gap: 8px;
`;
export const ListItemContent = css`
	justify-content: space-between;
`;
export const Title = styled.h3`
	color: #FFF;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 130%;
`;
export const Label = styled.span`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;
export const Icon = css`
	width: 16px;
	margin: 0 3px 0 3px;
`;
export const Order = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 20px;
	color: var(--white-color);
	gap: 2px;
`;
