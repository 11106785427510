import {createSlice} from '@reduxjs/toolkit';

import {AbonementType} from '../../interfaces/abonement';
import {ServiceListItem} from '../../interfaces/service';
import {fetchGetListAbonements} from '../thunks/abonements/get-list-abonements';
import {fetchGetListServicesForAbonement} from '../thunks/abonements/get-list-services';
import {fetchGetTotalListAbonements} from '../thunks/abonements/get-total-list-abonements';

interface InitialStateAbonement {
	abonements: AbonementType[]
	checkedAbonementUpdate: AbonementType
	listServices: ServiceListItem[]
	total: number
}

const initialState: InitialStateAbonement = {
	abonements: [],
	checkedAbonementUpdate: {} as AbonementType,
	listServices: [],
	total: 0
};

export const abonementSlice = createSlice({
	name: 'abonement',
	initialState,
	reducers: {
		setCheckedAbonementUpdate: (state: InitialStateAbonement, action) => {
			state.checkedAbonementUpdate = action.payload;
		}
	},
	extraReducers: builder => {
		builder.addCase(fetchGetListAbonements.fulfilled, (state, action) => {
			state.abonements = action.payload?.data;
		});
		builder.addCase(fetchGetTotalListAbonements.fulfilled, (state, action) => {
			state.total = action.payload?.data;
		});
		builder.addCase(fetchGetListServicesForAbonement.fulfilled, (state, action) => {
			state.listServices = action.payload?.data;
		});
	},
});

export const abonement = abonementSlice.reducer;
export const {setCheckedAbonementUpdate} = abonementSlice.actions;
