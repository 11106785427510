import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	strokeColor?: string;
}

const IconTennisShape: FC<IconProps> = ({strokeColor, ...restProps}) => {
	return (
		<svg width="20" height="24" viewBox="0 0 74 89" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
			<g clipPath="url(#clip0_30_324)">
				<path
					d="M36.6778 28.4322C41.2627 28.4322 44.9759 24.766 44.9759 20.2393C44.9759 15.7126 41.2627 12.0837 36.6778 12.0837C32.0931 12.0837 28.4178 15.7126 28.4178 20.2393C28.4178 24.766 32.0931 28.4322 36.6778 28.4322ZM70.1728 89.0001C72.2947 89.0001 74 87.2793 74 85.2217V66.2544C74 64.9078 73.2799 63.8976 72.2947 63.0744L57.4038 50.9535L54.4484 35.1662C54.183 33.5575 54.3728 31.9862 55.1686 30.415L67.7102 5.68653C68.6573 3.81597 67.8615 1.53392 65.9673 0.598649C64.0727 -0.336619 61.7614 0.411596 60.8139 2.24472L48.4997 26.6365C47.6282 28.3574 46.3777 29.4797 44.5211 30.2279L22.81 39.1315L14.0573 32.2107C13.4132 31.7244 12.6554 31.5373 12.0112 31.5373V21.2494H7.80542V35.0165C7.80542 36.2885 8.18434 37.3734 9.16946 38.159L19.8546 46.5389C21.1807 47.5491 22.6206 47.4744 24.2877 46.8009L36.1474 41.9001C37.8146 41.1894 38.6482 42.1246 38.8377 43.247L44.7105 75.2331L28.4178 81.6678C26.4854 82.416 25.5002 84.6229 26.258 86.6058C27.1674 88.8131 29.365 89.4115 31.2595 88.6634L50.4699 81.1066C52.1371 80.4331 53.0086 78.8246 52.8949 77.1037L51.7961 62.3638C51.6824 60.7176 53.5013 59.7825 54.7895 60.6429L66.346 68.2748V85.2217C66.346 87.2793 68.0132 89.0001 70.1728 89.0001ZM9.92726 1.34686C4.3574 1.34686 0 5.68652 0 11.2233C0 16.2363 4.3195 21.7731 9.92726 23.6437C15.5351 21.7731 19.8166 16.2363 19.8166 11.2233C19.8166 5.68652 15.4593 1.34686 9.92726 1.34686ZM9.92726 5.49946C13.1101 5.49946 15.6108 8.04338 15.6108 11.3355C15.6108 14.5529 13.2237 18.032 9.92726 19.1544C6.6308 18.032 4.20581 14.5529 4.20581 11.3355C4.20581 8.04338 6.70661 5.49946 9.92726 5.49946Z"
					fill={strokeColor}
				/>
			</g>
			<defs>
				<clipPath id="clip0_30_324">
					<rect width="74" height="89" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

IconTennisShape.defaultProps = {
	strokeColor: '#b3b3b3',
};

export default IconTennisShape;
