import classNames from 'classnames';
import {FC, useCallback} from 'react';

import IconArrowDownShape from '../../../../components/svg-icon-components/icon-arrow-down-shape';
import styles from './counter.module.css';

interface CounterProps {
	count: number;
	maxCount?: number;
	className?: string;
	iconColor?: string;
	onCountChange: (amount: number) => void;
	counterTitle?: string;
}

export const Counter: FC<CounterProps> = ({count, maxCount = 1, className, iconColor, onCountChange, counterTitle}) => {
	const onDecrease = useCallback(() => {
		if (count > 1) {
			onCountChange(count - 1);
		}
	}, [count, onCountChange]);

	const onIncrease = useCallback(() => {
		if (maxCount > count) {
			onCountChange(count + 1);
		}
	}, [maxCount, count, onCountChange]);

	return (
		<div className={classNames(styles.counter, className)}>
			{counterTitle && (
				<span>{counterTitle}</span>
			)}
			{count > 1 && <IconArrowDownShape
				className={classNames(styles.counter_control, {
					[styles.counter_control_active]: count > 1,
				})}
				onClick={onDecrease}
				strokeColor={iconColor}
			/>}
			<span className={styles.counter_count}>{count}</span>
			<IconArrowDownShape
				className={classNames(styles.counter_control, styles.counter_control_increase, {
					[styles.counter_control_active]: maxCount > count,
				})}
				strokeColor={iconColor}
				onClick={onIncrease}
			/>
		</div>
	);
};
