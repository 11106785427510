import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import {some} from 'lodash';
import {FC, useCallback} from 'react';

import {Contacts} from '../../../interfaces/interfaces';
import {ListWithIcons} from '../../list-with-icons/list-with-icons';
import IconCall from '../../svg-icon-components/icon-call';
import IconInstagram from '../../svg-icon-components/icon-instagram';
import IconTelegram from '../../svg-icon-components/icon-telegram';
import IconWebSite from '../../svg-icon-components/icon-web-site';
import {Tooltip} from '../../tooltip/tooltip';
import styles from './contacts-list.module.css';

interface OrganizationCardContactsListProps {
	contacts?: Contacts;
}

export const ContactsList: FC<OrganizationCardContactsListProps> = ({contacts}) => {
	const hasContacts = some(contacts, (value) => value !== null);

	const onCopyPhone = useCallback(() => {
		if (!contacts?.viber) {
			return;
		}

		copy(contacts.viber);
	}, [contacts?.viber]);

	if (!contacts || !hasContacts) {
		return null;
	}

	return (
		<ListWithIcons className={styles.contacts_list}>
			{contacts.viber ? (
				<ListWithIcons.Item
					icon={<IconCall />}
					contentClassName={styles.contacts_list_item_content}
					onClick={onCopyPhone}
				>
					<p data-tooltip-id="phone-tooltip" className={styles.contacts_list_item_label}>
						{contacts.viber}
					</p>
					<Tooltip
						id="phone-tooltip"
						content="Скопировано"
						openOnClick={true}
						delayHide={800}
						openEvents={{click: true}}
						closeEvents={{click: true, mouseleave: true}}
					/>
				</ListWithIcons.Item>
			) : null}
			{contacts.instagram ? (
				<ListWithIcons.Item icon={<IconInstagram />} contentClassName={styles.contacts_list_item_content}>
					<a
						href={contacts.instagram}
						className={classNames(styles.contacts_list_item_label, styles.link)}
						target="_blank"
						rel="noopener noreferrer"
					>
						@{contacts.instagram.split('/').pop()}
					</a>
				</ListWithIcons.Item>
			) : null}
			{contacts.telegram ? (
				<ListWithIcons.Item icon={<IconTelegram />} contentClassName={styles.contacts_list_item_content}>
					<a
						href={contacts.telegram}
						className={classNames(styles.contacts_list_item_label, styles.link)}
						target="_blank"
						rel="noopener noreferrer"
					>
						@{contacts.telegram.split('/').pop()}
					</a>
				</ListWithIcons.Item>
			) : null}
			{contacts.web_site ? (
				<ListWithIcons.Item icon={<IconWebSite />} contentClassName={styles.contacts_list_item_content}>
					<a
						href={contacts.web_site}
						className={classNames(styles.contacts_list_item_label, styles.link)}
						target="_blank"
						rel="noopener noreferrer"
					>
						{contacts.web_site.replace('https://','')}
					</a>
				</ListWithIcons.Item>
			) : null}
		</ListWithIcons>
	);
};
