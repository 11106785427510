import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ServiceListItem} from '../../interfaces/service';

export interface InitialStateService {
	service: ServiceListItem;
}

const initialState: InitialStateService = {
	service: {} as ServiceListItem,
};

const servicePreviewSlice = createSlice({
	name: 'service-preview',
	initialState,
	reducers: {
		setCheckedServices: (state: InitialStateService, action: PayloadAction<ServiceListItem>) => {
			state.service = action.payload;
		},
	},
});
export const servicePreview = servicePreviewSlice.reducer;
export const {setCheckedServices} = servicePreviewSlice.actions;
