import styled from '@emotion/styled';

export const CardRemove = styled.div`
	position: absolute;
	padding: 18px 14px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	align-self: flex-end;
	width: 100%;
	background: rgba(255, 255, 255, 0.49);
	margin: 0;
	height: 100%;
	gap: 10px;

	button {
		border: none;
		color: #FFFFFF;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		border-radius: 5px;
		padding: 5px 9px;
		cursor: pointer;
		margin: 0;
	}
`;
