import styled from '@emotion/styled';

export const CheckedBlock = styled.div`
	height: 74px;
	margin: -20px 6px;
	padding: 3px;
	background-color: var(--green);
	z-index: 1;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
`;

export const CheckedBlockContent = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	padding: 10px;
	background-color: var(--green);
	border-radius: 0;
	gap: 43px;
`;
