export const LOCAL_STORAGE_KEY_MAP = {
	city: 'city',
	dataUser: 'dataUser',
	userNick: 'userNick',
	email: 'email',
	auth: 'auth',
	jwt: 'jwt',
	checkedFieldId: 'idCheckField',
	userPassword: 'passUser',
	roles: 'roles',
	hasAdminRole: 'hasAdminRole',
	selectedFullRole: 'selectedFullRole',
	serviceOrder: 'serviceOrder',
};
