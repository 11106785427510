import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import {ActivateOrderParams, AllOrdersParams, CloseOrderParams, ConfirmOrderParams} from '../api/query-params';
import {IOrder} from '../interfaces/order';
import {messageErrCloseOrder, messageErrConfirmOrder, messageSuccessUpdatePlatform} from '../shared/constants/message-user';
import {HOST_CRM, urlGetOrders, urlGetTotalOrders, urlPutOrderActivate, urlPutOrderClose, urlPutOrderConfirm} from '../shared/constants/url';
import {jwtUser} from '../shared/utils/save-local-storage';
import {onGetQueryStarted, onPutQueryStarted} from './utils';

export const crmApi = createApi({
	reducerPath: 'crmApi',
	baseQuery: fetchBaseQuery({
		baseUrl: HOST_CRM,
		prepareHeaders: (headers) => {
			headers.set('Authorization', `Bearer ${jwtUser()}`);
			return headers;
		}
	}),
	tagTypes: ['Order'],
	endpoints: (build) => ({
		getAllOrders: build.query<IOrder[], AllOrdersParams>({
      query: (params) => {
				return {url: urlGetOrders, params};
			},
			transformResponse: (response: {orders: IOrder[]}) => {
				return response.orders;
			},
			onQueryStarted: onGetQueryStarted(),
			providesTags: (result) => {
				return result
					? [
							...result.map(({id}) => ({type: 'Order' as const, id})),
							{type: 'Order', id: 'LIST'},
						]
					: [{type: 'Order', id: 'LIST'}];
			}
		}),
		getAllOrdersTotal: build.query<number, AllOrdersParams>({
      query: (params) => {
				return {url: urlGetTotalOrders, params};
			},
			transformResponse: (response: {total:number}) => {
				return response.total;
			},
			onQueryStarted: onGetQueryStarted()
    }),
		closeOrder: build.query<void, CloseOrderParams>({
      query: (body) => {
				return {url: urlPutOrderClose, method: 'PUT', body};
			},
			onQueryStarted: onPutQueryStarted(messageErrCloseOrder, messageSuccessUpdatePlatform )
    }),
		confirmOrder: build.query<void, ConfirmOrderParams>({
      query: (body) => {
				return {url: urlPutOrderConfirm, method: 'PUT', body};
			},
			onQueryStarted: onPutQueryStarted(messageErrConfirmOrder, messageSuccessUpdatePlatform )
    }),
		activateOrder: build.query<void, ActivateOrderParams>({
      query: (body) => {
				return {url: urlPutOrderActivate, method: 'PUT', body};
			},
			onQueryStarted: onPutQueryStarted(messageErrConfirmOrder, messageSuccessUpdatePlatform )
    }),
	})
});

export const {
	useGetAllOrdersQuery,
	useGetAllOrdersTotalQuery,
	useLazyCloseOrderQuery,
	useLazyConfirmOrderQuery,
	useLazyActivateOrderQuery
} = crmApi;

