import {createAsyncThunk} from '@reduxjs/toolkit';

import {registerAPI} from '../../api/registration-api';
import {messageSuccessRemoveTypeServ} from '../../shared/constants/message-user';
import {handleAxiosError} from '../../shared/utils/handle-axios-error';
import {setIsShowMessage, setMessageUser, setShowLoader} from '../reducers/user-data-reducer';

export const fetchRemoveServiceType = createAsyncThunk(
	'organizationSlice/fetchRemoveServiceType',
	async (data: { service_type: string }, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await registerAPI.removeServicesTypes(data);
			dispatch(setShowLoader({isShowLoader: false}));
			if (response.status === 200) {
				dispatch(setMessageUser({messageUser: messageSuccessRemoveTypeServ}));
				dispatch(setIsShowMessage({isShowMessage: true}));
				return {status: response.status};
			}
		} catch (error) {
				handleAxiosError({dispatch, rejectWithValue, error});
		}
	},
);
