import {TFullUserRole} from '../../interfaces/role';
import {LOCAL_STORAGE_KEY_MAP} from '../../shared/constants/local-storage';
import {getLocalStorage} from '../../shared/utils/save-local-storage';
import {RootState} from '../store';

export const isShowLoaderSelector = (state: RootState) => state.userState.isShowLoader;
export const dataMessageSelector = (state: RootState) => state.userState.messageUser;
export const dataUserSelector = (state: RootState) => state.userState.userData;
export const dataUsermetaSelector = (state: RootState) => state.userState.userMeta;
export const isShowMessageSelector = (state: RootState) => state.userState.isShowMessage;
export const isAuthSelector = (state: RootState) => state.userState.isAuth;
export const isShowAuthSelector = (state: RootState) => state.userState.isShowAuth;
export const passwordUserSelector = (state: RootState) => state.userState.userData.password;
export const isErrorAuthSelector = (state: RootState) => state.userState.isErrorAuth;
export const timeTokenSelector = (state: RootState) => state.userState.jwtData.exp;
export const jwtRoleSelector = (state: RootState) => state.userState.jwtData.roles;
export const allUsersSelector = (state: RootState) => state.userState.allUsers;
export const checkedUserRolesSelector = (state: RootState): TFullUserRole[] => state.userState.checkedUserFullRoles || [];
export const viewedUserRolesSelector = (state: RootState): TFullUserRole[] => state.userState.viewedUserFullRoles || [];
export const allUsersTotalCountSelector = (state: RootState) => state.userState.totalCount;
export const allPlatformsVisitedTotalSelector = (state: RootState) => state.userState.allPlatformsVisitedUserTotal;
export const allPlatformsVisitedSelector = (state: RootState) => state.userState.allPlatformsVisitedUser;
export const allOrganizationsVisitedTotalSelector = (state: RootState) =>
	state.userState.allOrganizationsVisitedUserTotal;
export const allOrganizationsVisitedSelector = (state: RootState) => state.userState.allOrganizationsVisitedUser;
export const allServicesVisitedTotalSelector = (state: RootState) => state.userState.allServicesVisitedUserTotal;
export const allServicesVisitedSelector = (state: RootState) => state.userState.allServicesVisitedUser;
export const loginCheckedUserSelector = (state: RootState) => state.userState.loginCheckedUserForAddRole;
export const nickUserSelector = (state: RootState) => state.userState.nickName;
export const stepRegistration1Selector = (state: RootState) => state.userState.stepRegistration1;
export const stepRegistration2Selector = (state: RootState) => state.userState.stepRegistration2;

export const blackListPlatformSelector = (state: RootState) => state.userState.allBlackUsers;
export const blackListTotalCountSelector = (state: RootState) => state.userState.allBlackTotalCount;
export const commentariesUser = (state: RootState) => state.userState.commentaries;
export const requestStatusSelector = (state: RootState) => state.userState.status;
export const orderSelector = (state: RootState) => state.userState.order;
export const orderAttachSelector = (state: RootState) => state.userState.orderAttach;
export const historyOrdersSelector = (state: RootState) => state.userState.historyOrders;
export const historyOrdersTotalSelector = (state: RootState) => state.userState.historyOrdersTotal;
export const openForUrlSelector = (state: RootState) => state.userState.openForUrl;

export const closestOrderUserSelector = (state: RootState) => state.userState.closestOrderUser;

export const isNetworkSelector = (state: RootState) => state.userState.isNetwork;
export const isUserCheckedServiceSelector = (state: RootState) => state.userState.isUserCheckedService;
export const selectUserCity = (state: RootState) => state.userState.city;
export const selectAvatars = (state: RootState) => state.userState.avatars;
export const selectedAdminFullRole = (state: RootState): TFullUserRole => state.userState.selectedAdminFullRole || getLocalStorage(LOCAL_STORAGE_KEY_MAP.selectedFullRole);
