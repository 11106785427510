import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface InitialStateFAQ {
	idSelectsMenu: number;
	activeSelectContent: string[];
}

const initialState: InitialStateFAQ = {
	idSelectsMenu: 1,
	activeSelectContent: []
};

export const faqSlice = createSlice({
	name: 'FAQ',
	initialState,
	reducers: {
		changeIdSelectsMenu: (state: InitialStateFAQ, action: PayloadAction<number>) => {
			state.idSelectsMenu = action.payload;
		},
		changeActiveSelectContent: (state: InitialStateFAQ, action: PayloadAction<string>) => {
			state.activeSelectContent = [action.payload];
		},
		// 	if (state.activeSelectContent.includes(action.payload)) {
		// 		state.activeSelectContent = state.activeSelectContent.filter(i => i !== action.payload);
		// 	} else {
		// 		state.activeSelectContent.push(action.payload);
		// 	}
		// },
	}
});

export const {changeIdSelectsMenu, changeActiveSelectContent} = faqSlice.actions;
