import classNames from 'classnames';
import {CSSProperties, FC, JSX, PropsWithChildren, ReactNode, useRef} from 'react';

import {useClickOutside} from '../../../shared/hooks/use-click-outside';
import IconArrowDown from '../../svg-icon-components/icon-arrow-down';
import IconCloseThin from '../../svg-icon-components/icon-close-thin';
import {FieldBase} from '../field-base/field-base';
import styles from './openable-field-base.module.css';

interface OpenableFieldBaseProps extends PropsWithChildren {
	clearable?: boolean;
	isOpen: boolean;
	icon?: JSX.Element;
	className?: string;
	displayValueClassName?: string;
	iconStrokeColor?: string;
	placeholder?: string;
	value: string | ReactNode;
	onClear?: (value: any) => void,
	onToggle: (isOpen: boolean) => void;
	styleFieldBase?: CSSProperties
}

export const OpenableFieldBase: FC<OpenableFieldBaseProps> = ({
																  styleFieldBase,
																  clearable = false,
																  isOpen,
																  icon,
																  className,
																  iconStrokeColor = '#4D4D4D',
																  placeholder,
																  value,
																  children,
																  onToggle,
																  onClear,
																  displayValueClassName,
															  }) => {
	const elementRef = useRef<HTMLDivElement>(null);
	useClickOutside(elementRef, () => onToggle(false));


	const showArrow = !clearable || clearable && !value;

	return (
		<div className={classNames(styles.openable_field_base)}>
			<FieldBase style={styleFieldBase} icon={icon} onClick={() => {
				onToggle(!isOpen);
			}} className={className} ref={elementRef}>
				<span
					className={classNames(styles.display_value, {
						[displayValueClassName || styles.display_value_filled]: value,
					})}
				>
					{value || placeholder}
				</span>

				{clearable && value && <IconCloseThin
					onClick={(event: any) => {
						onClear?.('');
						event.stopPropagation();
					}}
					strokeColor={iconStrokeColor}
					width={9}
					height={9}
				/>}

				{showArrow && <IconArrowDown
					strokeColor={iconStrokeColor}
					className={classNames(styles.toggle_icon, {
						[styles.toggle_icon_opened]: isOpen,
					})}
				/>}
			</FieldBase>
			{isOpen && children}
		</div>
	);
};
