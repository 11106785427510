import classNames from 'classnames';
import React, {FC, ReactNode, useEffect, useRef, useState} from 'react';

import {useClickOutside} from '../../shared/hooks/use-click-outside';
import styles from './overflow-tooltip.module.css';

interface OverflowTooltipProps{
	classNameContainer?: string,
	content: ReactNode,
	className?: string
}

function isTextOverflow(element: any) {
	if (!element) {
		return false;
	}
	return element.clientWidth + 5 < element.scrollWidth;
}

export const OverflowTooltip:FC<OverflowTooltipProps> = ({content, className, classNameContainer}) => {
	const [overflow, setOverflow] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);
	const ref = useRef<HTMLDivElement | null>(null);
	const tooltipRef = useRef<HTMLDivElement>(null);
	useClickOutside(tooltipRef, () => setShowTooltip(false));

	useEffect(() => {
		setOverflow(isTextOverflow(ref.current));
	}, []);

	return (<div key={new Date().getTime()}
				 className={classNames(styles.overflow_tooltip_container,  classNameContainer)}
				 onMouseOver={() => {
					 setShowTooltip(true);
				 }} onMouseOut={() => {
		setShowTooltip(false);
	}}>
		<p className={classNames(styles.ellipsised, className, {[styles.pointer]: overflow})} ref={ref}>{content}</p>
		{showTooltip && overflow &&
			<div ref={tooltipRef} className={classNames(styles.tooltip)}>{content}</div>}
	</div>);
};
