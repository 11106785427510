import styled from '@emotion/styled';

interface ServiceTextContentStyle {
	checked?: boolean
	ImgWrapper?: boolean
}

export const ServiceCardContainer = styled.div`
	height: 100%;
	border-radius: 8px;
`;
export const ServiceTextContent = styled.div<ServiceTextContentStyle>`
	background-color: ${props=> props.checked ? 'var(--green)' : 'var(--grey)'};
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	margin: 0 6px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 18px;
	padding: 14px 14px 24px;
	height: 220px;
	position: relative;
`;
export const SportsKindTypesWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 6px;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	margin-right: 44px;
	height: 80px;
	color: var(--white-color);
`;
export const SportKind = styled.span<ServiceTextContentStyle>`
	background-color: ${props=> props.checked ? 'var(--white-color)' : 'var(--green)'};
	border-radius: 6px;
	padding: 2px 10px;
	color: ${props=> !props.checked ? 'var(--white-color)' : 'var(--green)'};
	height: 24px;
`;
export const SportType= styled.span`
	background-color: var(--yellow);
	border-radius: 6px;
	padding: 2px 10px;
	height: 24px;
`;
export const Sport = styled.span`
	background-color: var(--purple);
	border-radius: 6px;
	padding: 2px 10px;
	height: 24px;
`;
export const ImgWrapper = styled.div<ServiceTextContentStyle>`
	position: relative;

	${props=> props.ImgWrapper &&`
	cursor: pointer;
	transition: 0.7s ease-in-out;

	&:hover{
	filter: brightness(0.6);

	button{
		display: block;
	}
		`}
	}
`;
export const Button = styled.button`
	display: none;
	background-color: rgb(245, 245, 245);
	bottom: 10%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 7px 12px 9px;
	border-radius: 6px;
	cursor: pointer;
	color: var(--button-wb);
	border: 1px solid var(--button-wb);
	transition: 0.7s ease-in-out;
	position: absolute;
	&:hover{
		background: var(--button-wb);
		color: var(--white-color);
	}
`;
export const ServiceLogo = styled.img`
	width: 100%;
	object-fit: cover;
	aspect-ratio: 16 / 9;
	border-radius: 8px;
	border: 1px solid var(--gray-middle);
`;
export const OrgInfoLogo = styled.img`
	position: absolute;
	right: 10px;
	top: 12px;
	border-radius: 50%;
	width: 58px;
	height: 58px;
`;
export const TitleService = styled.h2<ServiceTextContentStyle>`
	font-weight: 500;
	font-size: 18px;
	line-height: 23px;
	color: ${props=> props.checked ? 'var(--white-color)' : 'var(--black-color)'};
`;
export const ItemsWrapper = styled.div<ServiceTextContentStyle>`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 4px;
	color: ${props=> props.checked ? 'var(--white-color)' : 'var(--title)'};
`;
export const ItemContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
`;
export const ItemWithIcon = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;
export const ServiceInfoIcon = styled.div`
	position: absolute;
	right: 14px;
	top: 14px;
	cursor: pointer;
`;
