import {createAsyncThunk} from '@reduxjs/toolkit';

import {registerAPI} from '../../../api/registration-api';
import {UpdateUser} from '../../../interfaces/interfaces';
import {getIdUser} from '../../../shared/utils/decoder-jwt';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {fetchGetUserById} from './get-user-by-id';

export const fetchUpdateDataUser = createAsyncThunk(
	'userDataSlice/fetchUpdateDataUser',
	async (data: UpdateUser, {dispatch, rejectWithValue}) => {
		try {
			const response = await registerAPI.updateUserData(data);
			if (response.status === 200) {
				dispatch(fetchGetUserById(getIdUser()));
				return {status: response.status, data};
			}
		} catch (error) {
				handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
