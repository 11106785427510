import {createAsyncThunk} from '@reduxjs/toolkit';

import {historyServicesAPI} from '../../../api/history';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';

export const fetchGetUserClosestOrder = createAsyncThunk(
	'userDataSlice/fetchGetUserClosestOrder',
	async (idUser: string, {dispatch, rejectWithValue}) => {
		try {
			const response = await historyServicesAPI.getClosestService(idUser);

			if (response.status === 200) {
				return {status: response.status, data: response.data.order};
			}
			if (response.status === 204) {
				return {status: response.status, data: []};
			}
		} catch (error) {
				handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
