import React, {FC, JSX, useCallback, useEffect, useState} from 'react';
import {DayPickerProps} from 'react-day-picker';

import {getCalendarDisplayValue} from '../../../shared/utils/time/get-calendar-display-value';
import {Calendar} from '../calendar/calendar';
import {OpenableFieldBase} from '../openable-field-base/openable-field-base';
import styles from './calendar-field.module.css';

type CalendarFieldProps = DayPickerProps & {
	value: Date | undefined;
	icon: JSX.Element;
	placeholder: string;
	openable?: boolean;
	onChange: (value: Date | undefined) => void;
};

export const CalendarField: FC<CalendarFieldProps> = ({
	icon, placeholder, value, onChange, openable, ...props
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [displayValue, setDisplayValue] = useState(getCalendarDisplayValue(value));
	useEffect(() => {
		setDisplayValue(getCalendarDisplayValue(value));
	},[value]);
	const onSelect = useCallback(
		(value: Date | undefined) => {
			setDisplayValue(getCalendarDisplayValue(value));
			onChange(value);
			setIsOpen(false);
		},
		[],
	);
	return (
		<OpenableFieldBase
			onClear={()=>onSelect(undefined)}
			clearable={!openable}
			isOpen={openable ? false : isOpen}
			value={value ? displayValue : ''}
			icon={icon}
			placeholder={placeholder}
			onToggle={setIsOpen}
		>
			<div
				className={styles.calender_container}
				onClick={(event) => {
					event.stopPropagation();
				}}
			>
				<Calendar {...props} mode="single" selected={value} onSelect={onSelect} />
			</div>
		</OpenableFieldBase>
	);
};
