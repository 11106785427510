import {createAsyncThunk} from '@reduxjs/toolkit';

import {GetServicesTotalByFilterParams} from '../../../api/query-params';
import {SearchPlatformAPI} from '../../../api/search-platform-api';
import {requestCodes} from '../../../shared/constants/request-codes';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setServicesTotal} from '../../reducers/platforms-reducer';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const fetchGetServicesTotalByFilter = createAsyncThunk(
	'fieldSlice/fetchGetServicesTotalByFilter',
	async (params: GetServicesTotalByFilterParams | undefined, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await SearchPlatformAPI.getServicesTotalByFilter(params);

			if (response.status === requestCodes.success) {
				dispatch(setServicesTotal({data: response.data.total}));
				return {status: response.status, data: response.data.total};
			}
		} catch (error) {
				handleAxiosError({dispatch,rejectWithValue,error});
		} finally {
			dispatch(setShowLoader({isShowLoader: false}));
		}
	},
);
