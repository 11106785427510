import cnBind from 'classnames/bind';
import {memo, useMemo, useState} from 'react';

import {ScheduleInfo} from '../../interfaces/interfaces';
import {useBooleanState} from '../../shared/hooks/useBooleanState';
import {useGetDayInfo} from '../../shared/utils/get-day-info';
import {converterTime} from '../../shared/utils/time/converter-time';
import {extractUniqueTimePairs} from '../../shared/utils/time/extract-unique-time-pairs';
import {CalendarModal} from '../CalendarModal/calendar-modal';
import {SingleSelect} from '../controls';
import styles from './index.module.css';

const cx = cnBind.bind(styles);

export const WorkSchedule = memo(({schedule}: { schedule: ScheduleInfo[] }) => {
		const {value, toggleValue} = useBooleanState(false);
		const [selectedDay, setSelectedDay] = useState<Date>(new Date());
		const handleSelectDay = (data: Date) => setSelectedDay(data);
		const {dayInfo} = useGetDayInfo(selectedDay, schedule);
		const timeConv = useMemo(() =>
				dayInfo === 'Weekend'
					? ['Выходной']
					: Object.values(dayInfo.Working.schedule).map(el =>
						`${converterTime(el.start_working)}-${converterTime(el.end_working)}`)
			, [dayInfo]);

		const uniqueTime =
			extractUniqueTimePairs([...new Set(timeConv)]);

		return (
			<div className={cx('contacts_list_item_select')}>
				{uniqueTime.length === 1 || uniqueTime[0] === 'Выходной'
					? <span>{uniqueTime[0]}</span>
					: <SingleSelect
						containerClassName={styles.selector_container}
						options={uniqueTime.map((time) => ({
							value: time,
							label: time,
						}))}
						value={uniqueTime[0]}
					/>
				}
				<div className={cx('selector_calendar')}
					 onClick={() => toggleValue()}>
					{selectedDay.toLocaleDateString() === new Date().toLocaleDateString()
						? <span>Сегодня</span>
						: <span>{selectedDay.toLocaleDateString().toString()}</span>
					}
					<CalendarModal
						isCalendarOpened={value}
						hideNavigation
						openFromMonth={selectedDay.getMonth() + 1}
						closeCalendar={toggleValue}
						handleSelectDay={handleSelectDay}
						selectedDay={selectedDay}
						isColor
					/>
				</div>
			</div>
		);
	})
;
