import styled from '@emotion/styled';
import {Link} from 'react-router-dom';

interface LogoWrapperStyle {
	isHovered?: boolean
}

export const LogoWrapper = styled.div<LogoWrapperStyle>`
	position: relative;
	transition: 0.7s;

	${props => props.isHovered && `
		&:hover {
		button {
			display: block;
		}
		.logo{
			filter: brightness(0.6);
		}
	}
	`}
`;
export const Logo = styled.img<LogoWrapperStyle>`
	width: 100%;
	object-fit: cover;
	aspect-ratio: 16 / 9;
	border-radius: 8px;
	border: 1px solid var(--gray-middle);
	transition: 0.7s;

	${props => props.isHovered && `
	&:hover {
		filter: brightness(0.6);
	    }
    `}
`;
export const OrgLogoWrapper = styled(Link)`
	background-color: transparent;
	cursor: pointer;
	position: absolute;
	right: 10px;
	top: 12px;
`;
export const OrgLogo = styled.img`
	border-radius: 50%;
	width: 58px;
	height: 58px;
	transition: 0.7s;

	&:hover {
		filter: brightness(0.6);
	}
`;
export const FastPassageService = styled.button`
	display: none;
	background-color: rgb(245, 245, 245);
	bottom: 10%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 7px 12px 9px;
	border-radius: 6px;
	cursor: pointer;
	color: var(--button-wb);
	border: 1px solid var(--button-wb);
	transition: 0.5s;
	position: absolute;

	&:hover {
		background: var(--button-wb);
		color: var(--white-color);
	}
`;
