import React from 'react';
import {NavLink} from 'react-router-dom';

import {ServiceTypeKind} from '../../../../interfaces/enums';
import {ORDER, SERVICES} from '../../../../shared/constants/path';
import {handleScrollToTop} from '../../../../shared/utils/scroll-to-top';
import styles from './navbar.module.css';

export const NavBar = () => {
	const stylesCallback = ({isActive}: {
		isActive: boolean
	}) => (isActive ? styles.nav_link_active : styles.nav_link);

	return (
		<nav className={styles.navbar}>
			<ul>
				<li>
					<NavLink to={ORDER} className={stylesCallback} onClick={handleScrollToTop}>
						Бронировать
					</NavLink>
				</li>
				<li>
					<NavLink to={`${SERVICES}/${ServiceTypeKind.workout.toLocaleLowerCase()}`}
							 className={stylesCallback}
							 onClick={handleScrollToTop}>
						Тренировки
					</NavLink>
				</li>
				<li>
					<NavLink to={`${SERVICES}/${ServiceTypeKind.tournament.toLocaleLowerCase()}`}
							 className={stylesCallback}
							 onClick={handleScrollToTop}>
						Турниры
					</NavLink>
				</li>
				<li>
					<NavLink to={`${SERVICES}/${ServiceTypeKind.event.toLocaleLowerCase()}`}
							 className={stylesCallback}
							 onClick={handleScrollToTop}>
						Мероприятия
					</NavLink>
				</li>
				<li>
					<NavLink to={`${SERVICES}/${ServiceTypeKind.inventory.toLocaleLowerCase()}`}
							 className={stylesCallback}
							 onClick={handleScrollToTop}>
						Инвентарь
					</NavLink>
				</li>
				<li>
					<NavLink to={`${SERVICES}/${ServiceTypeKind.rent.toLocaleLowerCase()}`} className={stylesCallback}
							 onClick={handleScrollToTop}>
						Спортзалы
					</NavLink>
				</li>
			</ul>
		</nav>
	);
};
