import cnBind from 'classnames/bind';
import React, {
	CSSProperties,
	DetailedHTMLProps,
	forwardRef,
	HTMLAttributes,
	InputHTMLAttributes,
	JSX,
	memo,
} from 'react';

import {FieldBase, IconPosition} from '../field-base/field-base';
import styles from './input.module.css';

const cx = cnBind.bind(styles);

export interface InputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	icon?: JSX.Element;
	iconPosition?: `${IconPosition}`;
	containerClassName?: string;
	labelClassName?: string;
	wrapperProps?: HTMLAttributes<HTMLDivElement>;
	appearance?: 'error';
	label?: string;
	isToggle?: boolean;
	error?: boolean;
	errorText?: string;
	obligatoryField?: string;
	style?: CSSProperties;
}

const PureInput = forwardRef<HTMLInputElement, InputProps>(function ForwardInput(
	{
		icon, labelClassName, error, errorText, containerClassName, style,
		wrapperProps = {}, label, id, obligatoryField, appearance, iconPosition, ...restProps
	},
	ref,
) {
	return (
		<div className={cx('input_wrapper')} {...wrapperProps}>
			{error && <span className={cx('error')}>{errorText}</span>}
			{label && (
				<label htmlFor={id} className={cx(!labelClassName ? 'label' : labelClassName)}>
					{label}<span className={'required_fields'}>{obligatoryField}</span>
				</label>
			)}
			<FieldBase
				icon={icon}
				style={style}
				iconPosition={iconPosition}
				className={cx(!containerClassName ? 'input_base' : containerClassName, styles[`appearance_${appearance}`])}
			>
				<input {...restProps} id={id} className={cx('input', restProps.className)} ref={ref} />
			</FieldBase>
		</div>
	);
});

export const Input = memo(PureInput);
