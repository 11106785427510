import axios from 'axios';

import {
	AddImage,
	InputTypesRegistration,
	IServiceType,
	IUpdateServiceType, OrganizationName,
	RecoverPasswordCompleteData, RegisterOrgRequest,
	RemoveImage,
	SystemID,
	UpdateUser,
	UserRequestAuth,
} from '../interfaces/interfaces';
import {RegisterOrganization} from '../interfaces/organization';
import {RegisterPlatform} from '../interfaces/platform';
import {
	HOST_REGISTER,
	urlAddAllowsServices,
	urlAddCity,
	urlAddImg,
	urlAddRoleUser,
	urlAddServiceType, urlApproveNewOrg,
	urlAuthRecoverUser,
	urlAuthUser,
	urlGetAvatars, urlGetOrgCreations,
	urlPostAddSport, urlPostRegisterOrgRequest,
	urlRecoverComplete, urlRecoverOrg, urlRecoverPlatform,
	urlRegisterOrg,
	urlRegisterPlatform,
	urlRegisterUser,
	urlRegisterUserStep1,
	urlRemoveAllowsServices,
	urlRemoveCity,
	urlRemoveImg, urlRemoveOrg, urlRemovePlatform,
	urlRemoveRoleUser,
	urlRemoveServiceType,
	urlRemoveSport,
	urlUpdateNickname,
	urlUpdatePassword,
	urlUpdateServiceType,
	urlUpdateSport,
	urlUpdateUserData,
	urlUploadImg,
} from '../shared/constants/url';
import {jwtUser} from '../shared/utils/save-local-storage';

const instance = axios.create({
	baseURL: HOST_REGISTER,
	headers: {
		'Content-Type': 'application/json',
	},
});

const instanceJwt = axios.create({
	baseURL: HOST_REGISTER,
	headers: {
		'Content-Type': 'application/json',
	},
});

instanceJwt.interceptors.request.use(
	(request) => {
		request.headers.Authorization = `Bearer ${jwtUser()}`;
		return request;
	},
	(error) => {
		console.log(error, 'No token');
	},
);

const instanceJwtAlt = axios.create({
	baseURL: HOST_REGISTER,
	headers: {
		'Content-Type': 'multipart/form-data',
	},
});

instanceJwtAlt.interceptors.request.use(
	(request) => {
		request.headers.Authorization = `Bearer ${jwtUser()}`;
		return request;
	},
	(error) => {
		console.log(error, 'No token');
	},
);

export const registerAPI = {
	registerUserStep1(data: {email: string}) {
		return instance.post(urlRegisterUserStep1, data);
	},
	registerUser(data: InputTypesRegistration) {
		return instance.post(urlRegisterUser, data);
	},
	authUser(data: UserRequestAuth) {
		return instance.post(urlAuthUser, data);
	},
	registerPlatform(data: RegisterPlatform) {
		return instanceJwt.post(urlRegisterPlatform, data);
	},
	registerOrganization(data: RegisterOrganization) {
		return instanceJwt.post(urlRegisterOrg, data);
	},
	removePlatform(data:SystemID) {
		return instanceJwt.put(urlRemovePlatform, data);
	},
	recoverPlatform(data:SystemID) {
		return instanceJwt.put(urlRecoverPlatform, data);
	},
	removeOrg(data:OrganizationName) {
		return instanceJwt.put(urlRemoveOrg, data);
	},
	recoverOrg(data:OrganizationName) {
		return instanceJwt.put(urlRecoverOrg, data);
	},
	// TODO: should not be an type
	addRoleUser(data: any) {
		return instanceJwt.post(urlAddRoleUser, data);
	},
	removeRoleUser(data: any) {
		return instanceJwt.put(urlRemoveRoleUser, data);
	},
	updateNickname(data: {user_id: string; new_nickname: string}) {
		return instanceJwt.put(urlUpdateNickname, data);
	},
	updatePassword(data: {user_id: string; new_password: string}) {
		return instanceJwt.put(urlUpdatePassword, data);
	},
	updateUserData(data: UpdateUser) {
		return instanceJwt.put(urlUpdateUserData, data);
	},
	recoverPassword(data: {email: string}) {
		return instance.post(urlAuthRecoverUser, data);
	},
	recoverPasswordComplete(data: RecoverPasswordCompleteData) {
		return instance.post(urlRecoverComplete, data);
	},
	removeTypeSport(data: {sport: string}) {
		return instanceJwt.put(urlRemoveSport, data);
	},
	updateTypeSport(data: {new_logo: string | null; new_name: string; sport_type: string}) {
		return instanceJwt.put(urlUpdateSport, data);
	},
	updateServiceType(data: IUpdateServiceType) {
		return instanceJwt.put(urlUpdateServiceType, data);
	},
	addImg(data: AddImage) {
		return instanceJwt.post(urlAddImg, data);
	},
	removeImg(data: RemoveImage) {
		return instanceJwt.put(urlRemoveImg, data);
	},
	addSportTypes(typeSport: {sport: string} | {sport_type: string; logo: string | null}) {
		return instanceJwt.post(urlPostAddSport, typeSport);
	},
	getAvatars() {
		return instance.get(urlGetAvatars);
	},
	addServicesTypes(typeService: IServiceType) {
		return instanceJwt.post(urlAddServiceType, typeService);
	},
	removeServicesTypes(typeService: {service_type: string}) {
		return instanceJwt.put(urlRemoveServiceType, typeService);
	},
	addAllowsServicesTypes(typeSport: string, typeService: {service_type: string}) {
		return instanceJwt.post(`${urlAddAllowsServices}${typeSport}`, typeService);
	},
	removeAllowsServicesTypes(typeSport: string, typeService: {service_type: string}) {
		return instanceJwt.put(`${urlRemoveAllowsServices}${typeSport}`, typeService);
	},
	addCity(data: {city: string}) {
		return instanceJwt.post(urlAddCity, data);
	},
	removeCity(data: {city: string}) {
		return instanceJwt.put(urlRemoveCity, data);
	},
	uploadImage(data: any) {
		return instanceJwtAlt.put(urlUploadImg, data);
	},
	addOrgRequest(data: RegisterOrgRequest) {
		return instance.post(urlPostRegisterOrgRequest, data);
	},
	getOrgCreations() {
		return instanceJwt.get(urlGetOrgCreations);
	},
	addApproveNewOrg(data: {request_id: string}) {
		return instanceJwt.put(urlApproveNewOrg, data);
	},
};
