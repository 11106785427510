import {createAsyncThunk} from '@reduxjs/toolkit';

import {crmPlatformAPI} from '../../../api/crm-platform';
import {SystemID} from '../../../interfaces/interfaces';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';


export const fetchRemoveService = createAsyncThunk(
    'fetchRemoveService',
    async (data:SystemID, {rejectWithValue, dispatch}) => {
        try {
            const response = await crmPlatformAPI.removeService(data);
            return response.data;
        } catch (error) {
				handleAxiosError({dispatch,rejectWithValue,error});
        }
    },
);
