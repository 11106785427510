import {FullscreenControl, Map, Placemark,useYMaps, ZoomControl} from '@pbe/react-yandex-maps';
import classNames from 'classnames';
import React, {FC, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useAppSelector} from '../../redux/selectors/hook';
import {selectUserCity} from '../../redux/selectors/user-state-selectors';
import {DEFAULT_CITY, DEFAULT_ZOOM, SIZE_PLACEMARK, ZOOM_ALL_CITIES} from '../../shared/constants/ymaps';
import {getCoordinates} from '../../shared/utils/get-coordinates';
import {PLACEMARK_OPTIONS} from './constants';
import styles from './map.module.css';
interface IMapComponentProps {
	className?: string;
	coordinates: IAddress[];
	mapWidth?: string;
	mapHeight?: string;
	focusAddress?: string | null;
}
interface IAddress {
	coordinates: number[];
	address: string;
	link?: string
}

export const MapComponent: FC<IMapComponentProps> = ({
	className, mapWidth, mapHeight, focusAddress,
	coordinates
}) => {
	const navigate = useNavigate();
	const city = useAppSelector(selectUserCity);
	const [center, setCenter] = useState<number[] | undefined>(undefined);
	const mapRef = useRef<any>(null);
	const ymaps = useYMaps();


	const setNewCoordinates = (newCoordinates: number[]) => {
		setCenter(newCoordinates);

		if (mapRef.current) {
			mapRef.current.setCenter(newCoordinates);
			mapRef.current.setZoom(city ? DEFAULT_ZOOM : SIZE_PLACEMARK - 1);
		}
	};

	useEffect(() => {
		if (!focusAddress) {
			ymaps?.ready(() => {
				getCoordinates(city ?? DEFAULT_CITY, ymaps).then((coordinates) => {
					setNewCoordinates(coordinates);
				});
			});
		}

		const newCoordinates = coordinates.find((item) => item.address === focusAddress)?.coordinates;

		if(newCoordinates){
			setNewCoordinates(newCoordinates);
		}

	}, [coordinates, focusAddress, city, ymaps]);

	return (
		<div className={classNames(styles.map, className)}>
			{center && (
				<Map
					width={mapWidth}
					height={mapHeight}
					defaultState={{
						center,
						zoom: city ? DEFAULT_ZOOM : ZOOM_ALL_CITIES - 1,
						controls: [],
					}}
					instanceRef={(ref) => {
						mapRef.current = ref;
					}}
				>
					<ZoomControl
						options={{
							size: 'small',
							position: {
								top: 200,
								right: 10,
							},
						}}
					/>
					<FullscreenControl />

					{coordinates?.map((el, index) => (
						<Placemark
							key={index}
							geometry={el.coordinates}
							properties={{iconCaption: 'Мы здесь'}}
							options={PLACEMARK_OPTIONS}
							onClick={()=>{
								if(el.link){
									navigate(el.link);
								}
							}}
						/>
					))}
				</Map>
			)}
		</div>
	);
};

MapComponent.defaultProps = {
	mapWidth: '100%',
	mapHeight: '400px',
};
