import classNames from 'classnames';
import React, {ReactNode, RefObject} from 'react';

import styles from './option.module.css';

export interface IOption<T> {
	value: T;
	label: string | ReactNode
}

interface OptionProps<T> {
	isSelected?: boolean;
	value: T;
	label: string | ReactNode;
	className?: string;
	activeClassName?: string;
	onChange?: (value: T) => void;
	ref?: RefObject<HTMLDivElement>;
}

export function Option<T extends number | string | null>({
	value,
	label,
	isSelected,
	className,
	activeClassName,
	onChange,
    ref,
}: OptionProps<T>) {

	return (
		<div ref={ref}
			className={classNames(styles.option, className, {
				[activeClassName || styles.option_active]: isSelected,
			})}
			onClick={() => onChange?.(value)}
		>
			{label}
		</div>
	);
}
