import {FC, memo, useEffect, useState} from 'react';

import noImagePlaceholder from '../../../assets/img/no_image.png';
import IconSporder from '../../../components/svg-icon-components/icon-sporder';
import {PlatformSingle} from '../../../interfaces/platform';
import {useResizeWindow} from '../../../shared/hooks/use-resize-window';
import {FlickitySlider} from '../../flickity-slider/flickity-slider';
import {UniqueImage} from '../../images/index.type';
import {Spoiler} from '../../Spoiler/Spoiler';
import {PlatformCardContactsPage} from './components/PlatformCardContactsPage/PlatformCardContactsPage';
import {Slider} from './components/slider/slider';
import * as SC from './platform-card.style';

export interface PlatformCardProps {
	platform: PlatformSingle;
	className?: string;
	onClickToOrg: (org_name: string) => void;
	onShowMap?: () => void;
}

export const PlatformCard: FC<PlatformCardProps> = memo(({platform, onClickToOrg}) => {
	const [showSliderWindow, setShowSliderWindow] = useState(false);
	const [showImgId, setShowImgId] = useState('');

	const [checkedImg, setCheckedImg] = useState(platform.images[0]);

	const slickShowSlider = (id?: string) => {
		if (id) {
			setShowImgId(id);
		}
		setShowSliderWindow(prevState => !prevState);
		if (!showSliderWindow) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	};
	useEffect(() => {
		const logo = platform.images.find(i=> i.info.is_logo);
		if(logo){
			setCheckedImg(logo);
		}
	}, [platform]);
	const width = useResizeWindow();
	const isMobileMod = width <= 1040;
	const clickCheckedImg = (checkedImg: UniqueImage) => {
		setCheckedImg(checkedImg);
	};
	return (
		<SC.Container>
			<SC.SliderContainer>
				  <SC.CheckedPhoto src={checkedImg.info.image_link || noImagePlaceholder} alt='Галерея площадки'
								 title={'Галерея площадки'}/>
				<Slider images={platform.images} clickCheckedImg={clickCheckedImg} showImgId={checkedImg.id}/>
			</SC.SliderContainer>
			<SC.TextContainer>
				<SC.PlatformContactsWrapper>
					<SC.PlatformCardMainInfo>
						<SC.PlatformCardTitle>{platform.org_name}</SC.PlatformCardTitle>
						<div onClick={() => onClickToOrg?.(platform?.org_name)}>
							<SC.PlatformCardOrganizationLogo
								src={platform.org_logo || noImagePlaceholder}
								alt={`Логотип - ${platform.org_name}`}
								title={`Логотип - ${platform.org_name}`}
							/>
						</div>
					</SC.PlatformCardMainInfo>
					<PlatformCardContactsPage platformSingle={platform}/>
				</SC.PlatformContactsWrapper>

				<SC.PlatformCardAdditionalInfo>
					<SC.ToMapLinkWrapper offset={-150} to='platform-map'>
						<SC.ToMapLink>на карту</SC.ToMapLink>
						<IconSporder strokeColor="#1C8666" width={12} height={15}/>
					</SC.ToMapLinkWrapper>
					{!isMobileMod && <SC.Description>{platform.description}</SC.Description>}
					{isMobileMod && <Spoiler description={platform.description}/>}
				</SC.PlatformCardAdditionalInfo>
			</SC.TextContainer>
			{showSliderWindow &&
				<FlickitySlider slickShowSlider={slickShowSlider} showImgId={showImgId} images={platform.images}/>}
		</SC.Container>
	);
});
