import {RefObject, useEffect} from 'react';

export const useModalWindow = (isRef: RefObject<Element>, callback: () => void) => {

    const handleClickOutside = (e: MouseEvent) => {
        if (isRef.current && !isRef.current.contains(e.target as HTMLDivElement)) {
            return callback();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

};
