import {FC} from 'react';
import {Link} from 'react-router-dom';

import * as SC from './quick-jump-button.style';

interface QuickJumpButtonProps {
	text: string
	type: 'link' | 'button'
	to?: string
	onClick?: () => void
}

export const QuickJumpButton: FC<QuickJumpButtonProps> = ({text, to, onClick, type}) => {
	return (
		<>
			{type === 'button' && <SC.Button onClick={onClick}>{text}</SC.Button>}
			{type === 'link' && <Link to={to || ''}><SC.Button onClick={onClick}>{text}</SC.Button></Link>}
		</>
	);
};
