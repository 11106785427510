import classNames from 'classnames';
import React, {CSSProperties, ForwardedRef, forwardRef, JSX, PropsWithChildren} from 'react';

import IconLineUp from '../../svg-icon-components/icon-line-up';
import styles from './field-base.module.css';

export enum IconPosition {
	start = 'start',
	end = 'end',
}

interface FieldBaseProps extends PropsWithChildren {
	icon?: JSX.Element;
	withIconDelimiter?: boolean;
	iconPosition?: `${IconPosition}`;
	className?: string;
	onClick?: () => void;
	style?:CSSProperties
}

export const FieldBase = forwardRef<HTMLDivElement, FieldBaseProps>(
	(
		{icon, className,style, children, withIconDelimiter, iconPosition = IconPosition.start, onClick},
		ref: ForwardedRef<HTMLDivElement>,
	) => {
		return (
			<div style={ style } className={classNames(styles.field_base, className)} ref={ref} onClick={onClick}>
				<div className={styles.content_container}>
					{icon && iconPosition === IconPosition.start ? (
						<div className={styles.content_container_before_icon}>
							{icon}
							{withIconDelimiter ? <IconLineUp /> : null}
						</div>
					) : null}

					{children}

					{icon && iconPosition === IconPosition.end ? (
						<div className={styles.content_container_after_icon}>{icon}</div>
					) : null}
				</div>
			</div>
		);
	},
);

FieldBase.defaultProps = {
	withIconDelimiter: true,
};
