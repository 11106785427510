import classNames from 'classnames';
import {FC, PropsWithChildren} from 'react';

import styles from './tag.module.css';

interface TagProps extends PropsWithChildren {
	className?: string;
}

export const Tag: FC<TagProps> = ({children, className}) => {
	return <div className={classNames(styles.tag, className)}>{children}</div>;
};
