import {TFullUserRole} from '../../../interfaces/role';
import {isAdminRole} from './is-admin-role';
import {isModeratorFullRole} from './is-moderator-role';
import {isOwnerRole} from './is-owner-role';
import {isViewerRole} from './is-viewer-role';

// TODO: add Coach role
export const isRole = (role: unknown): role is TFullUserRole => {
	return isViewerRole(role) || isAdminRole(role) || isModeratorFullRole(role) || isOwnerRole(role);
};
