import {AbonementsListFilterKey, ServiceListFilterKey} from '../../interfaces/service';
import {ANY_ACTIVITY_OPTION} from '../../shared/constants/service/filters';

export const DEFAULT_SERVICE_LIST_LIMIT = 20;
export const UNLIMITED = 'Не ограничено';

export const SERVICE_LIST_FILTERS = {
	[ServiceListFilterKey.sport_type]: ServiceListFilterKey.sport_type,
	[ServiceListFilterKey.service_type]: ServiceListFilterKey.service_type,
	[ServiceListFilterKey.service_kind]: ServiceListFilterKey.service_kind,
	[ServiceListFilterKey.org_name]: ServiceListFilterKey.org_name,
	[ServiceListFilterKey.activity]: ServiceListFilterKey.activity,
	[ServiceListFilterKey.platform_id]: ServiceListFilterKey.platform_id
} as const;


export const DEFAULT_FILTERS_DATA = {
	[SERVICE_LIST_FILTERS.sport_type]: '',
	[SERVICE_LIST_FILTERS.service_type]: '',
	[SERVICE_LIST_FILTERS.service_kind]: '',
	[SERVICE_LIST_FILTERS.org_name]: '',
	[SERVICE_LIST_FILTERS.activity]: ANY_ACTIVITY_OPTION.value,
	[SERVICE_LIST_FILTERS.platform_id]: ''
};
export const ABONEMENTS_LIST_FILTERS = {
	[AbonementsListFilterKey.service_type]: AbonementsListFilterKey.service_type,
	[AbonementsListFilterKey.kind]: AbonementsListFilterKey.kind,
	[AbonementsListFilterKey.sport_type]: AbonementsListFilterKey.sport_type,
	[AbonementsListFilterKey.platform_id]: AbonementsListFilterKey.platform_id,
	[AbonementsListFilterKey.org_name]: AbonementsListFilterKey.org_name,
	[AbonementsListFilterKey.unlimited]: AbonementsListFilterKey.unlimited,
	[AbonementsListFilterKey.price_from]: AbonementsListFilterKey.price_from,
	[AbonementsListFilterKey.price_to]: AbonementsListFilterKey.price_to,
} as const;
export const ABONEMENTS_DEFAULT_FILTERS_DATA = {
	[ABONEMENTS_LIST_FILTERS.platform_id]: ABONEMENTS_LIST_FILTERS.platform_id,
	[ABONEMENTS_LIST_FILTERS.org_name]: ABONEMENTS_LIST_FILTERS.org_name,
	[ABONEMENTS_LIST_FILTERS.unlimited]: ABONEMENTS_LIST_FILTERS.unlimited,
	[ABONEMENTS_LIST_FILTERS.sport_type]: ABONEMENTS_LIST_FILTERS.sport_type,
	[ABONEMENTS_LIST_FILTERS.kind]: ABONEMENTS_LIST_FILTERS.kind,
	[ABONEMENTS_LIST_FILTERS.service_type]: ABONEMENTS_LIST_FILTERS.service_type,
	[ABONEMENTS_LIST_FILTERS.price_from]: ABONEMENTS_LIST_FILTERS.price_from,
	[ABONEMENTS_LIST_FILTERS.price_to]: ABONEMENTS_LIST_FILTERS.price_to,
};
export const DEFAULT_FILTERS_ABONEMENTS_DATA = {
	[ABONEMENTS_DEFAULT_FILTERS_DATA.platform_id]: '',
	[ABONEMENTS_DEFAULT_FILTERS_DATA.org_name]: '',
	[ABONEMENTS_DEFAULT_FILTERS_DATA.unlimited]: '',
	[ABONEMENTS_DEFAULT_FILTERS_DATA.sport_type]: '',
	[ABONEMENTS_DEFAULT_FILTERS_DATA.kind]: '',
	[ABONEMENTS_DEFAULT_FILTERS_DATA.service_type]: '',
	[ABONEMENTS_DEFAULT_FILTERS_DATA.price_from]: '',
	[ABONEMENTS_DEFAULT_FILTERS_DATA.price_to]: '',
};
