import {FC, memo} from 'react';

import {OverflowTooltip} from '../../../../overflow-tooltip/overflow-tooltip';
import * as SC from './service-name.style';

interface ServiceNameProps{
	checked?: boolean
	serviceName: string
}

export const ServiceName:FC<ServiceNameProps> = memo(({serviceName, checked}) => {
	return (
		<SC.ServiceName checked={checked}>
			<OverflowTooltip
				content={serviceName}
			/>
		</SC.ServiceName>
	);
});

