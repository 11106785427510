import cnBind from 'classnames/bind';
import {CSSProperties, DetailedHTMLProps, forwardRef, HTMLAttributes, TextareaHTMLAttributes} from 'react';

import {FieldBase} from '../field-base/field-base';
import styles from './textarea.module.css';
const cx = cnBind.bind(styles);
export interface TextareaProps
	extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
	containerClassName?: string;
	appearance?: 'error';
	wrapperProps?: HTMLAttributes<HTMLDivElement>;
	label?: string;
	labelClassName?:string;
	styleFieldBase?: CSSProperties
}


export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(function ForwardInput(
	{containerClassName,labelClassName, wrapperProps = {}, label,styleFieldBase, id, appearance, ...restProps},
	ref,
) {

	return (
		<div className={cx ('textarea_wrapper')} {...wrapperProps}>
			{label && (
				<label htmlFor={id} className={cx(labelClassName ? labelClassName : 'label')}>
					{label}
				</label>
			)}
			<FieldBase
				style={styleFieldBase}
				className={cx(containerClassName, 'textarea_base', styles[`appearance_${appearance}`])}
			>
				<textarea
					{...restProps}
					id={id}
					className={cx('textarea', restProps.className)}
					ref={ref}
				/>
			</FieldBase>
		</div>
	);
});
