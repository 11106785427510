import {DayInfo} from '../../../interfaces/interfaces';
import {converterTime} from '../time/converter-time';

export const getServiceWorkingTime = (dayInfo: DayInfo) => {
	if (typeof dayInfo === 'string') {
		return ['Выходной', ''];
	}

	const minStart = Math.min(...dayInfo.Working.schedule.map((item) => item.start_working));
	const maxEnd = Math.max(...dayInfo.Working.schedule.map((item) => item.end_working));

	return `${converterTime(minStart)}-${converterTime(maxEnd)}`;

};
