import ls from 'localstorage-slim';

import {LOCAL_STORAGE_KEY_MAP} from '../constants/local-storage';

ls.config.encrypt = true;
ls.config.secret = 97;

export const saveLocalStorage = (key: string, value: any) => {
	localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key: string): any => {
	const item = localStorage.getItem(key);
	if (item && item !== 'undefined') {
		return JSON.parse(item);
	}
	return null;
};

export const isJwt = (): boolean => typeof localStorage.getItem(LOCAL_STORAGE_KEY_MAP.jwt) === 'string';
export const isAuth = (): boolean | null => getLocalStorage(LOCAL_STORAGE_KEY_MAP.auth);

export const jwtUser = () => {
	if (isJwt()) {
		return getLocalStorage(LOCAL_STORAGE_KEY_MAP.jwt);
	}
	return null;
};
