import axios from 'axios';

import {
	addUserComments,
	deleteUserComments, getUserCommentsOrg,
	getUserCommentsPlatform, getUserCommentsTotalCountOrg,
	getUserCommentsTotalCountPlatform,
	HOST_CRM,
	updateUserComments,
} from '../shared/constants/url';
import {jwtUser} from '../shared/utils/save-local-storage';

const instanceJwt = axios.create({
	baseURL: HOST_CRM,
});

instanceJwt.interceptors.request.use(
	(request) => {
		request.headers.Authorization = `Bearer ${jwtUser()}`;
		return request;
	},
	(error) => {
		console.log(error, 'No token');
	},
);

export const UserCommentAPI = {
	getAllCommentsPlatform(params?: IUserCommentPlatformParams) {
		return instanceJwt.get(getUserCommentsPlatform, {params});
	},
	getUserCommentsPlatformTotalCount(params?: IUserCommentPlatformParams) {
		return instanceJwt.get(getUserCommentsTotalCountPlatform, {params});
	},
	getAllCommentsOrg(params?: IUserCommentOrgParams) {
		return instanceJwt.get(getUserCommentsOrg, {params});
	},
	getUserCommentsOrgTotalCount(params?: IUserCommentOrgParams) {
		return instanceJwt.get(getUserCommentsTotalCountOrg, {params});
	},
	addUserComments(data: IUserAddDomain) {
		return instanceJwt.post(addUserComments, data);
	},
	updateUserComments(data: IUserUpdateDomain) {
		return instanceJwt.put(updateUserComments, data);
	},
	deleteUserComments(data: IUserDeleteDomain) {
		return instanceJwt.put(deleteUserComments, data);
	},
};

// todo
export interface IUserCommentPlatformParams {
	platform_id?: string;
	user_id?: string;
}

export interface IUserCommentOrgParams {
	org_name?: string;
	user_id?: string;
}
export interface IUserAddDomain {
	comment: string;
	platform_id: string;
	requester: string;
	user_id: string;
}
export interface IUserUpdateDomain {
	id: string;
	new_comment: string;
	requester: string;
	platform_id: string;
	user_id: string;
}
export interface IUserDeleteDomain {
	id: string;
	platform_id: string;
}

