import styled from '@emotion/styled';

export const Button = styled.button`
	display: none;
	background-color: rgb(245, 245, 245);
	bottom: 10%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 7px 12px 9px;
	border-radius: 6px;
	cursor: pointer;
	color: var(--button-wb);
	border: 1px solid var(--button-wb);
	transition: 0.7s ease-in-out;
	position: absolute;
	&:hover {
		background: var(--button-wb);
		color: var(--white-color);
	}
`;
