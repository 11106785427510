import classNames from 'classnames';
import React, {CSSProperties, JSX, useCallback, useState} from 'react';

import {OpenableFieldBase} from '../openable-field-base/openable-field-base';
import {IOption, Option} from './components/option/option';
import styles from './single-select.module.css';

interface SingleSelectProps<T> {
	clearable?: boolean;
	options: IOption<T>[];
	value: T | null;
	disabled?: boolean;
	placeholder?: string;
	icon?: JSX.Element;
	containerClassName?: string;
	optionsContainerClassName?: string;
	optionClassName?: string;
	optionActiveClassName?: string;
	displayValueClassName?: string;
	iconStrokeColor?: string;
	defaultDisplayValue?: IOption<T>['label'];
	onChange?: (value: T) => void;
	optionsContainerStyle?: CSSProperties;
}

export function SingleSelect<T extends number | string>({
															clearable,
															value, optionsContainerStyle,
															disabled,
															options,
															placeholder,
															icon,
															containerClassName,
															optionsContainerClassName,
															optionClassName,
															optionActiveClassName,
															displayValueClassName,
															iconStrokeColor,
															defaultDisplayValue,
															onChange,
														}: SingleSelectProps<T>) {

	const [isOpen, setIsOpen] = useState(false);

	const handleOnChange = useCallback(
		(value: T) => {
			onChange?.(value);
			setIsOpen(false);
		},
		[onChange],
	);
	window.addEventListener('scroll', function() {
		if (isOpen) {
			setIsOpen(false);
		}
	});
	const displayValue =
		options.find((option) => option.value === value)?.label || defaultDisplayValue || value?.toString();

	return (
		<OpenableFieldBase
			styleFieldBase={optionsContainerStyle}
			clearable={disabled ? false : clearable}
			isOpen={disabled ? false : isOpen}
			value={displayValue}
			onClear={handleOnChange}
			icon={icon}
			iconStrokeColor={iconStrokeColor}
			className={containerClassName}
			displayValueClassName={displayValueClassName}
			placeholder={placeholder}
			onToggle={setIsOpen}
		>
			<div
				className={classNames(styles.options_container, optionsContainerClassName, {
					[styles.options_container_with_indent]: Boolean(icon),
				})}
			>
				<div className={styles.opt}>{options.map((option, index) => (
					<Option
						className={optionClassName}
						activeClassName={optionActiveClassName}
						key={`${option.value}_${index}`}
						isSelected={option.value === value}
						onChange={(value) =>
							handleOnChange(value)}
						{...option}
					/>
				))}</div>
				{options.length === 0 ? <Option label="Нет данных" value={null} /> : null}
			</div>
		</OpenableFieldBase>
	);
}
