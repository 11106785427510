import styled from '@emotion/styled';
import {Link} from 'react-router-dom';

interface FooterLinksContainerStyle {
	gridArea: string
}
export const Footer  =styled.footer`
	position: relative;
	width: 100%;
	font-family: 'Jost',sans-serif;
	color: var(--white-color);
`;

export const FooterNavContainer = styled.div`
	display: flex;
	justify-content: center;
	background: #296552;
`;

export const FooterNav  =styled.nav`
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 1368px;
	padding: 62px 30px 118px;
	gap: 20px;
	@media screen and (max-width: 1150px) {
		padding: 50px 31px 50px;
		display: grid;
		justify-content: space-around;
		grid-gap: 30px;
		grid-template-areas:
				"logo links"
				"categories contacts"
	}
	@media screen and (max-width: 640px) {
		display: grid;
		justify-content: center;
		grid-gap: 50px;
		padding: 50px;
		grid-template-areas:
               "logo"
			   "links"
			   "categories"
			   "contacts"
`;

export const FooterInfoContainer = styled.div`
	display: flex;
	justify-content: center;
	background: #095740;
`;

export const FooterInfo = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 1424px;
	padding: 10px 12px 30px;
	font-size: 16px;
	line-height: 1.3em;
	gap: 10px;
	@media screen and (max-width: 640px) {
			font-size: 11px;
	}
`;

export const FooterLogoContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 20px;
	max-width: 286px;
	grid-area: logo;
	gap: 35px;
	@media screen and (max-width: 1150px) {
		gap: 15px;
	}
`;
export const Logo  = styled.img`
	max-width: 286px;
	grid-area: logo;
`;

export const FooterLinksContainer = styled.div<FooterLinksContainerStyle>`
	display: flex;
	flex-direction: column;
	max-width: 286px;
	min-width: 245px;
	gap: 35px;
	grid-area: ${props => props.gridArea};
	@media screen and (max-width: 1150px) {
		gap: 15px;
	}
`;

export const FooterLinksTitle = styled.h3`
	font-size: 30px;
	line-height: 1.3em;
	color: var(--white-color);
`;

export const FooterLink  = styled(Link)`
	display: flex;
	flex-direction: column;
	font-size: 26px;
	line-height: 1.3em;

	&:hover {
		color: var(--grey-light-color);
	}
`;
export const Name = styled.h2`
	font-size: 34px;
	line-height: 1em;
	color: var(--white-color);
`;
export const Address = styled.h3`
	font-size: 16px;
	font-weight: 400;
`;
export const Contacts = styled.h4`
	font-size: 16px;
	font-weight: 400;
`;
export const TimeWork = styled.div`
	font-size: 16px;
`;
export const Contract = styled.p`
	font-size: 16px;
`;
export const Work = styled.span`
	font-weight: 500;
`;
export const Payments = styled.img`
	width: 100%;
	padding: 0 20px;
`;
