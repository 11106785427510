import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	backgroundColor?: string;
	strokeColor?: string;
}

const IconTelegram: FC<IconProps> = ({backgroundColor, strokeColor, ...restProps}) => {
	return (
		<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
			<rect width="22" height="22" rx="11" fill={backgroundColor} />
			<path
				d="M16.3694 5C16.8008 5 17.1263 5.38664 16.9522 6.10579L14.8405 16.2745C14.6929 16.9975 14.2653 17.1715 13.6749 16.8351L8.63786 13.0344C8.61832 13.0201 8.60241 13.0012 8.59143 12.9794C8.58045 12.9575 8.57473 12.9333 8.57473 12.9087C8.57473 12.8842 8.58045 12.86 8.59143 12.8381C8.60241 12.8163 8.61832 12.7974 8.63786 12.7831L14.4545 7.41651C14.7194 7.17679 14.3977 7.0608 14.0495 7.27732L6.74944 11.9827C6.7273 11.9975 6.70217 12.0069 6.67594 12.0102C6.64971 12.0136 6.62308 12.0108 6.59807 12.0021L3.49865 11.0007C2.80989 10.7957 2.80989 10.3124 3.65381 9.96834L16.0553 5.08119C16.1536 5.03299 16.2604 5.00538 16.3694 5Z"
				stroke={strokeColor}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

IconTelegram.defaultProps = {
	backgroundColor: '#1c8666',
	strokeColor: '#ffffff',
};

export default IconTelegram;
