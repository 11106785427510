import styled from '@emotion/styled';

interface ButtonStyle{
	background: string
}

export const Button = styled.button<ButtonStyle>`
	background-color: ${(props)=> props.background};
	border: none;
	color: #FFFFFF;
	font-size: 14px;
	font-family: var(--font-family),serif;
	font-weight: 400;
	line-height: 20px;
	border-radius: 5px;
	padding: 5px 9px;
	cursor: pointer;
	white-space: nowrap;
	opacity: 1;
	transition: 0.7s;
	width: 100%;

	&:hover {
		box-shadow: var(--box-shadow-btn);
	}
`;
