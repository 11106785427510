import {CSSProperties, FC} from 'react';

import IconArrowDown from '../../../assets/icon/arrow-down.svg';
import {Button} from '../button/button';
import * as SC from './show-list-button.style';

interface ShowListInterface {
	onClickShowList: ()=> void
	showAll: boolean
	prevText?: string
	nextText?: string
	bgColor?: 'yellow' | 'green' | 'purple' | 'white'
	style?: CSSProperties | undefined
}

export const ShowListButton:FC<ShowListInterface> = ({onClickShowList,nextText,bgColor, style, prevText, showAll}) => {
	return (
		<Button onClick={onClickShowList} bgColor={bgColor} style={style}
				label={showAll ? (nextText || 'Свернуть список услуг') : (prevText || 'Раскрыть список услуг')}
				icon={<SC.IconWrapper showAllServices={showAll}>
					<SC.Vector src={IconArrowDown} alt={'Направление'} />
				</SC.IconWrapper>} />
	);
};
