import {Dispatch} from '@reduxjs/toolkit';
import {isAxiosError} from 'axios';

import {setIsShowMessage, setMessageUser, setShowLoader} from '../../redux/reducers/user-data-reducer';
import {
	dataSendingError409, errorServer500,
	invalidRequestFormat400,
	invalidUrlAddress404, messageErrServer,
	unAuthorized401,
} from '../constants/message-user';
import {requestCodes} from '../constants/request-codes';
import {isRightTokenTime} from './decoder-jwt';
import {isAuth} from './save-local-storage';

interface HandleAxiosErrorType {
	error: any,
	dispatch: Dispatch,
	rejectWithValue: any
}

export const handleAxiosError = ({
									 error,
									 dispatch,
									 rejectWithValue,
								 }: HandleAxiosErrorType) => {

	const messageUser = {
		titleMessage: '',
		textMessage: '',
		action: 'close',
		btnTitle: 'Ок',
		isErrorMessage: true,
	};

	dispatch(setShowLoader({isShowLoader: false}));
	if (!isRightTokenTime() && isAuth()) {
		dispatch(setMessageUser({
			messageUser,
			titleMessage: 'Что-то пошло не так',
			descriptionMessage: 'Время сеанса истекло, пожалуйста, войдите заново в аккаунт',
		}));
		dispatch(setIsShowMessage({isShowMessage: true}));
		return;
	}
	if (isAxiosError(error)) {
		if (typeof error.response?.data === 'string') {
			dispatch(setMessageUser({messageUser, textMessage: error.response?.data}));
			dispatch(setIsShowMessage({isShowMessage: true}));
			return error;
		}

		if (error.response?.status === requestCodes.badRequest) {
			dispatch(setMessageUser({
				messageUser,
				titleMessage: invalidRequestFormat400,
				textMessage: error.response?.data.message,
				descriptionMessage: error.response?.data.description,
			}));
			dispatch(setIsShowMessage({isShowMessage: true}));
			throw rejectWithValue(401);
		} else if (error.response?.status === requestCodes.unauthorized) {
			console.log('axiosErrStatus', error.response.status);
			dispatch(setMessageUser({
				messageUser,
				textMessage: error.response.data.message,
				titleMessage: unAuthorized401,
				descriptionMessage: error.response?.data.description,
			}));
			dispatch(setIsShowMessage({isShowMessage: true}));
			throw rejectWithValue(401);
		} else if (error.response?.status === requestCodes.forbidden) {
			console.log('axiosErrStatus', error.response.status);
			dispatch(setMessageUser({
				messageUser,
				textMessage: error.response.data.message,
				titleMessage: unAuthorized401,
				descriptionMessage: error.response?.data.description,
			}));
			dispatch(setIsShowMessage({isShowMessage: true}));
			throw rejectWithValue(403);
		} else if (error.response?.status === requestCodes.notFound) {
			dispatch(setMessageUser({
				messageUser,
				titleMessage: invalidUrlAddress404,
				textMessage: error.response?.data.message,
				descriptionMessage: error.response?.data.description,
			}));
			dispatch(setIsShowMessage({isShowMessage: true}));
			throw rejectWithValue(404);
		} else if (error.response?.status === requestCodes.conflict) {
			dispatch(setMessageUser({
				messageUser,
				textMessage: error.response.data.message,
				titleMessage: dataSendingError409,
				descriptionMessage: error.response?.data.description,
			}));
			dispatch(setIsShowMessage({isShowMessage: true}));
			console.log('axiosErrStatus', error.response.status);
			throw rejectWithValue(409);
		} else if (error.response?.status === requestCodes.serverError) {
			dispatch(setMessageUser({
				messageUser,
				textMessage: error.response.data.message,
				titleMessage: errorServer500,
			}));
			dispatch(setIsShowMessage({isShowMessage: true}));
			console.log('error, data is not found');
			throw rejectWithValue(500);
		}
		throw error;
	} else {
		dispatch(setMessageUser({messageUser: messageErrServer}));
		dispatch(setIsShowMessage({isShowMessage: true}));
		return rejectWithValue('This is not axios error');
	}
};
