import styled from '@emotion/styled';

interface TextContentWrapperStyle{
	checked: boolean | undefined
	page: boolean
}
export const TextContentWrapper = styled.div<TextContentWrapperStyle>`
	background-color: ${props => props.checked ? 'var(--green)' : 'var(--grey)'};
	border-bottom-left-radius: ${props => (props.checked && props.page) ? '0' : '10px'};
	border-bottom-right-radius: ${props => (props.checked && props.page) ? '0' : '10px'};
	cursor: default;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	font-size: 16px;
	gap: 10px;
	line-height: 1.3em;
	padding:  ${props => props.checked ? '10px 12px 36px' : '10px 12px 23px'};
	margin: 0 6px;
	color: ${props => props.checked ? 'var(--white-color)' : 'var(--link-color)'};
`;
