import {FC, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import noImagePlaceholder from '../../../../../assets/img/no_image.png';
import IconSporder from '../../../../../components/svg-icon-components/icon-sporder';
import {UserRegistered} from '../../../../../interfaces/interfaces';
import {PlatformListItem} from '../../../../../interfaces/platform';
import {setCheckedPlatformUpdate} from '../../../../../redux/reducers/platforms-reducer';
import {useAppDispatch} from '../../../../../redux/store';
import {ONE_FIELDS, UPDATE_PLATFORM} from '../../../../../shared/constants/path';
import {useResizeWindow} from '../../../../../shared/hooks/use-resize-window';
import {handleScrollToTop} from '../../../../../shared/utils/scroll-to-top';
import {ButtonGroupSetting} from '../../../../buttons';
import {QuickJumpButton} from '../../../../buttons/quick-jump-button/quick-jump-button';
import {Spoiler} from '../../../../Spoiler/Spoiler';
import {PlatformCardContactsList} from '../platform-card-contacts-list/platform-card-contacts-list';
import * as SC from './platform-list-card.style';

export interface Props {
	place?: 'sidebar-list' | 'list';
	platform: PlatformListItem;
	className?: string;
	onClickToPlatform: (id: string) => void;
	onClickToOrg: (org_name: string) => void;
	isAdmin?: boolean;
	handleActions?: (val: string, type: string) => void;
	allModerators?: UserRegistered[];
}

export const PlatformListCard: FC<Props> = ({
												platform,
												onClickToPlatform,
												onClickToOrg,
												isAdmin = false,
												handleActions = () => {
												},
												allModerators = [],
											}) => {
	const NAV_TO = ONE_FIELDS.replace(':id', platform.id);
	const width = useResizeWindow();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const isMobileMod = width <= 1300;
	const [type, setType] = useState<string>('');
	const handleIsType = (val: string) => setType(val);
	const logo = platform.logo;

	const update = (platform: PlatformListItem) => {
		dispatch(setCheckedPlatformUpdate(platform));
		navigate(`/admin/platforms/${UPDATE_PLATFORM}`);
	};
	const onClickToPlatformHandler = () => handleScrollToTop();

	return (
		<SC.PlatformCard>
			<SC.PlatformCardLogoWrapper>
				<SC.PlatformCardLogo
					src={logo || noImagePlaceholder}
					alt={`Изображение - ${platform?.org_name}`}
					title={`Изображение - ${platform?.org_name}`}
				/>
				<QuickJumpButton onClick={onClickToPlatformHandler} text={'Быстрый просмотр'} type={'link'} to={NAV_TO}/>
			</SC.PlatformCardLogoWrapper>
			<SC.PlatformCardMainInfo>
				<SC.PlatformCardTitle>{platform?.org_name}</SC.PlatformCardTitle>
				<div onClick={() => onClickToOrg?.(platform?.org_name)}>
					<SC.PlatformCardOrganizationLogo
						src={platform?.org_logo || noImagePlaceholder}
						alt={`Логотип - ${platform.org_name}`}
						title={`Логотип - ${platform.org_name}`}
					/>
				</div>
				<PlatformCardContactsList platform={platform}/>
			</SC.PlatformCardMainInfo>
			<SC.PlatformCardAdditionalInfo>
				<SC.ToMapLinkWrapper to={NAV_TO}>
					<SC.ToMapLink  onClick={() => onClickToPlatform?.(platform?.id || '')}>на карту</SC.ToMapLink>
					<IconSporder strokeColor="#1C8666" width={12} height={15}/>
				</SC.ToMapLinkWrapper>
				{!isMobileMod &&
					<SC.PlatformCardInfoBlockDescription>{platform?.description}</SC.PlatformCardInfoBlockDescription>}
				{isMobileMod && <Spoiler description={platform?.description}/>}
				<ButtonGroupSetting platform={platform} type={type} handleIsType={handleIsType}
									style={SC.ButtonGroupSetting} update={() => update(platform)}
									handleActions={handleActions} allModerators={allModerators} isAdmin={isAdmin}/>
			</SC.PlatformCardAdditionalInfo>
		</SC.PlatformCard>
	);
};
