import {createAsyncThunk} from '@reduxjs/toolkit';

import {registerAPI} from '../../api/registration-api';
import {handleAxiosError} from '../../shared/utils/handle-axios-error';

export const fetchUpdatePassword = createAsyncThunk(
	'userDataSlice/fetchUpdatePassword',
	async (data: {user_id: string; new_password: string}, {rejectWithValue,dispatch}) => {
		try {
			const response = await registerAPI.updatePassword(data);

			if (response.status === 200) {
				return {status: response.status, newPassword: data.new_password};
			}
		} catch (error) {
				handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
