import {CSSProperties, FC} from 'react';

import {UserRegistered} from '../../../interfaces/interfaces';
import {OrganizationListItem} from '../../../interfaces/organization';
import {PlatformListItem} from '../../../interfaces/platform';
import {GroupButtonsCard} from '../../GroupButtonsCard/GroupButtonsCard';
import {ButtonCard} from '../button-card/button-card';
import * as SC from './button-group-setting.style';

interface ButtonGroupSettingProps {
	isAdmin: boolean;
	platform: PlatformListItem | OrganizationListItem;
	allModerators: UserRegistered[];
	handleActions: (val: string, type: string) => void;
	type: string;
	handleIsType: (val: string) => void;
	update: () => void;
	formType?: boolean;
	style?:CSSProperties
	styleItems?:CSSProperties
}

export const ButtonGroupSetting: FC<ButtonGroupSettingProps> = ({
																	style,
																	formType = false,
																	isAdmin,
																	update,
																	handleActions,
																	platform,
																	type,
																	handleIsType,
																	allModerators,
																	styleItems,
																}) => {
	return (
		<>
			{isAdmin &&
					<GroupButtonsCard
						update={update}
						platform={platform}
						allOwners={allModerators}
						handleActions={handleActions}
						data={platform}
						type={type}
						handleIsType={handleIsType}
						formType={formType}
						styleItems={styleItems}
					/>
			}
			{isAdmin && !platform?.active &&
				<SC.CardRemove style={style}>
					<ButtonCard title={'Изменить'} background={'#1C8666'} buttonClick={update} />
					<ButtonCard buttonClick={() => handleIsType('recover')} title={'Активировать'}
								background={'#352C8D'} />
				</SC.CardRemove>}
		</>
	);
};
