import {createAsyncThunk} from '@reduxjs/toolkit';

import {GetServicesByFilterParams} from '../../api/query-params';
import {SearchPlatformAPI} from '../../api/search-platform-api';
import {requestCodes} from '../../shared/constants/request-codes';
import {handleAxiosError} from '../../shared/utils/handle-axios-error';
import {setShowLoader} from '../reducers/user-data-reducer';

export const fetchGetTotalServicesOrigins = createAsyncThunk(
	'fieldSlice/fetchGetTotalServicesDepsAllowed',
	async (params: GetServicesByFilterParams, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await SearchPlatformAPI.urlServicesByFilterOriginsDeps(params);
			if (response.status === requestCodes.success) {
				return {status: response.status, total: response.data.total};
			}
		} catch (error) {
			handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
