import styled from '@emotion/styled';

export const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
export const IconWrapper = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;
export const TextRight = styled.span`
	text-wrap: nowrap;
`;
export const Description = styled.span`
	white-space: pre-wrap;
	text-overflow: ellipsis;
`;
