import {useNavigate} from 'react-router-dom';

import {toggleMap} from '../../../redux/reducers/map-reducer';
import {useAppDispatch} from '../../../redux/store';
import {PLAY_FIELDS} from '../../../shared/constants/path';
import {handleScrollToTop} from '../../../shared/utils/scroll-to-top';
import {Button} from '../../buttons/button/button';
import {Section} from '../../section/section';
import styles from './section-map.module.css';

export const SectionMap = () => {
	const dispatchApi = useAppDispatch();
	const navigate = useNavigate();

	const handleGoToMap = () => {
		dispatchApi(toggleMap(true));
		handleScrollToTop();
		navigate(PLAY_FIELDS);
	};

	return (
		<Section className={styles.section_map} >
			<div className={styles.container_text}>
				<h2 className={styles.title}>
					Занимайтесь спортом, <br /> <strong className="title_section title_section_purple">а не поиском!</strong>
				</h2>
				<div className={styles.container_flex}>
					<p className={styles.text}>
						Используйте карту для поиска ближайших залов, спортивных площадок и комплексов. <br /> Начните
						свой путь к активной жизни прямо сейчас!
					</p>
				</div>
				<Button className={styles.view_on_map_btn} label='Посмотреть на карте' onClick={handleGoToMap}/>
			</div>
		</Section>
	);
};
