import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
  strokeColor?: string;
}

const IconArrowRightBig: FC<IconProps> = ({strokeColor, ...props}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="24"
      viewBox="0 0 10 24"
      fill="none"
      {...props}
    >
      <path
        d="M0.833984 1.58337L4.50684 5.2236C7.43587 8.12661 8.90038 9.57811 9.12588 11.3473C9.18113 11.7807 9.18113 12.2194 9.12588 12.6528C8.90038 14.422 7.43587 15.8735 4.50684 18.7765L0.833984 22.4167"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

IconArrowRightBig.defaultProps = {
  strokeColor: '#303030',
};

export default IconArrowRightBig;
