import {FC, ReactNode} from 'react';

import * as SC from './card-container.style';

interface CardContainerProps {
	children: ReactNode
}

export const CardContainer: FC<CardContainerProps> = ({children}) => {
	return <SC.Container>{children}</SC.Container>;
};
