import {createAsyncThunk} from '@reduxjs/toolkit';

import {crmPlatformAPI} from '../../../api/crm-platform';
import {requestCodes} from '../../../shared/constants/request-codes';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const removeDependency = createAsyncThunk(
	'fieldSlice/removeDependency',
	async (params: {dependency: string, origin: string}, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await crmPlatformAPI.removeDependency(params);
			dispatch(setShowLoader({isShowLoader: false}));

			if (response.status === requestCodes.success) {
				return {status: response.status, data: response.data, idDependency: params.dependency, idOrigin: params.origin};
			}

			if (response.status === requestCodes.emptyData) {
				return {status: response.status, data: []};
			}
		} catch (error) {
			handleAxiosError({dispatch, rejectWithValue, error});
		}
	},
);
