import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import {GetAllUsersParams} from '../api/query-params';
import {UserMeServerInfo} from '../interfaces/interfaces';
import {HOST_SEARCH_USERS, urlGetAllUsers} from '../shared/constants/url';
import {jwtUser} from '../shared/utils/save-local-storage';
import {onGetQueryStarted} from './utils';

export const userApi = createApi({
	reducerPath: 'userApi',
	baseQuery: fetchBaseQuery({
		baseUrl: HOST_SEARCH_USERS,
		prepareHeaders: (headers) => {
			headers.set('Authorization', `Bearer ${jwtUser()}`);
			return headers;
		}
	}),
	endpoints: (build) => ({
		getAllUsers: build.query<UserMeServerInfo[], GetAllUsersParams>({
      query: (params) => {
				return {url: urlGetAllUsers, params};
			},
			transformResponse: (response: {users: UserMeServerInfo[]}) => {
				return response.users;
			},
			onQueryStarted: onGetQueryStarted()
    }),
	})
});

export const {
	useGetAllUsersQuery
} = userApi;

