import {arrStartPageCard_ForOrg} from '../../../shared/constants/item-start-page';
import {Section} from '../../section/section';
import styles from './section-for-org.module.css';

export const SectionForOrg = () => {
	return (
		<Section className={styles.section_for_org}>
			<div className={styles.content}>
				<h2 className={styles.section_title}>Вы представляете площадку, секцию, клуб?</h2>
				<div className={styles.container_title}>
					<p className={`title_section title_section_white ${styles.title_section}`}>
						Почему нужно публиковаться через
						<strong className="title_section_green"> Sporder.by</strong>
					</p>
				</div>
				<div className={styles.container_card}>
					{arrStartPageCard_ForOrg.map((item, idx) => (
						<div className={styles.card} key={idx}>
							<h3 className={`${styles.card_title} ${idx === 3 ? styles.black_text : styles.white_text}`}>
								{item.title}
							</h3>
							<p className={`${styles.card_text} ${idx === 3 ? styles.black_text : styles.white_text}`}>
								{item.text}
							</p>
						</div>
					))}
				</div>
			</div>
		</Section>
	);
};
