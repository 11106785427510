import {createAsyncThunk} from '@reduxjs/toolkit';

import {registerAPI} from '../../api/registration-api';
import {handleAxiosError} from '../../shared/utils/handle-axios-error';

export const fetchRecoverPassword = createAsyncThunk(
	'userDataSlice/fetchRecoverPassword',
	async (data: { email: string }, {rejectWithValue, dispatch}) => {
		try {
			const response = await registerAPI.recoverPassword(data);

			if (response.status === 200) {
				return {status: response.status, loginUser: data.email};
			}
			if (response.status === 204) {
				return {status: response.status};
			}
		} catch (error) {
				handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
