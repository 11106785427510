import {DEFAULT_COORDS} from '../constants/ymaps';

export const getCoordinates = (address: string, ymaps: any): Promise<number[]> => {
	return new Promise((resolve) => {
		ymaps?.ready(() => {
			ymaps
				.geocode(address)
				.then((res: any) => {
					const coords = res?.geoObjects?.get(0)?.geometry?.getBounds();
					resolve(coords?.length ? coords[0] : DEFAULT_COORDS);
				})
				.catch(() => {
					resolve(DEFAULT_COORDS);
				});
		});
	});
};
