import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	strokeColor?: string;
	backgroundColor? : string
}

const IconService: FC<IconProps> = ({strokeColor, backgroundColor}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
			<rect width="22" height="22" rx="11" fill={backgroundColor} />
			<path
				d="M17 11C17 14.3137 14.3137 17 11 17C7.68629 17 5 14.3137 5 11C5 7.68629 7.68629 5 11 5C14.3137 5 17 7.68629 17 11Z"
				stroke={strokeColor}
			/>
			<path
				d="M8.6 11C8.6 11.2761 8.24183 11.5 7.8 11.5C7.35817 11.5 7 11.2761 7 11C7 10.7239 7.35817 10.5 7.8 10.5C8.24183 10.5 8.6 10.7239 8.6 11Z"
				stroke={strokeColor}
				strokeWidth="0.5"
			/>
			<path
				d="M11.8 11C11.8 11.2761 11.4418 11.5 11 11.5C10.5582 11.5 10.2 11.2761 10.2 11C10.2 10.7239 10.5582 10.5 11 10.5C11.4418 10.5 11.8 10.7239 11.8 11Z"
				stroke={strokeColor}
				strokeWidth="0.5"
			/>
			<path
				d="M15 11C15 11.2761 14.6418 11.5 14.2 11.5C13.7582 11.5 13.4 11.2761 13.4 11C13.4 10.7239 13.7582 10.5 14.2 10.5C14.6418 10.5 15 10.7239 15 11Z"
				stroke={strokeColor}
				strokeWidth="0.5"
			/>
		</svg>
	);
};

IconService.defaultProps = {
	strokeColor: '#ffffff',
	backgroundColor: '#1C8666'
};

export default IconService;
