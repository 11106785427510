import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ServiceListItem} from '../../interfaces/service';
import {getAllServicesOrigins} from '../thunks/dependency/get-all-services-origins';
import {getServicesDependencyAllowed} from '../thunks/dependency/get-services-dependency-allowed';
import {removeDependency} from '../thunks/dependency/remove-dependency';

interface InitialStateDependency {
	servicesDependency: {
		amount: number | null
		dependency: ServiceListItem
	}[];
	checkedService: ServiceListItem;
	checkedServiceDeps: ServiceListItem;
	servises: ServiceListItem[];
	servicesDeps: ServiceListItem[];
}

const initialState: InitialStateDependency = {
	servicesDependency: [],
	checkedService: {} as ServiceListItem,
	checkedServiceDeps: {} as ServiceListItem,
	servises: [],
	servicesDeps: [],
};

export const dependencySlice = createSlice({
	name: 'dependency',
	initialState,
	reducers: {
		setServicesDependency: (state: InitialStateDependency, action) => {
			state.servicesDependency = [...action.payload.data];
		},
		setCheckedService: (state: InitialStateDependency, action) => {
			state.checkedService = action.payload;
		},
		setCheckedServiceDeps: (state: InitialStateDependency, action) => {
			state.checkedServiceDeps = action.payload;
		},
		setAddService: (state) => {
			state.checkedServiceDeps = {} as ServiceListItem;
		},
		setChangeAmount: (state: InitialStateDependency, action: PayloadAction<{
			id: string,
			amount: number | null
		}>) => {
			state.servicesDependency.forEach(i => {
				if (i.dependency.id === action.payload.id) {
					i.amount = action.payload.amount;
				}
			});
		},
	},
	extraReducers: builder => {
		builder.addCase(getAllServicesOrigins.fulfilled, (state, action) => {
			state.servises = action.payload?.data;
		});
		builder.addCase(getServicesDependencyAllowed.fulfilled, (state, action) => {
			state.servicesDeps = action.payload?.data;
		});
		builder.addCase(removeDependency.fulfilled, (state, action) => {
			state.servicesDependency = state.servicesDependency.filter(i => i.dependency.id !== action.payload?.idDependency);
			state.servises = state.servises.map(i => i.id === action.payload?.idDependency ? ({
				...i,
				deps: i.deps - 1,
			}) : i);
		});
	},
});

export const {
	setServicesDependency,
	setChangeAmount,
	setCheckedService,
	setCheckedServiceDeps,
	setAddService,
} = dependencySlice.actions;
export const dependency = dependencySlice.reducer;
