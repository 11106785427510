import React, {FC} from 'react';

import * as SC from './label-section.style';

interface LabelSectionProps {
	label: string;
}

export const LabelSection: FC<LabelSectionProps> = ({label}) => {
	return (
		<SC.LabelSection>{label}</SC.LabelSection>
	);
};
