import {memo} from 'react';

import {handleScrollToTop} from '../../shared/utils/scroll-to-top';
import * as SC from './registration-conditions.style';

export const RegistrationConditions = memo(() => {

	const onClickHandler = () => handleScrollToTop();

	return (
		<SC.RegistrationConditions>
			Оставляя заявку, вы принимаете условия
			<SC.RegistrationConditionsLink to={'/personal-data-processing-policy'}
										   onClick={onClickHandler}>{' '} публичного
				договора</SC.RegistrationConditionsLink> и
			<SC.RegistrationConditionsLink to={'/public-contract-rumiki-individuals'} onClick={onClickHandler}>{' '}
				положения об обработке персональных данных
			</SC.RegistrationConditionsLink>
		</SC.RegistrationConditions>
	);
});
