import {createAsyncThunk} from '@reduxjs/toolkit';

import {IUserListRecommendations} from '../../api/query-params';
import {SearchPlatformAPI} from '../../api/search-platform-api';
import {handleAxiosError} from '../../shared/utils/handle-axios-error';
import {setShowLoader} from '../reducers/user-data-reducer';

export const fetchGetListRecommendations = createAsyncThunk(
	'orderAttachSlice/fetchGetListRecommendations',
	async (params: IUserListRecommendations, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await SearchPlatformAPI.getListRecommendations(params);
			dispatch(setShowLoader({isShowLoader: false}));

			if (response.status === 200) {
				return {status: response.status, data: response.data.services};
			}
			if (response.status === 204) {
				return {status: response.status, data: []};
			}
		} catch (error) {
			handleAxiosError({dispatch,error,rejectWithValue});
		}
	},
);
