import classNames from 'classnames';
import {FC, useCallback, useEffect, useMemo, useState} from 'react';

import {HOURS_COEFFICIENT, MINUTES_COEFFICIENT} from '../../../shared/constants/time';
import {converterTimeToDisplay} from '../../../shared/utils/time/convert-time-to-display';
import {IOption} from '../single-select/components/option/option';
import {SingleSelect} from '../single-select/single-select';
import styles from './time-select.module.css';

type TimePoint = {
	hours?: number;
	minutes?: number
};

interface TimeSelectProps {
	points: number[];
	value: number;
	displayValueClassName?: string;
	className?: string;
	iconStrokeColor?: string;
	label: string;
	defaultOption?: IOption<number>;
	onChange: (value: number) => void;
}

const calculateData = (value: number): TimePoint => {
	return {
		hours: Math.trunc(value / HOURS_COEFFICIENT),
		minutes: value % HOURS_COEFFICIENT,
	};
};

export const TimeSelect: FC<TimeSelectProps> = ({
	points,
	value,
	label,
	className,
	iconStrokeColor,
	defaultOption,
	onChange,
	displayValueClassName,
}) => {
	const isDefaultValue = useCallback((value: number) => {
		return defaultOption?.value && value === defaultOption?.value;
	}, [defaultOption]);
	const {hours: initHours, minutes: initMinutes} = useMemo(() => {
		if (isDefaultValue(value)) {
			return {hours: undefined, minutes: undefined};
		}
		return calculateData(value);
	}, [value, isDefaultValue]);
	const [hours, setHours] = useState<number | undefined>(undefined);
	const [minutes, setMinutes] = useState<number | undefined>(undefined);
	const handleOnChange = useCallback(({hours, minutes}: TimePoint) => {
		if (hours === undefined || minutes === undefined) {
			return;
		}

		onChange(hours * HOURS_COEFFICIENT + minutes);
	}, [hours, minutes, isDefaultValue, onChange]);

	useEffect(() => {
		setHours(initHours);
		setMinutes(initMinutes);
	}, [initHours, initMinutes]);

	const pointsToHoursOptions = useMemo(() => {
		return [...new Set(points.map((point) => Math.trunc(point / HOURS_COEFFICIENT)))]
			.map((value) => {
				return {
					label: converterTimeToDisplay(value),
					value,
				};
			});
	}, [points]);

	const correctMinutesOptions = useCallback((hours?: number) => {
		return [...new Set(points.filter((point) => hours !== undefined ? Math.trunc(point / HOURS_COEFFICIENT) === hours : true).map((point) => point % HOURS_COEFFICIENT))]
			.map((value) => {
				return {
					label: converterTimeToDisplay(value * MINUTES_COEFFICIENT),
					value,
				};
			});
	}, [points]);
	const minutesOptions = correctMinutesOptions(hours);

	return (
		<div className={classNames(styles.time_select, className)}>
			<p className={styles.title}>{label}</p>
			<SingleSelect
				value={hours || '-'}
				containerClassName={styles.time_single_select}
				displayValueClassName={displayValueClassName}
				iconStrokeColor={iconStrokeColor}
				options={pointsToHoursOptions}
				defaultDisplayValue={'-'}
				onChange={(value) => {
					if (value === '-') {
						return;
					}
					setHours(value);
					if (!correctMinutesOptions(value).find((value) => value.value === minutes)) {
						setMinutes(undefined);
					}
					handleOnChange({hours: value, minutes});
				}}
			/>
			<span> : </span>
			<SingleSelect
				value={minutes !== undefined ? minutes : '-'}
				containerClassName={styles.time_single_select}
				displayValueClassName={displayValueClassName}
				iconStrokeColor={iconStrokeColor}
				options={minutesOptions}
				defaultDisplayValue={'-'}
				onChange={(value) => {
					if (value === '-') {
						return;
					}
					setMinutes(value);
					handleOnChange({hours, minutes: value});
				}}
			/>
		</div>
	);
};
