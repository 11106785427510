export const isValidEmailRegex = (email: string): boolean => {
	const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	return emailRegex.test(email);
};

export const phoneRegex = /^\+375 \((29|33|44|25)\) \d{3}-\d{2}-\d{2}$/;

export const isValidPhoneNumber = (phone: string): boolean => {
	return phoneRegex.test(phone);
};

export const isValidPhoneInput = (phone: string): boolean => {
	const phoneRegex = /^\+375(44|33|25|29)\d{7}$/;

	return phoneRegex.test(phone);
};
