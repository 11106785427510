import {createAsyncThunk} from '@reduxjs/toolkit';

import {crmPlatformAPI} from '../../api/crm-platform';
import {GetAllBlackListParams} from '../../api/query-params';
import {requestCodes} from '../../shared/constants/request-codes';
import {handleAxiosError} from '../../shared/utils/handle-axios-error';
import {setBlackList, setShowLoader} from '../reducers/user-data-reducer';

export const fetchGetAllUsersBlackList = createAsyncThunk(
	'userDataSlice/fetchGetBlackListByPlatform',
	async (params: GetAllBlackListParams, {rejectWithValue, dispatch}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await crmPlatformAPI.getAllUsersBlackList(params);
			const responseTotalCount = await crmPlatformAPI.getAllUsersBlackListTotalCount(params);
			dispatch(setShowLoader({isShowLoader: true}));


			if (response.status === requestCodes.success) {
				dispatch(setBlackList({data: response.data.users}));
				return {status: response.status, data: response.data.users, totalCount: responseTotalCount.data.total};
			}

			if (response.status === requestCodes.emptyData) {
				return {status: response.status, data: response.data};
			}
		} catch (error) {
				handleAxiosError({dispatch,error,rejectWithValue});
		}
	},
);
