import {createAsyncThunk} from '@reduxjs/toolkit';

import {searchUsersAPI} from '../../../api/search-users-a-p-i';
import {requestCodes} from '../../../shared/constants/request-codes';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';

export const fetchGetUserFullRoles = createAsyncThunk(
	'userDataSlice/fetchGetUserFullRoles',
	async (id: string,{dispatch, rejectWithValue}) => {
		try {
			const res = await searchUsersAPI.getUserFullRoles(id);
			if (res.status === requestCodes.success) {
				return {status: res.status, data: {roles: res.data.roles, sub: id}};
			}
		} catch (error) {
				handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
