import {createAsyncThunk} from '@reduxjs/toolkit';

import {crmPlatformAPI} from '../../../api/crm-platform';
import {requestCodes} from '../../../shared/constants/request-codes';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';
import {setShowLoader} from '../../reducers/user-data-reducer';

export const fetchGetNotificationsPlatform = createAsyncThunk(
	'fieldSlice/fetchGetNotificationsPlatform',
	async (data: string, {dispatch, rejectWithValue}) => {
		try {
			dispatch(setShowLoader({isShowLoader: true}));
			const response = await crmPlatformAPI.getNotificationsPlatform(data);
			dispatch(setShowLoader({isShowLoader: false}));

			if (response.status === requestCodes.success) {
				return {status: response.status, data: response.data};
			}
			if (response.status === requestCodes.emptyData) {
				return {status: response.status, data: response.data};
			}
		} catch (error) {
			handleAxiosError({dispatch,rejectWithValue,error});
		}
	},
);
