import {createAsyncThunk} from '@reduxjs/toolkit';

import {GetAllUsersParams} from '../../../api/query-params';
import {searchUsersAPI} from '../../../api/search-users-a-p-i';
import {UserCommentAPI} from '../../../api/users-comments-api';
import {UserMeServerInfo} from '../../../interfaces/interfaces';
import {requestCodes} from '../../../shared/constants/request-codes';
import {handleAxiosError} from '../../../shared/utils/handle-axios-error';

export const fetchGetAllUsers = createAsyncThunk(
	'userDataSlice/fetchGetAllUsers',
	async (params: GetAllUsersParams, {rejectWithValue, dispatch}) => {
		try {
			const response = await searchUsersAPI.getAllUsers(params);
			const responseTotalCount = await searchUsersAPI.getAllUsersTotalCount(params);

			if (response.status === requestCodes.success && responseTotalCount.status === requestCodes.success) {
				const userWithCommentsPromise = response.data.users.map(async (user: UserMeServerInfo) => {
					let resCommentTotalPlatform: any;

					if ('Registered' in user && params.platform_id && !params.org_name) {
						resCommentTotalPlatform = await UserCommentAPI.getUserCommentsPlatformTotalCount({
							platform_id: params.platform_id,
							user_id: user.Registered.id,
						});
					}

					if ('Registered' in user && params.org_name) {
						resCommentTotalPlatform = await UserCommentAPI.getUserCommentsOrgTotalCount({
							org_name: params.org_name,
							user_id: user.Registered.id,
						});
					}

					return {
						...user,
						totalComments: resCommentTotalPlatform?.data?.total,
					};
				});

				const userWithTotalComments = await Promise.all(userWithCommentsPromise);

				return {
					status: response.status,
					data: userWithTotalComments,
					totalCount: responseTotalCount.data.total,
				};
			}
		} catch (error) {
			handleAxiosError({dispatch, error, rejectWithValue});
		}
	});
