import {UserRole} from '../../../interfaces/enums';
import {TFullUserRole} from '../../../interfaces/role';
import {humanReadableRoleMap, OwnerRoleMetaField} from '../../constants/roles';
import {isModeratorFullRole} from './is-moderator-role';
import {isOwnerRole} from './is-owner-role';

export const roleToString = (role: TFullUserRole):{labelRole: string, labelRoleInfo: string, value: string}[] => {
	if (isModeratorFullRole(role)) {
		return [{
			labelRole: humanReadableRoleMap[UserRole.moderator],
			labelRoleInfo: role.Moderator.info.org_name,
			value: `${humanReadableRoleMap[UserRole.moderator]} ${role.Moderator.info.org_name}`,
		}, {
			labelRole: 'Адрес',
			labelRoleInfo: role.Moderator.info.location.address,
			value: '',
		}];
	}
	if (isOwnerRole(role)) {
		return [{
			labelRole: humanReadableRoleMap[UserRole.adminSite],
			labelRoleInfo: role[UserRole.adminSite][OwnerRoleMetaField],
			value: `${humanReadableRoleMap[UserRole.adminSite]} ${role[UserRole.adminSite][OwnerRoleMetaField]}`,
		}];
	}
	if (typeof role === 'string' && role in humanReadableRoleMap) {
		return [{labelRole: humanReadableRoleMap[role], labelRoleInfo: '', value: humanReadableRoleMap[role]}];
	}
	return [];
};
