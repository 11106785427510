import {RootState} from '../store';

export const arrServiceTypeStringSelector = (state: RootState) => state.sportState.arrServiceTypeString;
export const arrSportStringSelector = (state: RootState) => state.sportState.arrSportString;
export const filterSportSelector = (state: RootState) => state.sportState.filterSport;
export const allSportTypesSelector = (state: RootState) => state.sportState.allSportTypes;
export const allServicesTypeSelector = (state: RootState) => state.sportState.allServicesTypes;
export const allowsServicesTypeSelector = (state: RootState) => state.sportState.allowsServiceType;
export const objAllowsServicesTypeSelector = (state: RootState) => state.sportState.objAllows;
export const arrCitiesSelector = (state: RootState) => state.sportState.allCity;
