import axios from 'axios';

import {getAllUsersTotalCount, HOST_SEARCH_USERS, urlGetAllUsers, urlGetUserById, urlGetUserFullRole, urlGetUserRole} from '../shared/constants/url';
import {jwtUser} from '../shared/utils/save-local-storage';
import {GetAllUsersParams} from './query-params';

const instanceJwt = axios.create({
	baseURL: HOST_SEARCH_USERS,
});

instanceJwt.interceptors.request.use(
	(request) => {
		request.headers.Authorization = `Bearer ${jwtUser()}`;
		return request;
	},
	(error) => {
		console.log(error, 'No token');
	},
);

export const searchUsersAPI = {
	getAllUsers(params?: GetAllUsersParams) {
		return instanceJwt.get(urlGetAllUsers, {params});
	},
	getAllUsersTotalCount(params?: GetAllUsersParams) {
		return instanceJwt.get(getAllUsersTotalCount, {params});
	},
	getUserRoles(id: string) {
		return instanceJwt.get(`${urlGetUserRole}/${id}`);
	},
	getUserFullRoles(id: string) {
		return instanceJwt.get(`${urlGetUserFullRole}/${id}`);
	},
	getUserById(id: string) {
		return instanceJwt.get(`${urlGetUserById}/${id}`);
	},
};
