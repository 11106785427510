import styled from '@emotion/styled';

interface ServiceNameStyle{
	checked: boolean | undefined
}

export const ServiceName = styled.div<ServiceNameStyle>`
	font-weight: 500;
	font-size: 18px;
	line-height: 23px;
	height: 24px;
	text-wrap: nowrap;
	color: ${props => props.checked ? 'var(--white-color)' : 'var(--black-color)'};
	p{
		text-wrap: nowrap;
	}
`;
