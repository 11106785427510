import {UserRole} from '../../../interfaces/enums';
import {OwnerRole} from '../../../interfaces/role';
import {OwnerRoleMetaField} from '../../constants/roles';
import {isObject} from '../is-object';

export const isOwnerRole = (role: unknown): role is OwnerRole => {
	if (!isObject(role)) {
		return false;
	}

	if (!(UserRole.adminSite in role)) {
		return false;
	}

	const roleMeta = role[UserRole.adminSite];

	if (!isObject(roleMeta)) {
		return false;
	}

	if (!(OwnerRoleMetaField in roleMeta)) {
		return false;
	}

	const organizationName = roleMeta[OwnerRoleMetaField];

	return typeof organizationName === 'string';
};
