import {DayInfo} from '../../interfaces/interfaces';
import {converterTime} from './time/converter-time';

export const getPlatformWorkingTime = (dayInfo: DayInfo ) => {
	if (dayInfo === 'Weekend') {
		return ['Выходной', ''];
	}

	return dayInfo.Working.schedule.map(
		(schedule) => `${converterTime(schedule.start_working)}-${converterTime(schedule.end_working)}`,
	);
};
