import {useMemo} from 'react';

import {DayInfo, isScheduleFrequencyMonthly, isScheduleFrequencyOnceDate, isScheduleFrequencyWeekly, isScheduleFrequencyYearly,ScheduleInfo} from '../../../../../../../interfaces/interfaces';
import {getWeekDay} from '../../../../../get-day-info';
import {ScheduleCalendarType} from '../schedule-editor-modal-settings/components/index.type';

export const getWeek = (date: Date) => {
	const firstWeekDay = getWeekDay(new Date(date.getFullYear(), date.getMonth(), 1));
	const offsetDate = date.getDate() + firstWeekDay - 1;
	return Math.floor(offsetDate / 7) + 1;
};

export const SCHEDULE_PRIORITY = [
	ScheduleCalendarType.DAILY,
	ScheduleCalendarType.WEEKLY,
	ScheduleCalendarType.MONTHLY_FOR_DAY,
	ScheduleCalendarType.MONTHLY_FOR_WEEK,
	ScheduleCalendarType.YEARLY_FOR_DAY,
	ScheduleCalendarType.YEARLY_FOR_WEEK,
	ScheduleCalendarType.ONCE_DATE
];

export const REVERSED_SCHEDULE_PRIORITY = [...SCHEDULE_PRIORITY].reverse();

export type AggregatedCalendar = {
	[ScheduleCalendarType.DAILY]: { info: DayInfo }[],
	[ScheduleCalendarType.WEEKLY]: { day: number, info: DayInfo }[],
	[ScheduleCalendarType.MONTHLY_FOR_DAY]: { day: number, info: DayInfo }[],
	[ScheduleCalendarType.MONTHLY_FOR_WEEK]: { day: number, week: number, info: DayInfo }[],
	[ScheduleCalendarType.YEARLY_FOR_DAY]: { day: number, month: number, info: DayInfo }[]
	[ScheduleCalendarType.YEARLY_FOR_WEEK]: { day: number, week: number, month: number, info: DayInfo }[]
	[ScheduleCalendarType.ONCE_DATE]: { date: string,  info: DayInfo }[],
};

export const useCalendarDate = (schedule: ScheduleInfo[]) => {
	// TODO: better to rewrite it to map
	const calendar = useMemo(() =>
            schedule.reduce<AggregatedCalendar>((acc, cur) => {
				if(isScheduleFrequencyWeekly(cur.frequency)){
					acc.Weekly.push(
						{
							...cur.frequency.Weekly,
							info: cur.schedule,
						}
					);
					return acc;
				}

				if(isScheduleFrequencyMonthly(cur.frequency)){
					if (cur.frequency.Monthly.week) {
						acc.MonthlyForWeek.push(
							{
								day: cur.frequency.Monthly.day,
								week: cur.frequency.Monthly.week,
								info: cur.schedule,
							}
						);
					} else {
						acc.MonthlyForDay.push(
							{
								day: cur.frequency.Monthly.day,
								info: cur.schedule,
							}
						);
					}

					return acc;
				}

				if(isScheduleFrequencyYearly(cur.frequency)){
					if (cur.frequency.Yearly.week) {
						acc.YearlyForWeek.push(
							{
								day: cur.frequency.Yearly.day,
								week: cur.frequency.Yearly.week,
								month: cur.frequency.Yearly.month,
								info: cur.schedule,
							}
						);
					} else {
						acc.YearlyForDay.push(
							{
								day: cur.frequency.Yearly.day,
								month: cur.frequency.Yearly.month,
								info: cur.schedule,
							}
						);
					}
					return acc;
				}

				if(isScheduleFrequencyOnceDate(cur.frequency)){
					acc.OnceDate.push(
						{
							...cur.frequency.OnceDate,
							info: cur.schedule,
						}
					);
					return acc;
				}
				acc.Daily.push(
					{
						info: cur.schedule,
					});
				return acc;
			}, {
				[ScheduleCalendarType.DAILY]: [],
				[ScheduleCalendarType.WEEKLY]: [],
				[ScheduleCalendarType.MONTHLY_FOR_DAY]: [],
				[ScheduleCalendarType.MONTHLY_FOR_WEEK]: [],
				[ScheduleCalendarType.YEARLY_FOR_DAY]: [],
				[ScheduleCalendarType.YEARLY_FOR_WEEK]: [],
				[ScheduleCalendarType.ONCE_DATE]: []
			})
		, [schedule]);

	const modifiers = useMemo(() => {
		return {
			Daily: (_date: Date) => calendar.Daily.length !== 0,
			Weekly: (date: Date) => calendar.Weekly.some(el => getWeekDay(date) === el.day),
			MonthlyForWeek: (date: Date) => calendar.MonthlyForWeek.some(el => getWeekDay(date) === el.day && getWeek(date) === el.week),
			MonthlyForDay: (date: Date) => calendar.MonthlyForDay.some(el => date.getDate() === el.day),
			YearlyForWeek: (date: Date) => calendar.YearlyForWeek.some(el =>
					date.getMonth() + 1 === el.month && getWeekDay(date) === el.day && getWeek(date) === el.week),
			YearlyForDay: (date: Date) => calendar.YearlyForDay.some(el => date.getDate() === el.day && date.getMonth() + 1 === el.month),
			OnceDate: (date: Date) => calendar.OnceDate.some(el =>
				date.toLocaleDateString() === (el.date).split('-').reverse().join('.')),
			WeekendDay: (_date: Date) => calendar.Daily.some(el => el.info === 'Weekend'),
			WeekendWeek: (date: Date) => calendar.Weekly.some(el => el.info === 'Weekend' && getWeekDay(date) === el.day),
			WeekendMonthlyForWeek: (date: Date) =>
				calendar.MonthlyForWeek.some(el => (el.info === 'Weekend' && getWeekDay(date) === el.day && getWeek(date) === el.week)),
			WeekendMonthlyForDay: (date: Date) => calendar.MonthlyForDay.some(el => (el.info === 'Weekend' && date.getDate() === el.day)),
			WeekendYearlyForWeek: (date: Date) => calendar.YearlyForWeek.some(el =>
				(el.info === 'Weekend' && date.getMonth() + 1 === el.month && getWeekDay(date) === el.day && getWeek(date) === el.week)),
			WeekendYearlyForDay: (date: Date) => calendar.YearlyForDay.some(el =>
				(el.info === 'Weekend' && date.getDate() === el.day && date.getMonth() + 1 === el.month)),
			WeekendOnceDate: (date: Date) =>
				calendar.OnceDate.some(el =>
					el.info === 'Weekend' && date.toLocaleDateString() === (el.date).split('-').reverse().join('.'))
			,
		};
	}, [calendar]);

	return {modifiers, calendar};
};
