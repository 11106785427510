import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	strokeColor?: string;
}

const IconArrowRight: FC<IconProps> = ({strokeColor, ...restProps}) => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
			<path d="M6.94 4L6 4.94L9.05333 8L6 11.06L6.94 12L10.94 8L6.94 4Z" fill={strokeColor} />
		</svg>
	);
};

IconArrowRight.defaultProps = {
	strokeColor: '#303030',
};

export default IconArrowRight;
