import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {setIsAuthUser} from '../../redux/reducers/user-data-reducer';
import {isAuth} from '../../shared/utils/save-local-storage';
import {BurgerMenu} from './components/burger-menu/burger-menu';
import {BurgerMenuButton} from './components/burger-menu-button/burger-menu-button';
import {CityInfo} from './components/city-info/city-info';
import {Logo} from './components/logo/logo';
import {NavBar} from './components/navbar/navbar';
import {UserInfo} from './components/user-info/user-info';
import * as SC from './header.style';

export const Header = () => {
	const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
	const dispatch = useDispatch();

	if (isBurgerMenuOpen) {
		document.body.style.padding = ' 0 calc(20px - (100vw - 100%)) 0 0';
		document.body.style.overflow = 'hidden';
	} else {
		document.body.style.padding = '0';
		document.body.style.overflow = 'auto';
	}
	useEffect(() => {
		if (isAuth()) {
			dispatch(setIsAuthUser());
		}
	}, [dispatch]);

	const handleToggleBurgerMenu = useCallback(() => {
		setIsBurgerMenuOpen((isBurgerMenuOpen) => !isBurgerMenuOpen);
	}, []);

	return (
		<SC.Header>
			<SC.HeaderContent>
				<SC.HeaderLogoNav >
					<Logo/>
					<NavBar/>
				</SC.HeaderLogoNav>

				<SC.HeaderControls>
					{/* <SC.FaqWrapper><FaqLink /></SC.FaqWrapper>*/}
					<CityInfo/>
					<UserInfo/>
					<BurgerMenuButton onClick={handleToggleBurgerMenu}/>
				</SC.HeaderControls>
			</SC.HeaderContent>
			<BurgerMenu isOpen={isBurgerMenuOpen} setIsOpen={setIsBurgerMenuOpen}/>
		</SC.Header>
	);
};
