import classNames from 'classnames';
import React, {FC, JSX, useEffect, useState} from 'react';

import {TimeStartEndSelect} from '../../../pages/order-page/components/order-details/components/day-schedule/components/time-start-end-select/time-start-end-select';
import {HOURS_COEFFICIENT, HOURS_PER_DAY} from '../../../shared/constants/time';
import {getTimeDisplayValue} from '../../../shared/utils/time/get-time-display-value';
import {OpenableFieldBase} from '../openable-field-base/openable-field-base';
import {START_HOURS} from './constants';
import styles from './time-field.module.css';

interface TimeFieldProps {
	icon: JSX.Element;
	placeholder: string;
	fromValue?: number;
	toValue?: number;
	onChange: (from: number, to: number) => void;
}

export const TimeField: FC<TimeFieldProps> = ({icon, placeholder, fromValue, toValue, onChange}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [timeRange, setTimeRange] = useState({start: fromValue || 0, end: toValue || 0});
	const [disabled, setDisabled] = useState(true);
	const [displayValue, setDisplayValue] = useState(getTimeDisplayValue(fromValue, toValue));

	useEffect(() => {
		setTimeRange({start: fromValue || 0, end: toValue || 0});
		setDisplayValue(getTimeDisplayValue(fromValue || 0, toValue || 0));
		setDisabled(true);
	},[fromValue, toValue]);

	const onSubmit = (data: {start: number, end: number}) => {
		const {start, end} = data;
		setDisplayValue(getTimeDisplayValue(timeRange.start, timeRange.end));
		onChange(start, end);
		setIsOpen(false);
	};

	return (
		<OpenableFieldBase
		  clearable={true}
			onClear={() => {
				onSubmit({start: 0, end: 0});
			}}
			isOpen={isOpen}
			value={displayValue}
			icon={icon}
			placeholder={placeholder}
			onToggle={setIsOpen}
		>
			<div
				className={styles.time_container}
				onClick={(event) => {
					event.stopPropagation();
				}}
			>
				<TimeStartEndSelect
					orderPolicy={{
						service_step: 1,
						order_step: 1,
						min_order_duration: 2,
					}}
					startValue={timeRange.start}
					endValue={timeRange.end}
					defaultOption={{label: '-', value: -1}}
					workingTime={[{
						start_working: START_HOURS * HOURS_COEFFICIENT,
						end_working: HOURS_PER_DAY * HOURS_COEFFICIENT,
						property: null,
					}]}
					onChange={() => {
						setDisabled(true);
					}}
					onSubmit={(value) => {
						setTimeRange(value);
						setDisabled(false);
					}}
					timeSelectClassName={styles.grey_color}
					displayValueClassName={styles.grey_color}
					containerClassName={styles.flex_column_container}
				/>

				<div className={classNames(styles.block, styles.footer)}>
					<button
						className={classNames('btn_yellow', styles.submit_button)}
						type="button"
						disabled={disabled}
						onClick={() => {
							onSubmit(timeRange);
						}}
					>
						Сохранить
					</button>
				</div>
			</div>
		</OpenableFieldBase>
	);
};
