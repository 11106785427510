import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	backgroundColor?: string;
	strokeColor?: string;
	borderRadius?: string;
	weight?: string;
	height?: string;
}

const IconExclamationMark: FC<IconProps> = ({
	width,
	height,
	backgroundColor,
	strokeColor,
	borderRadius,
	...restProps
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...restProps}
		>
			<rect width="100%" height="100%" rx={borderRadius} fill={backgroundColor} />
			<mask id="path-2-inside-1_306_7547" fill={strokeColor}>
				<path d="M17 11C17 14.3137 14.3137 17 11 17C7.68629 17 5 14.3137 5 11C5 7.68629 7.68629 5 11 5C14.3137 5 17 7.68629 17 11Z" />
			</mask>
			<path
				d="M11.5 7.30769C11.5 7.03155 11.2761 6.80769 11 6.80769C10.7239 6.80769 10.5 7.03155 10.5 7.30769H11.5ZM10.5 12.3846C10.5 12.6608 10.7239 12.8846 11 12.8846C11.2761 12.8846 11.5 12.6608 11.5 12.3846H10.5ZM11.5 14.2308C11.5 13.9546 11.2761 13.7308 11 13.7308C10.7239 13.7308 10.5 13.9546 10.5 14.2308H11.5ZM10.5 14.6923C10.5 14.9685 10.7239 15.1923 11 15.1923C11.2761 15.1923 11.5 14.9685 11.5 14.6923H10.5ZM10.5 7.30769V12.3846H11.5V7.30769H10.5ZM10.5 14.2308V14.6923H11.5V14.2308H10.5ZM16 11C16 13.7614 13.7614 16 11 16V18C14.866 18 18 14.866 18 11H16ZM11 16C8.23858 16 6 13.7614 6 11H4C4 14.866 7.13401 18 11 18V16ZM6 11C6 8.23858 8.23858 6 11 6V4C7.13401 4 4 7.13401 4 11H6ZM11 6C13.7614 6 16 8.23858 16 11H18C18 7.13401 14.866 4 11 4V6Z"
				fill={strokeColor}
				mask="url(#path-2-inside-1_306_7547)"
			/>
		</svg>
	);
};

IconExclamationMark.defaultProps = {
	backgroundColor: '#1c8666',
	strokeColor: '#ffffff',
	width: '22',
	height: '22',
	borderRadius: '11',
};

export default IconExclamationMark;
