import classNames from 'classnames';
import {CSSProperties, FC, memo, ReactNode} from 'react';

import styles from './button.module.css';

type ButtonProps = {
	label: string;
	onClick?: () => void;
	className?: string;
	bgColor?: 'yellow' | 'green' | 'purple' | 'white';
	icon?: ReactNode;
	iconPosition?: 'left' | 'right'
	disabled?: boolean;
	disabledGgColorMode?: 'light' | 'dark';
	style?: CSSProperties
	type?: 'button' | 'submit' | 'reset'
};


export const Button: FC<ButtonProps> = memo(({
											type,
											style,
											label,
											icon,
											iconPosition = 'right',
											onClick = ()=> {},
											className,
											bgColor = 'yellow',
											disabled,
											disabledGgColorMode = 'light',
										}) => {
	const handleOnClick = () => {
		if (disabled) {
			return;
		}

		onClick();
	};

	return (
		<button
			style={style}
			type={type || 'button'}
			className={classNames(styles.btn_container, className, {
				[styles.disabled]: disabled,
				[styles[disabledGgColorMode]]: disabled,
				[styles[bgColor]]: !disabled,
			})}
			onClick={handleOnClick}
		>
			<div className={styles.btn_decoration} />
			<div className={styles.label_container}>
				{!!icon && iconPosition === 'left' && icon}
				<span>{label}</span>
				{!!icon && iconPosition === 'right' && icon}
			</div>
		</button>
	);
});
