import {ModeratorRoleFullMetaField, ModeratorRoleMetaField, OwnerRoleMetaField} from '../shared/constants/roles';
import {isAdminRole, isModeratorFullRole, isOwnerRole} from '../shared/utils/roles';
import {UserRole} from './enums';
import {PlatformShortInfo} from './platform';

export type AdminRole = UserRole.admin;

export type OwnerRole = {
	[UserRole.adminSite]: {
		[OwnerRoleMetaField]: string;
	};
};

export type ModeratorRole = {
	[UserRole.moderator]: {
		[ModeratorRoleMetaField]: string;
	};
};

export type ModeratorFullRole = {
	[UserRole.moderator]: {
		[ModeratorRoleFullMetaField]: PlatformShortInfo;
	};
};

export type ViewerRole = UserRole.viewer;
export type UnknownRole = UserRole.unknown;

export type TUserRole = UnknownRole | ViewerRole | AdminRole  | ModeratorRole | OwnerRole;
export type TFullUserRole = UnknownRole | ViewerRole | AdminRole  | ModeratorFullRole | OwnerRole;

export const filterAdminRoles = (roles: TFullUserRole[]): TFullUserRole[] =>{
	return [
		...roles.filter((role) => isAdminRole(role)),
		...roles.filter((role) => isOwnerRole(role)),
		...roles.filter((role) => isModeratorFullRole(role)),
		...roles.filter((role) => !isModeratorFullRole(role) && !isOwnerRole(role) && !isAdminRole(role)),
	];
};

