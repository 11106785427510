import {FC} from 'react';
import Flickity from 'react-flickity-component';
import {useNavigate} from 'react-router-dom';

import {UniqueImage} from '../../../../images/index.type';
import * as SC from './slider.style';

interface SliderProps {
	images: UniqueImage[]
	showImgId: string
	clickCheckedImg: (img: UniqueImage) => void
}

export const Slider: FC<SliderProps> = ({images, showImgId, clickCheckedImg}) => {
	const navigate = useNavigate();
	const flickityOptions = () => {
		const initialIndex = images.findIndex(image => image.id === showImgId);
		return {
			cellAlign: 'center',
			initialIndex,
		};
	};
	const onClickHandler = (item: UniqueImage) => {
		clickCheckedImg(item);
	};
	if (!images) {
		navigate(-1);
	}
	return (
		<Flickity
			className={SC.Slider}
			elementType="div"
			disableImagesLoaded={false}
			options={flickityOptions()}
			reloadOnUpdate
			static
		>
			{images.map((item, index) => {
				return (
					<div key={index}>
						<SC.Images key={index} src={item.info.image_link} onClick={() => onClickHandler(item)}
								   alt="фотографии спортивной организации"/>
					</div>
				);
			})}
		</Flickity>
	);
};
