import jwt_decode from 'jwt-decode';

import {UserRole} from '../../interfaces/enums';
import {JwtData} from '../../interfaces/interfaces';
import {TUserRole} from '../../interfaces/role';
import {LOCAL_STORAGE_KEY_MAP} from '../constants/local-storage';
import {OwnerRoleMetaField} from '../constants/roles';
import {isOwnerRole, isRole} from './roles';
import {getLocalStorage, isJwt} from './save-local-storage';

export const jwtDecode = (token: string) => {
	return jwt_decode(token) as any;
	// eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIyMiIsInJvbGVzIjpbIlwiVmlld2VyXCIiXSwicmVzIjp7fSwiZXhwIjoxNjg4NDYwMjAxfQ.NyEIaxk2O8P5bynS1q1MW0Lmtti7acjz_lbE_t5jP_DtOdy4GRAk4kiGU3bD-OADADJhxmhULRlB2WUn7rOxDA

	//   if (typeof window !== 'undefined') {
	//     window.Buffer = window.Buffer || require('buffer').Buffer;
	//     const key:string = Buffer.from(JSON.stringify(token.split('.')[1]), 'base64').toString()
	//     return JSON.parse(key);
	//   }
};

export const jwtParserRole = (jwtData: any ): JwtData => {
	const roles: TUserRole[] = [];
	jwtData.roles.forEach((role: any) => roles.push(JSON.parse(role)));
	jwtData.roles = roles.sort((a, b)=>{
		if(Object.keys(a)[0] > Object.keys(b)[0] || Object.entries(Object.values(a)[0]).join(' ').toLowerCase() > Object.entries(Object.values(b)[0]).join(' ').toLowerCase()) {
			return 1;
		}
		return  -1;
	});
	return jwtData;
};

export const getParsedJWTRoles = (): TUserRole[] => {
	const JWTRoles = jwtDecode(getLocalStorage(LOCAL_STORAGE_KEY_MAP.jwt)).roles;

	return JWTRoles.map((role: string) => JSON.parse(role)).filter(isRole);
};

export const isRoleAdmin = () => {
	if (isJwt()) {
		const roles: string[] = [];
		jwtDecode(getLocalStorage(LOCAL_STORAGE_KEY_MAP.jwt)).roles.forEach((role: string) =>
			roles.push(JSON.parse(role)),
		);
		return roles.includes(UserRole.admin);
	}
};

export const isRoleAdminOrg = () => {
	if (isJwt()) {
		const roles: any[] = [];
		jwtDecode(getLocalStorage(LOCAL_STORAGE_KEY_MAP.jwt)).roles.forEach((role: any) =>
			roles.push(JSON.parse(role)),
		);
		const roleObj: any[] = [];
		roles.forEach((role) => {
			if (typeof role === 'object') {
				roleObj.push(Object.keys(role));
			}
		});
		return roleObj.flat().includes(UserRole.adminSite);
	}
};

export const isRoleModerator = () => {
	if (isJwt()) {
		const roles: any[] = [];
		jwtDecode(getLocalStorage(LOCAL_STORAGE_KEY_MAP.jwt)).roles.forEach((role: any) =>
			roles.push(JSON.parse(role)),
		);
		const roleObj: any[] = [];
		roles.forEach((role) => {
			if (typeof role === 'object') {
				roleObj.push(Object.keys(role));
			}
		});
		return roleObj.flat().includes(UserRole.moderator);
	}
};

export const getHasAdministrativeRole = () => getLocalStorage(LOCAL_STORAGE_KEY_MAP.hasAdminRole);

// TODO: need to handle owner array
export const nameOrganizationOwner = () => {
	if (!isJwt() || !isRoleAdminOrg()) {
		return;
	}

	const roles = getParsedJWTRoles();
	const ownerRole = roles.find(isOwnerRole);

	if (!ownerRole) {
		return;
	}

	return ownerRole[UserRole.adminSite][OwnerRoleMetaField];
};

export const isOrganizationOwner = (name: string) => {
	if (!isJwt() || !isRoleAdminOrg()) {
		return;
	}

	const roles = getParsedJWTRoles();
	const ownerRole = roles.filter(isOwnerRole).find(r=> r[UserRole.adminSite][OwnerRoleMetaField] === name);

	if (!ownerRole) {
		return;
	}

	return true;
};

export const isRightTokenTime = () => {
	if (isJwt()) {
		const timeTokenLocal = jwtDecode(getLocalStorage(LOCAL_STORAGE_KEY_MAP.jwt)).exp;
		return timeTokenLocal * 1000 > new Date().getTime();
	}
};

export const getIdUser = () => {
	if (isJwt()) {
		return jwtDecode(getLocalStorage(LOCAL_STORAGE_KEY_MAP.jwt)).sub;
	}
};

export const allPlatformAdmimSite = () => {
	if (isJwt()) {
		return jwtDecode(getLocalStorage(LOCAL_STORAGE_KEY_MAP.jwt)).res.platforms;
	}
};

export const allServicesAdmimSite = () => {
	if (isJwt()) {
		return jwtDecode(getLocalStorage(LOCAL_STORAGE_KEY_MAP.jwt)).res.services;
	}
};
