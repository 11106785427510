import {FC, SVGAttributes} from 'react';

interface IconProps extends SVGAttributes<SVGSVGElement> {
	strokeColor?: string;
}

const IconOkShape: FC<IconProps> = ({strokeColor, ...restProps}) => {
	return (
		<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
			<path
				d="M37.6678 18.9985L29.8047 27.7811C26.6888 31.2614 25.1309 33.0015 23.0813 33.0015C21.0317 33.0015 19.4738 31.2614 16.3579 27.7811L14.3294 25.5154M49.337 26C49.337 38.8895 38.8881 49.3384 25.9986 49.3384C13.1091 49.3384 2.66016 38.8895 2.66016 26C2.66016 13.1105 13.1091 2.66156 25.9986 2.66156C38.8881 2.66156 49.337 13.1105 49.337 26Z"
				stroke={strokeColor}
				strokeWidth="4"
				strokeLinecap="round"
			/>
		</svg>
	);
};

IconOkShape.defaultProps = {
	strokeColor: '#ffffff',
};

export default IconOkShape;
